import React, { useState, useEffect, useRef, useContext } from "react";
import { ModalController } from "../../../homeUI/_index";

import { GET_LIST_REPORT_WAITING_VERIFY } from "../../../../apis/_index";

import { Pagination } from "@mui/material";

import { readReport } from "../../../../funcs/reportCalling";
import { takePhongban, takeUser } from "../../../../funcs/reportCalling";
import { REPORT_PERSONAL_SUMWORK } from "../../../../apis/_index";
import { randomColor } from "../../../../funcs/reportCalling";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import LoaderSpinner from "../../../../components/loader-spinner/_index";
import DateTimePickerControl from "../../../../controls/datetimePicker/datetimePickerControl";
import ServiceUtils from "../../../../utils/services";
import LoadingControl from "../../../../controls/loading/loadingControl";

import "../../../../styles/reportConfirms/index.css";
import HandleUI from "../../../../utils/handleUI";
import HandleDate from "../../../../utils/date/handleDate";

const ReportWaitingVerify = (props: any) => {
  // const currentDate = new Date();

  // const [dataCot, setDataCot] = useState<any[] | null>(null);
  // const [dataTile, setDataTile] = useState<any[] | null>(null);
  const [dataList, setDataList] = useState<any | null>(null);

  // page Info
  const [selectedPage, setSelectedPage] = useState<any | null>(0);
  const [totalPage, setTotalPage] = useState<any | null>(null);
  const [pageShowing, setPageShowing] = useState<any | null>(10);

  const [fromDate, setFromDate] = useState<any | null>(
    HandleDate.firstDayOfYear
  );
  const [toDate, setToDate] = useState<any | null>(HandleDate.lastDayOfYear);

  const shouldLog = useRef(true);

  // const _getListReportWaitingVerify = (page: any) => {
  //   const accessToken = localStorage.getItem("accessToken");
  //   const body: any = {
  //     page,
  //     limit: pageShowing,
  //     fromDate: null,
  //     toDate: null,
  //   };
  //   try {
  //     ServiceUtils.postV2(GET_LIST_REPORT_WAITING_VERIFY, body, {
  //       headers: {
  //         Accept: "text/plain",
  //       },
  //     }).then((res: any) => {
  //       if ((res.data || {}).StatusCode === 200) {
  //         setDataList(res.data.Data);

  //         const count = ((res.data || {}).Data || {}).count;

  //         if (count % pageShowing === 0) {
  //           setTotalPage(Math.floor(count / pageShowing));
  //         } else {
  //           setTotalPage(Math.floor(count / pageShowing) + 1);
  //         }
  //       }
  //     });
  //   } catch (_error) {
  //     toast.error("Tải danh sách báo cáo thất bại.");
  //   }
  // };

  const _onLoadData = () => {
    const fetchData = async () => {
      try {
        const body: any = {
          Page: selectedPage,
          Limit: pageShowing,
          FromDate: fromDate,
          ToDate: toDate,
        };

        await ServiceUtils.postV2(GET_LIST_REPORT_WAITING_VERIFY, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setDataList(res.data.Data);

            if (res.data.Data.count % pageShowing === 0) {
              setTotalPage(Math.floor(res.data.Data.count / pageShowing));
            } else {
              setTotalPage(Math.floor(res.data.Data.count / pageShowing) + 1);
            }
          }
        });
      } catch (_error) {
        toast.error("Tải danh sách báo cáo thất bại.");
      }
    };

    fetchData();
  };

  // onMount data
  useEffect(() => {
    // _getListReportWaitingVerify(0);
    _onLoadData();
  }, [selectedPage, pageShowing]);

  // // onChange selectedPage
  // useEffect(() => {
  //   _getListReportWaitingVerify(selectedPage);
  // }, [selectedPage]);

  // // onChange pageShowing
  // useEffect(() => {
  //   setSelectedPage(0);

  //   _getListReportWaitingVerify(0);
  // }, [pageShowing]);

  const _onClickDetail2 = (id: string) => {
    context.setTaskId(id);
    // context.setDataModal({ periodic: periodic, taskName: taskName })

    // context.setFuncsLoad({
    //   onLoad: () => _getListReportWaitingVerify(0),
    // });
    setSelectedPage(0);
    context.setFuncsLoad({
      onLoad: () => _onLoadData(),
    });

    context.setFuncs("editReportInputForm");
    context.handleOpen();
  };

  const handleChange = (_event: React.ChangeEvent<unknown>, value: number) => {
    setSelectedPage(value - 1);
  };

  const context = useContext(ModalController);

  const _onClickSubmit = () => {
    _onLoadData();
  };
  return (
    <>
      <br></br>
      <div className="w-full h-max p-3">
        <div className="lg:space-x-2 md:space-x-2 mb-3 flex core-report-waiting-verify-filter">
          <DateTimePickerControl
            className="width-auto core-datetime-picker-resize"
            label={"Từ ngày"}
            value={fromDate}
            onChange={(e: any) => {
              const value = e;

              setFromDate(value);
            }}
          />

          <DateTimePickerControl
            className="width-auto core-datetime-picker-resize"
            label={"Đến ngày"}
            value={toDate}
            onChange={(e: any) => {
              const value = e;

              setToDate(value);
            }}
          />

          <div className="flex space-x-2 items-end justify-end lg:pl-3 md:pl-3 pt-3">
            <button
              className="
            flex items-center h-max p-2 bg-primary rounded-md text-white
              hover:bg-sky-400 active:shadow-inner active:shadow-slate-500 justify-center core-button-resize
            "
              onClick={_onClickSubmit}
            >
              <p>Xem</p>
            </button>
          </div>
        </div>
      </div>
      {dataList === null ? (
        // <div className="flex flex-col justify-center items-center w-full h-[calc(100%-10rem)] lg:mt-0 pb-10">
        //   <LoaderSpinner w={32} h={32} />
        //   <p className="text-primary">Đang tải dữ liệu</p>
        // </div>
        <LoadingControl />
      ) : (
        <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 pb-10">
          <div className="text-sm grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 w-full h-max items-end justify-between my-1 space-y-1">
            <div className="font-bold">
              <p>
                Tổng:&nbsp;
                {dataList === null ? "" : dataList?.count}
              </p>
              <p>
                Trang hiện tại:&nbsp;
                {dataList === null
                  ? ""
                  : dataList.data?.length + selectedPage * pageShowing}
              </p>
            </div>

            <div className="flex lg:justify-end md:justify-end w-full h-max items-center space-x-1">
              <p>Hiển thị</p>
              <select
                className="border-2 border-slate-200 rounded-md"
                value={pageShowing}
                onChange={(e: any) => {
                  e.preventDefault();
                  setPageShowing(e.target.value);
                  setSelectedPage(0);
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <p>dòng</p>
            </div>
          </div>

          <div
            className="tableFixHead styled-scrollbars w-full h-[calc(100%-5rem)] shadow-md"
            id="table"
          >
            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="border border-slate-300 ">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>STT</p>
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Nội dung yêu cầu</p>
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Loại yêu cầu</p>
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Ngày tạo</p>
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người yêu cầu</p>
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Thời hạn</p>
                    </div>
                  </th>
                  <th className="border border-slate-300"></th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {dataList === null ? (
                  <></>
                ) : (
                  dataList.reports?.map((item: any, index: number) => (
                    <tr key={index}>
                      <td className="border border-slate-300 text-center">
                        {index + 1 + selectedPage * pageShowing}
                      </td>
                      <td className="text-left border border-slate-300">
                        {item.ReportName}
                      </td>
                      <td className="text-left border border-slate-300">
                        {item.TypeReport === "True"
                          ? "BÁO CÁO CÔNG VIỆC"
                          : item.TypeReport === "False"
                          ? "BÁO CÁO NGOÀI CÔNG VIỆC"
                          : ""}
                      </td>
                      <td className="text-center border border-slate-300">
                        <div className="w-full h-full flex flex-col justify-center items-center">
                          <p>{moment(item.DateAdd).format("DD/MM/YYYY")}</p>
                          <p>{moment(item.DateAdd).format("HH:mm:ss")}</p>
                        </div>
                      </td>
                      <td className="text-left border border-slate-300">
                        {item.NameReportUser}
                      </td>
                      <td className="text-left border border-slate-300">
                        <div className="w-full h-full flex flex-col justify-center items-center">
                          <p>{moment(item.Deadline).format("DD/MM/YYYY")}</p>
                          <p>{moment(item.Deadline).format("HH:mm:ss")}</p>
                        </div>
                      </td>
                      <td className="text-center border border-slate-300">
                        <div className="ml-1 dropdown">
                          <div className="flex">
                            <button
                              className="dropbtn"
                              onMouseEnter={() => {
                                HandleUI.hoverMenuContextScrollTable(
                                  index,
                                  dataList.reports?.length
                                );
                              }}
                            >
                              <MoreHorizIcon fontSize="small" />
                            </button>

                            <div className="dropdown-content text-xs font-semibold">
                              <button
                                className="flex w-full h-full"
                                onClick={() => _onClickDetail2(item.ID)}
                              >
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  <div>Chi tiết</div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem] pt-2">
            <Pagination
              count={totalPage === null ? 0 : totalPage}
              page={selectedPage + 1}
              variant="outlined"
              color="primary"
              size="small"
              onChange={handleChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ReportWaitingVerify;
