import React, { useEffect, useRef, useState, useContext } from "react";
import { ModalController } from "../../../ui/homeUI/_index";
import parse, { HTMLReactParserOptions, Element } from "html-react-parser";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";

import CheckIcon from "@mui/icons-material/Check";

import SubModal from "../_subModal/_index";

import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";

import {
  BAO_CAO_CHI_TIET,
  BAO_CAO_UPDATE_REPORT,
  LOAD_ONE_REQUEST,
  LIST_OF_TEMPLATE,
  CREATE_REPORT,
} from "../../../apis/_index";

import moment from "moment";
import Select from "react-select";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import { toast } from "react-toastify";
import axios from "axios";

import reportView from "../../../temp/baocao";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import lacoLogo from "../../../temp/baocao/Asset7.png";
import LoaderSpinner from "../../loader-spinner/_index";
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";

const ReportDetailOwnerAndManagerElectric3 = (props: any) => {
  // Take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  const context = useContext(ModalController);

  const shouldLog = useRef(true);

  const [data, setData] = useState<any>(null);
  const [reportName, setReportName] = useState<string>("");
  const [isShowCreate, setIsShowCreate] = useState<boolean>(false);

  // State
  const [typeOfReport, setTypeOfReport] = useState<number>(0);
  const [reportHowTo, setReportHowTo] = useState<boolean | null>(null);

  const [listOfTemplate, setListOfTemplate] = useState<any[]>([]);
  const [selectedTemplateID, setSelectedTemplateID] = useState<string>("");
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");

  // -- Day
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");

  // -- Month
  const [month, setMonth] = useState<string>("");

  // -- Quarter
  const [quarter, setQuarter] = useState<string>("");

  // -- Year
  const [year, setYear] = useState<string>("");

  // -- Temp of other
  const [isChooseDate, setIsChooseDate] = useState<boolean>(false);

  // Funcs
  const _onClickSetIsShowCreate = () => {
    setIsShowCreate(!isShowCreate);
  };

  const resetData = () => {
    setFromDate("");
    setToDate("");
    setMonth("");
    setQuarter("");
    setYear("");
  };

  const _onChangeTypeOfReport = (data: any) => {
    if (data === null) {
      resetData();
      setTypeOfReport(0);
    } else {
      resetData();
      setTypeOfReport(data?.id);
    }
  };

  const _onClickSubmit = () => {
    let _fromDate: string = "";
    let _toDate: string = "";

    switch (typeOfReport) {
      case 1:
        _fromDate = moment(fromDate).format("YYYY-MM-DD");
        _toDate = moment(toDate).format("YYYY-MM-DD");
        break;
      case 2:
        _fromDate = moment(
          new Date(parseInt(year), parseInt(month) - 1)
        ).format("YYYY-MM-DD");
        _toDate = moment(new Date(parseInt(year), parseInt(month), 0)).format(
          "YYYY-MM-DD"
        );
        break;
      case 3:
        switch (quarter) {
          case "1":
            _fromDate = moment(new Date(parseInt(year), 0)).format(
              "YYYY-MM-DD"
            );
            _toDate = moment(new Date(parseInt(year), 3, 0)).format(
              "YYYY-MM-DD"
            );
            break;
          case "2":
            _fromDate = moment(new Date(parseInt(year), 3)).format(
              "YYYY-MM-DD"
            );
            _toDate = moment(new Date(parseInt(year), 6, 0)).format(
              "YYYY-MM-DD"
            );
            break;
          case "3":
            _fromDate = moment(new Date(parseInt(year), 6)).format(
              "YYYY-MM-DD"
            );
            _toDate = moment(new Date(parseInt(year), 9, 0)).format(
              "YYYY-MM-DD"
            );
            break;
          case "4":
            _fromDate = moment(new Date(parseInt(year), 9)).format(
              "YYYY-MM-DD"
            );
            _toDate = moment(new Date(parseInt(year), 12, 0)).format(
              "YYYY-MM-DD"
            );
            break;

          default:
            break;
        }
        break;
      case 4:
        _fromDate = moment(new Date(parseInt(year), 0)).format("DD-MM-YYYY");
        _toDate = moment(new Date(parseInt(year), 12, 0)).format("DD-MM-YYYY");
        break;
      case 5:
        break;

      default:
        break;
    }

    let result: string = "";
    // const result = `${typeOfReport.toString()},${_fromDate} 00:00:00:00,${_toDate}`

    // New setting
    switch (typeOfReport) {
      case 1:
        result = `${typeOfReport.toString()},${_fromDate} 00:00:00:00,${_toDate} 00:00:00:00`;
        break;
      case 2:
        result = month.toString();
        break;
      case 3:
        result = quarter.toString();
        break;
      case 4:
        result = year.toString();
        break;

      default:
        break;
    }

    // console.log({
    //   ReportID: data?.report?.ID,
    //   ReportTemplateID: selectedTemplateID,
    //   ReportName: reportName,
    //   TypeReport: reportHowTo,
    //   TypeReportJob: typeOfReport,
    //   TypeReportDetail: result,
    //   Content: selectedTemplate
    // })

    const takeData = async () => {
      const accessToken = localStorage.getItem("accessToken");
      const body: any = {
        RequestID: data?.report?.ID,
        ReportTemplateID: selectedTemplateID,
        ReportName: reportName,
        TypeReport: reportHowTo,
        TypeReportJob: typeOfReport,
        TypeReportDetail: result,
        Content: selectedTemplate,
      };
      try {
        await ServiceUtils.postV2(CREATE_REPORT, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data?.Data[0].RCode === 200) {
            toast.success("Tạo báo cáo thành công");
            _onClickDetail2(res.data?.Data[0].RId);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    takeData();
  };

  // onMount
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(LOAD_ONE_REQUEST + taskId + "&type=true", {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.data.StatusCode === 200) {
              setData(res.data.Data);
            } else {
              toast.error(res.data.Message);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      const takeTemplate = async () => {
        try {
          await ServiceUtils.getV2(LIST_OF_TEMPLATE, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.data.StatusCode === 200) {
              const tempArray = res.data.Data.map((item: any) => {
                return {
                  ...item,
                  value: item.NameOfTemplate,
                  label: item.NameOfTemplate,
                };
              });
              setListOfTemplate(tempArray);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
      takeTemplate();
    }
  }, []);

  // onDidUpdate

  // funcs
  const _onClickDetail2 = (id: string) => {
    context.setTaskId(id);
    // context.setDataModal({ periodic: periodic, taskName: taskName })

    // context.setFuncsLoad({
    //   onLoad: _onLoadReport
    // })

    context.setFuncs("editReportInputForm");
    context.handleOpen();
  };

  return (
    <div
      className="
        flex flex-col bg-white
        lg:w-[50rem] w-screen lg:h-[70vh] h-[calc(100vh-5rem)]
        rounded-md 
      "
    >
      {/* Headers */}
      <div
        className="        
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

        "
      >
        <p>Chi tiết yêu cầu</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* Content */}
      {data === null ? (
        // <div className="w-full h-[calc(100%-6rem)] flex p-3 text-sm justify-center items-center flex-col">
        //   <LoaderSpinner w={32} h={32} />
        //   <p className="text-primary">Đang tải dữ liệu</p>
        // </div>
        <LoadingControl />
      ) : (
        <>
          {isShowCreate === false ? (
            <div className="w-full h-[calc(100%-6rem)] flex flex-col p-3 text-sm space-y-4 overflow-auto">
              {/* Info */}
              <div className="w-full h-max flex flex-col space-y-1">
                <p className="text-primary font-bold">THÔNG TIN CHUNG</p>
                <div className="w-full h-max flex">
                  <p className="font-semibold">Người tạo yêu cầu: &nbsp;</p>
                  <div>{data.report?.NameUserRequest}</div>
                </div>

                <div className="w-full h-max flex">
                  <p className="font-semibold">Ngày tạo yêu cầu: &nbsp;</p>
                  <div>
                    {moment(data.report?.DateRequest).format("HH:mm:ss")} -{" "}
                    {moment(data.report?.DateRequest).format("DD/MM/YYYY")}
                  </div>
                </div>

                <div className="w-full h-max flex">
                  <p className="font-semibold">Thời hạn yêu cầu: &nbsp;</p>
                  <div>
                    {moment(data.report?.DeadLine).format("HH:mm:ss")} -{" "}
                    {moment(data.report?.DeadLine).format("DD/MM/YYYY")}
                  </div>
                </div>

                <div className="w-full h-max flex flex-col">
                  <p className="font-semibold">Nội dung yêu cầu: &nbsp;</p>
                  <div className="w-full h-max">
                    <textarea
                      className="w-full h-[5rem] rounded-md p-2 border-2 border-slate-200"
                      value={data.report?.RequestContent}
                      disabled
                    />
                  </div>
                </div>
              </div>

              {/* Detail */}
              <div className="w-full h-max flex flex-col space-y-1">
                <p className="font-bold text-primary">THÔNG TIN CHI TIẾT</p>

                {/* Table detail */}
                <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-max shadow-md">
                  <table>
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border-l-[1px] border-slate-300">STT</th>
                        <th className="border-l-[1px] border-slate-300">
                          Trạng thái
                        </th>
                        <th className="border-l-[1px] border-slate-300">
                          Nhân viên báo cáo
                        </th>
                        <th className="border-l-[1px] border-slate-300">
                          Lịch sử báo cáo 1
                        </th>
                        <th className="border-l-[1px] border-slate-300"></th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-max bg-red-200 overflow-auto">
                      {data?.report_Users?.map((item: any, index: number) => (
                        <tr key={index} className="w-max h-max bg-white">
                          <td className="text-center border border-slate-300">
                            {index + 1}
                          </td>
                          <td className="text-center border border-slate-300 font-semibold">
                            {item.ReportStatus === 0 ? (
                              <p className="text-amber-500">Đang yêu cầu</p>
                            ) : item.ReportStatus === 1 ? (
                              <p className="text-primary">Mới tạo báo cáo</p>
                            ) : item.ReportStatus === 2 ? (
                              <p className="text-purple-500">Đã gửi báo cáo</p>
                            ) : item.ReportStatus === 3 ? (
                              <p className="text-red-500">Không duyệt</p>
                            ) : item.ReportStatus === 4 ? (
                              <p className="text-gray-500">
                                Trả lại bổ sung hoặc chỉnh sửa
                              </p>
                            ) : item.ReportStatus === 5 ? (
                              <p className="text-green-500">Đã duyệt</p>
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="text-left border border-slate-300">
                            {item?.NameReportUser}
                          </td>
                          <td className="text-left border border-slate-300">
                            {item?.report_history.map(
                              (_item: any, _index: number) => (
                                <div
                                  key={_index}
                                  className="w-full h-max flex flex-col"
                                >
                                  <p className="font-semibold">
                                    {_index + 1}. {_item?.ReportName}
                                  </p>
                                  {_item?.report_reviewer_details.map(
                                    (_item2: any, _index2: number) => (
                                      <div
                                        key={_index2}
                                        className="w-full h-max flex"
                                      >
                                        <p>- {_item2.NameReviewer} - &nbsp;</p>
                                        <div className="font-semibold">
                                          {_item2.IsCheck === null ? (
                                            <p className="text-gray-500">
                                              Chưa duyệt
                                            </p>
                                          ) : _item2.IsCheck === true ? (
                                            <p className="text-green-500">
                                              Đã duyệt
                                            </p>
                                          ) : _item2.IsCheck === false ? (
                                            <p className="text-red-500">
                                              Không duyệt
                                            </p>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              )
                            )}
                          </td>
                          <td className="text-left border border-slate-300 space-x-2">
                            <button
                              className="text-xs bg-amber-500 hover:bg-amber-400 p-1 rounded-md"
                              onClick={_onClickSetIsShowCreate}
                            >
                              Tạo báo cáo
                            </button>
                            {/* <button className="text-xs bg-green-500 hover:bg-green-400 p-1 rounded-md">
                                    Nộp báo cáo
                                  </button> */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full h-[calc(100%-6rem)] flex flex-col p-3 text-sm space-y-4 overflow-auto">
              {/* Back button */}
              <div className="w-full h-max">
                <button
                  className="flex items-center space-x-1 text-primary hover:text-sky-400"
                  onClick={_onClickSetIsShowCreate}
                >
                  <ArrowBackIcon fontSize="small" />
                  <p>Quay lại</p>
                </button>
              </div>

              {/* Report's name */}
              <div className="flex flex-col w-full h-max">
                <label>Tên báo cáo</label>
                <input
                  className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                  value={reportName}
                  onChange={(e: any) => {
                    e.preventDefault();
                    setReportName(e.target.value);
                  }}
                />
              </div>

              {/* Type of report */}
              <div className="flex flex-col w-full h-max">
                <label>Hình thức báo cáo</label>
                <Select
                  className="z-[10]"
                  options={[
                    {
                      id: 0,
                      value: "Báo cáo công việc",
                      label: "Báo cáo công việc",
                    },
                    {
                      id: 1,
                      value: "Báo cáo ngoài công việc",
                      label: "Báo cáo ngoài công việc",
                    },
                  ]}
                  isClearable
                  onChange={(data: any) => {
                    if (data === null) {
                      setReportHowTo(null);
                    } else {
                      if (data?.id === 0) {
                        setReportHowTo(false);
                      } else {
                        setReportHowTo(true);
                      }
                    }
                  }}
                  placeholder="--- Chọn hình thức báo cáo ---"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              {/* Type report job */}
              <div className="flex flex-col w-full h-max">
                <label>Loại báo cáo</label>
                <Select
                  className="z-[9]"
                  options={[
                    {
                      id: 1,
                      value: "Báo cáo ngày",
                      label: "Báo cáo ngày",
                    },
                    {
                      id: 2,
                      value: "Báo cáo tháng",
                      label: "Báo cáo tháng",
                    },
                    {
                      id: 3,
                      value: "Báo cáo quý",
                      label: "Báo cáo quý",
                    },
                    {
                      id: 4,
                      value: "Báo cáo năm",
                      label: "Báo cáo năm",
                    },
                    {
                      id: 5,
                      value: "Báo cáo từng công việc",
                      label: "Báo cáo từng công việc",
                    },
                  ]}
                  isClearable
                  onChange={(data: any) => _onChangeTypeOfReport(data)}
                  placeholder="--- Chọn loại báo cáo ---"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              {/* From Date - To Date */}
              <div className="flex w-full h-max space-x-4">
                {typeOfReport === 0 ? (
                  <></>
                ) : typeOfReport === 1 ? (
                  <>
                    <div className="flex flex-col w-full h-max">
                      <label>Từ ngày</label>
                      <input
                        className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                        type="date"
                        value={fromDate}
                        onChange={(e: any) => {
                          e.preventDefault();
                          setFromDate(e.target.value);
                          console.log(e.target.value);
                        }}
                      />
                    </div>
                    <div className="flex flex-col w-full h-max">
                      <label>Đến ngày</label>
                      <input
                        className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                        type="date"
                        value={toDate}
                        onChange={(e: any) => {
                          e.preventDefault();
                          setToDate(e.target.value);
                          console.log(e.target.value);
                        }}
                      />
                    </div>
                  </>
                ) : typeOfReport === 2 ? (
                  <>
                    <div className="flex flex-col w-full h-max">
                      <label>Chọn tháng</label>
                      <Select
                        className="z-[8]"
                        options={[
                          {
                            id: 1,
                            value: "1",
                            label: "1",
                          },
                          {
                            id: 2,
                            value: "2",
                            label: "2",
                          },
                          {
                            id: 3,
                            value: "3",
                            label: "3",
                          },
                          {
                            id: 4,
                            value: "4",
                            label: "4",
                          },
                          {
                            id: 5,
                            value: "5",
                            label: "5",
                          },
                          {
                            id: 6,
                            value: "6",
                            label: "6",
                          },
                          {
                            id: 7,
                            value: "7",
                            label: "7",
                          },
                          {
                            id: 8,
                            value: "8",
                            label: "8",
                          },
                          {
                            id: 9,
                            value: "9",
                            label: "9",
                          },
                          {
                            id: 10,
                            value: "10",
                            label: "10  ",
                          },
                          {
                            id: 11,
                            value: "11",
                            label: "11",
                          },
                          {
                            id: 12,
                            value: "12",
                            label: "12",
                          },
                        ]}
                        isClearable
                        placeholder="--- Chọn tháng ---"
                        onChange={(data: any) => {
                          if (data === null) {
                            setMonth("");
                          } else {
                            setMonth(data.value);
                          }
                        }}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                    <div className="flex flex-col w-full h-max">
                      <label>Chọn năm</label>
                      <Select
                        className="z-[8]"
                        options={[
                          {
                            id: 2023,
                            value: "2023",
                            label: "2023",
                          },
                        ]}
                        isClearable
                        placeholder="--- Chọn năm ---"
                        onChange={(data: any) => {
                          if (data === null) {
                            setYear("");
                          } else {
                            setYear(data.value);
                          }
                        }}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  </>
                ) : typeOfReport === 3 ? (
                  <>
                    <div className="flex flex-col w-full h-max">
                      <label>Chọn quý</label>
                      <Select
                        className="z-[8]"
                        options={[
                          {
                            id: 1,
                            value: "1",
                            label: "1",
                          },
                          {
                            id: 2,
                            value: "2",
                            label: "2",
                          },
                          {
                            id: 3,
                            value: "3",
                            label: "3",
                          },
                          {
                            id: 4,
                            value: "4",
                            label: "4",
                          },
                        ]}
                        isClearable
                        placeholder="--- Chọn quý ---"
                        onChange={(data: any) => {
                          if (data === null) {
                            setQuarter("");
                          } else {
                            setQuarter(data.value);
                          }
                        }}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                    <div className="flex flex-col w-full h-max">
                      <label>Chọn năm</label>
                      <Select
                        className="z-[8]"
                        options={[
                          {
                            id: 2023,
                            value: "2023",
                            label: "2023",
                          },
                        ]}
                        isClearable
                        placeholder="--- Chọn năm ---"
                        onChange={(data: any) => {
                          if (data === null) {
                            setYear("");
                          } else {
                            setYear(data.value);
                          }
                        }}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  </>
                ) : typeOfReport === 4 ? (
                  <>
                    <div className="flex flex-col w-full h-max">
                      <label>Chọn năm</label>
                      <Select
                        className="z-[8]"
                        options={[
                          {
                            id: 2023,
                            value: "2023",
                            label: "2023",
                          },
                        ]}
                        isClearable
                        placeholder="--- Chọn năm ---"
                        onChange={(data: any) => {
                          if (data === null) {
                            setYear("");
                          } else {
                            setYear(data.value);
                          }
                        }}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  </>
                ) : typeOfReport === 5 ? (
                  <></>
                ) : (
                  <></>
                )}
              </div>

              <div className="flex flex-col w-full h-max">
                <label>Mẫu báo cáo</label>
                <Select
                  className="z-[7]"
                  options={listOfTemplate}
                  isClearable
                  onChange={(data: any) => {
                    if (data === null) {
                      setSelectedTemplate("");
                      setSelectedTemplateID("");
                    } else {
                      setSelectedTemplateID(data?.ID);
                      setSelectedTemplate(data?.Template);
                    }
                  }}
                  placeholder="--- Chọn mẫu báo cáo ---"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            </div>
          )}
        </>
      )}

      {/* Footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md  items-center text-sm space-x-4">
        <div className="flex w-full h-max space-x-3 justify-end items-center">
          <div className="w-max h-max flex space-x-3">
            {isShowCreate === true ? (
              <button
                className="
                    flex items-center space-x-1 w-max h-max p-2 bg-amber-500 text-white rounded-md
                    hover:bg-amber-400
                  "
                onClick={_onClickSubmit}
              >
                <ExitToAppTwoToneIcon fontSize="small" />
                <p>Tạo báo cáo</p>
              </button>
            ) : (
              <></>
            )}

            <button
              className="
              flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
              hover:bg-slate-200
            "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDetailOwnerAndManagerElectric3;
