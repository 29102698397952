import React, { useEffect, useRef, useState, useContext, useMemo } from "react";
import ReactDOMServer, { renderToString } from "react-dom/server";

import parse, { HTMLReactParserOptions, Element } from "html-react-parser";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";

// import autosize from "autosize";
import TextareaAutosize from "react-textarea-autosize";
import MoreTimeIcon from "@mui/icons-material/MoreTime";

import SubModal from "../_subModal/_index";
import moment from "moment";

import reportView from "../../../temp/baocao";
import { reportView2 } from "../../../temp/baocao/index2";

import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";

import {
  BAO_CAO_CHI_TIET,
  BAO_CAO_UPDATE_REPORT,
  BAO_CAO_SENT,
  READ_REPORT_NGUOINHAN,
  UPDATE_BAO_CAO,
  CREATE_REPORT_CONTENT,
} from "../../../apis/_index";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import { toast } from "react-toastify";
import axios from "axios";
import LoaderSpinner from "../../loader-spinner/_index";
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";

const SubViewReport = (props: any) => {
  // Take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);

  // state submodal
  const [data, setData] = useState<any>(null);
  const [template, setTemplate] = useState<string | null>(null);
  const [dataHistory, setDataHistory] = useState<any>(null);

  const [optionsParser, setOptionParser] = useState<any>();

  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const _onClickReportDelay = () => {
    setFuncsSub("reportDelay");
    handleOpenSub();
  };

  const _onClickSendReport = () => {
    setFuncsSub("reportSend");
    handleOpenSub();
  };

  // funcs
  const _onChangeReportDescriptionFuncs = (e: any, TaskID: string) => {
    const tempValue = dataHistory;
    const tempArray = [...dataHistory?.report_history];
    const tempObject = tempArray.find((item) => item.TaskID === TaskID);

    tempObject.Description = e.target.value;

    console.log("object", tempObject);
    console.log("array", tempArray);
    console.log("main", dataHistory);

    setDataHistory({ ...tempValue, report_history: tempArray });

    // setDataHistory((prevState: any) => {
    //   return prevState?.map((item: any) => {
    //     return {
    //       ...item,
    //       report_history: tempArray
    //     }
    //   })
    // })
  };

  // onMount
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(READ_REPORT_NGUOINHAN + taskId, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.data.StatusCode === 200) {
              setDataHistory(
                res.data.Data.report_history?.find(
                  (item: any) => item.ReportID === taskId
                )
              );
              setTemplate(res.data.Data.report_details?.ReportTemplateID);
              setData(res.data.Data);

              // console.log("res Data", data)
              // console.log("res history", dataHistory);
              // console.log("res template", template);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  });

  // onUpdate
  useEffect(() => {
    const options: HTMLReactParserOptions = {
      replace: (domNode) => {
        const typedDomNode = domNode as Element;

        if (typedDomNode.attribs) {
          if (data?.report_details?.TypeReportJob === "1") {
            if (typedDomNode.attribs.id === "reportDay") {
              return (
                <input className="w-3 h-3" type="checkbox" checked disabled />
              );
            }
          } else if (data?.report_details?.TypeReportJob === "2") {
            if (typedDomNode.attribs.id === "reportMonth") {
              return (
                <input className="w-3 h-3" type="checkbox" checked disabled />
              );
            }
          } else if (data?.report_details?.TypeReportJob === "3") {
            if (typedDomNode.attribs.id === "reportQuarter") {
              return (
                <input className="w-3 h-3" type="checkbox" checked disabled />
              );
            }
          } else if (data?.report_details?.TypeReportJob === "4") {
            if (typedDomNode.attribs.id === "reportYear") {
              return (
                <input className="w-3 h-3" type="checkbox" checked disabled />
              );
            }
          } else if (data?.report_details?.TypeReportJob === "5") {
            if (typedDomNode.attribs.id === "reportCreateOwnwer") {
              return (
                <input className="w-3 h-3" type="checkbox" checked disabled />
              );
            }
          } else {
          }
        }

        switch (typedDomNode?.attribs?.id) {
          case "nameStaff":
            return (
              <span className="font-normal">
                {data?.report_details?.NameReportUser}
              </span>
            );
          case "levelStaff":
            return (
              <span className="font-normal">
                {data?.report_details?.CHUCVU}
              </span>
            );
          case "phongBanStaff":
            return (
              <span className="font-normal">
                {data?.report_details?.PHONGBAN}
              </span>
            );

          case "workingCapacity":
            return (
              <span className="font-semibold">
                {data?.report_result[0]?.NANGSUAT}%
              </span>
            );
          case "supportCapacity":
            return (
              <span className="font-semibold">
                {data?.report_result[0]?.NANGSUATPHOIHOP}%
              </span>
            );
          case "workingLevel":
            return (
              <span className="font-semibold">
                {data?.report_result[0]?.NANGLUC}%
              </span>
            );
          case "supportLevel":
            return (
              <span className="font-semibold">
                {data?.report_result[0]?.NANGLUCPHOIHOP}%
              </span>
            );

          case "perfectResult":
            return (
              <span>{data?.report_result[0]?.Recipient_NumberExcellent}</span>
            );
          case "goodResult":
            return <span>{data?.report_result[0]?.Recipient_NumberGood}</span>;
          case "aveResult":
            return (
              <span>{data?.report_result[0]?.Recipient_NumberMedium}</span>
            );
          case "badResult":
            return <span>{data?.report_result[0]?.Recipient_NumberFail}</span>;

          case "receiverTask":
            return <span>{data?.report_result[0]?.DaNhan}</span>;
          case "completeTask":
            return <span>{data?.report_result[0]?.HoanTat}</span>;
          case "supportTask":
            return <span>{data?.report_result[0]?.HoTro}</span>;

          case "bonusOrPayoff":
            return (
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.report_history[0]?.RewardOrPunishment,
                }}
              />
            );
          case "planOfAction":
            return (
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.report_history[0]?.PlanOfAction,
                }}
              />
            );

          case "phongBanBoss":
            return (
              <span className="font-normal">
                {data?.report_listreviewer[0]?.PHONGBAN}
              </span>
            );
          case "levelBoss":
            return (
              <span className="font-normal">
                {data?.report_listreviewer[0]?.CHUCVU}
              </span>
            );
          case "taskOwner":
            return (
              <span className="font-normal">
                {data?.report_listreviewer[0]?.NameReviewer}
              </span>
            );
          case "bodyOfTaskResponsible":
            return (
              <>
                <tbody>
                  {data?.report_task?.map((item: any, index: number) => (
                    <tr key={index}>
                      <th
                        style={{ border: "2px solid black", height: "2rem" }}
                        scope="row"
                      >
                        {index + 1}
                      </th>
                      <td style={{ border: "2px solid black" }}>
                        <p className="font-semibold">{item.TaskName}</p>
                        <p>
                          Người giao: <span>{item.TaskOwnerName}</span>
                        </p>
                        <p>
                          Ngày tạo: {moment(item.DateAdd).format("DD-MM-YYYY")}
                        </p>
                        <p>
                          Thời hạn: {moment(item.DeadLine).format("DD-MM-YYYY")}
                        </p>
                        <div className="flex w-full h-max space-x-1">
                          <p>Trạng thái:</p>
                          {item.TaskStatus === 3 ? (
                            <p className="text-gray-500 font-bold">
                              Chưa xử lý
                            </p>
                          ) : item.TaskStatus === 4 ? (
                            <p className="text-amber-500 font-bold">
                              Đang xử lý
                            </p>
                          ) : item.TaskStatus === 5 ? (
                            <p className="text-green-500 font-bold">Hoàn tất</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="flex w-full h-max space-x-1">
                          <p>Kết quả:</p>
                          <p>{item.TaskScorecardName}</p>
                        </div>
                      </td>
                      <td
                        className="w-1/2 h-full"
                        style={{ border: "2px solid black" }}
                      >
                        {/* <TextareaAutosize
                          style={{
                            height: 80
                          }}
                          className="w-full rounded-md pl-2 pr-2 border-2 border-slate-200"

                          value={dataHistory?.report_history[index]?.Description}
                        /> */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              dataHistory?.report_history[index]?.Description,
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            );
        }
      },
    };

    setOptionParser(options);
  }, [data]);

  // memo
  const dataBodyReader = useMemo(() => {
    const tempData = data?.report_history?.find(
      (item: any) => item.ReportID === taskId
    );
    return tempData;
  }, [data, taskId]);

  const FinalJSX = () => {
    return <>{parse(reportView, optionsParser)}</>;
  };

  // Funcs
  const _onClickCreateContent = () => {
    const accessToken = localStorage.getItem("accessToken");

    const takeData = async () => {
      const body: any = {
        ReportID: taskId,
        Content: renderToString(<FinalJSX />),
      };
      try {
        await ServiceUtils.postV2(UPDATE_BAO_CAO, body, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            toast.success("Lưu nội dung thành công");
          } else {
            toast.success("Lưu nội dung thất bại");
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    takeData();
  };

  return (
    <div
      className="
        flex flex-col bg-white
        lg:w-[50rem] w-screen lg:h-[90vh] h-[calc(100vh-5rem)]
        rounded-md overflow-x-auto
    "
    >
      <SubModal
        taskId={taskId}
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
      />
      {/* Headers */}
      <div
        className="        
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between
    "
      >
        <p>Biểu mẫu báo cáo</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* Content */}
      {data === null ? (
        <div className="w-full h-[calc(100%-6rem)] flex p-3 text-sm overflow-scroll">
          <LoadingControl />
        </div>
      ) : (
        <div className="w-full h-[calc(100%-6rem)] flex p-3 text-sm overflow-scroll">
          <div className="w-full h-max flex">
            <div
              style={{
                width: "100%",
                height: "max-content",
              }}
            >
              {data === null || dataHistory === null || template === null ? (
                <></>
              ) : (
                <FinalJSX />
              )}
            </div>
          </div>
        </div>
      )}

      {/* Footer */}
      <div className="flex footer w-full p-12-16  rounded-b-md items-center text-sm space-x-4">
        <div className="flex w-full h-max space-x-3 justify-end items-center">
          {/* Print button */}

          {/* Close button */}
          <div className="w-max h-max flex space-x-3">
            {/* <button
            className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-red-500 text-white rounded-md
          hover:bg-red-400
        "
            onClick={_onClickDeleteReport}
          >
            <DeleteIcon fontSize="small" />
            <p>Xoá</p>
          </button> */}

            {/* Ask delay */}
            <button
              className="flex font-bold items-center space-x-1 w-max h-max p-2 border border-primary hover:bg-primary text-primary hover:text-white rounded-md drop-shadow-lg transition-all delay-65"
              onClick={_onClickReportDelay}
            >
              <MoreTimeIcon fontSize="small" />
              <p>Xin gia hạn</p>
            </button>

            <button
              className="
          flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
          hover:bg-green-400
        "
              onClick={_onClickCreateContent}
            >
              <SendIcon fontSize="small" />
              <p>Lưu báo cáo</p>
            </button>

            <button
              className="
                flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
                hover:bg-slate-200
              "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubViewReport;
