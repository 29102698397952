/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReplayIcon from "@mui/icons-material/Replay";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import {
  READ_ALL_LIST_QUYTRINH_DROPDOWN,
  LOAD_PHONG_BAN,
  PRIOR_LIST_DROPDOWN,
  SECURITY_READ_ALL_DROPDOWN,
  READ_TASK_LIST_BY_USER,
  TAKE_PARAMETER_LISTKEY_DROPDOWN,
  DETAIL_STEP_DROPDOWN,
  DETAIL_RESULT_DROPDOWN,
  NGUOI_GIAO_VIEC_UPDATE,
  CREATE_TASK_LIST_NGUOI_GIAO,
  NGUOI_GIAO_VIEC_LIST,
  DIFFICULT_LEVEL,
  CONFIG_IS_FORCE,
  DIFICULT_LEVEL_DROPDOWN,
  LOAD_PHONG_BAN_BY_USER,
  NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
  CREATE_TASK_RECOVERY_API,
  TASK_RECOVERY_LIST,
  APPROVE_TASK,
  CREATE_TASK_MULTI_LIST_NGUOI_GIAO,
  LOAD_SUGGEST_LIST,
  NGUOI_GIAO_VIEC_DETAIL,
} from "../../../apis/_index";

import LoaderSpinner from "../../loader-spinner/_index";
import NextPlanIcon from "@mui/icons-material/NextPlan";

import Select, { StylesConfig } from "react-select";

import { takePermission } from "../../../funcs";

import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";

import RedoIcon from "@mui/icons-material/Redo";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";

import { readList } from "../../../funcs/taskNguoiGiaoViec";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SubModal from "../_subModal/_index";
import { toast } from "react-toastify";
import CircleIcon from "@mui/icons-material/Circle";
import {
  ONCHANGE_VIEC_CAN_GIAO,
  ONLOAD_TASKLIST_ALL,
} from "../../../redux/reducers/workingReducer/_index";
import CheckBox from "@mui/icons-material/CheckBox";
import DateTimePickerControl from "../../../controls/datetimePicker/datetimePickerControl";
import { MODAL_KEYS } from "../../../constants/constants";

import UndoIcon from "@mui/icons-material/Undo";
import { _onClickFuncs } from "../../../funcs/onClickShowBtn";
import ServiceUtils from "../../../utils/services";
import TaskRepository from "../../../repositories/tasks/taskRepository";

export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly color: string;
  readonly isFixed?: boolean;
  readonly isDisabled?: boolean;
}

const EditTask = (props: any) => {
  // reducer
  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  const [fileListAPI, setFileListAPI] = useState<any[]>([]);

  let userData: any = localStorage.getItem("userData");
  let defaultPhongBanTaskReviewer: any;
  let defaultTaskReviewer: any;

  userData = userData.replace(/'/g, '"'); //replacing all ' with "
  userData = JSON.parse(userData);
  userData.Data.map(
    (item: any) =>
    (defaultPhongBanTaskReviewer = {
      IdPhongBan: item?.PhongBan,
      TenPhongBan: item?.TenPhongBan,
    })
  );
  userData.Data.map((item: any) => {
    defaultTaskReviewer = {
      FullName: item?.FullName,
      Id: item?.Id,
    };
  });

  // take props
  const handleClose = props.handleClose;
  const taskId = props.taskId;

  // state
  const shouldLog = useRef(true);
  const shouldLog3 = useRef(true);
  const urlLink = window.location.pathname;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const menu = takePermission(19);

  // submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [isShowSuggest, setIsShowSuggest] = useState<boolean>(false);
  const [suggest, setSuggest] = useState<string>("");
  const [suggestList, setSuggestList] = useState<any[] | null>(null);
  const [taskStatus, setTaskStatus] = useState<number | null>(null);

  const [isEvaluate, setIsEvaluate] = useState<boolean>(false);
  const [isExChange, setIsExChange] = useState<boolean>(true);
  const [useHourSupporter, setUseHourSupporter] = useState<any[]>([]);
  const [useMinutesSupporter, setUseMinutesSupporter] = useState<any[]>([]);
  const [phongBanList, setPhongBanList] = useState<any[] | null>(null);
  const [phongBanSelectedForSelect, setPhongBanSelectedForSelect] =
    useState<any>(-1);
  const [phongBanListForSelect, setPhongBanListForSelect] = useState<
    any[] | undefined
  >(undefined);
  const [userList, setUserList] = useState<any[] | null>(null);
  const [userListForSelect, setUserListForSelect] = useState<any[] | undefined>(
    undefined
  );
  const [userListForSelected, setUserListForSelected] = useState<
    any[] | undefined
  >(undefined);
  const [loaiViecList, setLoaiViecList] = useState<any[] | null>(null);
  const [nguonVanBanList, setNguonVanBanList] = useState<any[] | null>(null);
  const [uuTienList, setUuTienList] = useState<any[] | null>(null);
  const [doMatList, setDoMatList] = useState<any[] | null>(null);
  const [dauViecList, setDauViecList] = useState<any[] | null>(null);
  const [quyTrinhList, setQuyTrinhList] = useState<any[] | null>(null);
  const [doKhoList, setDoKhoList] = useState<any[] | null>(null);
  const uploadFileChat = useRef<any>(null);

  const [selectedDoKho, setSelectedDoKho] = useState<any>(-1);
  const [selectedPhongBan, setSelectedPhongBan] = useState<any>(-1);
  const [selectedNguoiNhan, setSelectedNguoiNhan] = useState<any>(-1);
  const [isMulti, setIsMulti] = useState<boolean>(false);
  const [listMultiUser, setListMultiUser] = useState<any[]>([]);
  const [userDanhGiaList, setUserDanhGiaList] = useState<any>(null);
  const [phongBanDanhGiaList, setPhongBanDanhGiaList] = useState<any[] | null>(
    null
  );

  const [selectedPhongBanDanhGia, setSelectedPhongBanDanhGia] = useState<any>(
    defaultPhongBanTaskReviewer || null
  );

  const [selectedNguoiDanhGiaList, setSelectedNguoiDanhGiaList] =
    useState<any>(null);

  const [selectedNguoiDanhGia, setSelectedNguoiDanhGia] = useState<any>(
    defaultTaskReviewer || null
  );

  const [selectedLoaiViec, setSelectedLoaiViec] = useState<any>(-1);
  const [selectedNguonVanBan, setSelectedNguonVanBan] = useState<any>("");
  const [selectedUuTien, setSelectedUuTien] = useState<any>(-1);
  const [selectedDoMat, setSelectedDoMat] = useState<any>(-1);
  const [selectedDauViec, setSelectedDauViec] = useState<any>(-1);
  const [selectedQuyTrinh, setSelectedQuyTrinh] = useState<any>(-1);

  const [isAutoForce, setIsAutoForce] = useState<boolean | null>(null);
  const [isAutoRequiredUseHours, setIsAutoRequiredUseHours] = useState<
    boolean | null
  >(null);
  const [isAutoRequiredUseMinutes, setIsAutoRequiredUseMinutes] = useState<
    boolean | null
  >(null);
  const [isAutoSecurity, setIsAutoSecurity] = useState<boolean | null>(null);
  const [isAutoPriority, setIsAutoPriority] = useState<boolean | null>(null);
  const [isAutoDifference, setIsAutoDifference] = useState<boolean | null>(
    null
  );
  const [isAutoOrigin, setIsAutoOrigin] = useState<boolean | null>(null);
  const [isAutoLoaiViec, setIsAutoLoaiViec] = useState<boolean | null>(null);
  const [priorityDefault, setPriorityDefault] = useState<number | null>(null);

  const [selectedNguoiNhanList, setSelectedNguoiNhanList] = useState<any>(null);
  const [selectedNguoiPhoiHopList, setSelectedNguoiPhoiHopList] =
    useState<any>(null);

  const [selectedNguoiLamViecList, setSelectedNguoiLamViecList] = useState<any>(
    []
  );
  const [selectedNguoiGopYList, setSelectedNguoiGopYList] = useState<any>([]);

  const [selectedBuocList, setSelectedBuocList] = useState<any>(null);
  const [selectedKetQuaList, setSelectedKetQuaList] = useState<any>(null);

  const [filesList, setFilesList] = useState<any>([]);
  const [notes, setNotes] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isForce, setIsForce] = useState<boolean>(false);
  const [isChooseTaskReviewer, setIsChooseTaskReviewer] =
    useState<boolean>(false);
  const [isSelectedBuocOK, setIsSelectedBuocOK] = useState<boolean>(false);

  // other thing
  const [taskName, setTaskName] = useState<any>("");
  const [deadLine, setDeadLine] = useState<any>("");
  const [refCode, setRefCode] = useState<any>("");

  const [bodyAdding, setBodyAdding] = useState<any>();

  // props - states
  const [positionStep, setPositionStep] = useState<number | null>(null);
  const [hourStep, setHourStep] = useState<number | null>(null);
  const [contentStep, setContentStep] = useState<string>("");

  const [ketqua, setKetqua] = useState<string>("");
  const [isAllUser, setIsAllUser] = useState<boolean>(false);

  const [useHours, setUseHours] = useState<number>(0);
  const [useMinutes, setUseMinutes] = useState<number>(0);

  const [dataUpdate, setDataUpdate] = useState(new FormData());
  const [taskCode, setTaskCode] = useState<any>("");

  const [organizationId, setOrganizationId] = useState<any>(null);

  const _isTransfer = props.isTransfer;
  // funcs
  const _onChangeRefCode = (e: any) => {
    e.preventDefault();
    setRefCode(e.target.value);
  };

  const _onChangeHours = (e: any) => {
    e.preventDefault();
    setUseHours(e?.target?.value);
  };

  const _onChangeMinutes = (e: any) => {
    e.preventDefault();
    setUseMinutes(e?.target?.value);
  };

  const _onChangeHoursSupporter = (e: any, id: any) => {
    const tempArray = useHourSupporter.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          value: parseInt(e.target.value),
        };
      } else {
        return {
          ...item,
        };
      }
    });
    setUseHourSupporter(tempArray);
  };

  const _onChangeMinutesSupporter = (e: any, id: any) => {
    const tempArray = useMinutesSupporter.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          value: parseInt(e.target.value),
        };
      } else {
        return {
          ...item,
        };
      }
    });
    setUseMinutesSupporter(tempArray);
  };

  const _onConvertToSelect = (
    arr: any[] | null = [],
    name: string,
    value: string,
    color: string | null = null
  ) => {
    let newArr: any[] | null = arr === null ? [] : [...arr];
    newArr = newArr.map((x: any) => ({
      ...x,
      label: x[name],
      value: x[value],
      color: color !== null ? x[color] : "#000",
    }));
    return newArr;
  };

  const _onChangePhongBan = (e: any) => {
    e.preventDefault();

    const value = e.target.value;

    setSelectedPhongBan(
      phongBanList?.find((item: any) =>
        (item?.IdPhongBan || "")
          .toString()
          .split(",")
          .find((p: any) => p == parseInt(value))
          ? true
          : false
      )
    );

    setSelectedNguoiNhanList(
      userList?.filter((item: any) =>
        (item?.IdPhongBan || "")
          .split(",")
          .find((p: any) => (p || "").trim() == value)
      )
    );

    setSelectedNguoiNhan(-1);

    setSelectedNguoiLamViecList([]);
    setSelectedNguoiGopYList([]);

    setUseHourSupporter([]);
    setUseMinutesSupporter([]);

    setSelectedQuyTrinh(-1);
    setSelectedBuocList(null);
    setSelectedKetQuaList(null);

    setOrganizationId(value);
  };

  const _onChangeNguoiNhan = (e: any) => {
    e.preventDefault();

    setSelectedNguoiNhan(
      selectedNguoiNhanList?.find((item: any) => item?.Id === e.target.value)
    );
    setSelectedNguoiPhoiHopList(
      userList?.filter((item: any) => item?.Id !== e.target.value)
    );

    const tempArray = [
      selectedNguoiNhanList?.find((item: any) => item?.Id === e.target.value),
    ];

    setSelectedNguoiLamViecList(tempArray);
    setSelectedNguoiGopYList(tempArray);
  };

  // -- edit files
  const saveEditFile = (_index: number, fileName: string) => {
    const tempArray = [...filesList];

    const updateArray = tempArray.map((item: any, index: any) => {
      if (index === _index) {
        const renamedFile = new Blob([item], {
          type: item?.type,
        });

        const fileWithNewName = new File([renamedFile], fileName, {
          type: item?.type,
          lastModified: item?.lastModified,
        });

        return fileWithNewName;
      } else {
        return item;
      }
    });

    setFilesList(updateArray);
  };

  const _onClickEditFile = (index: string, file: any, setFile: any) => {
    setFuncsSub("openEditFileSub");
    setBodyAdding({
      filesList2: file,
      setFilesList2: setFile,
      saveEditFile,
      index,
    });
    handleOpenSub();
  };
  // -- end edit file

  const _onChangePhongBanDanhGia = (e: any) => {
    e.preventDefault();
    setSelectedPhongBanDanhGia(
      phongBanDanhGiaList?.find((item: any) =>
        (item?.IdPhongBan || "")
          .toString()
          .split(",")
          .find((p: any) => p == parseInt(e.target.value))
          ? true
          : false
      )
    );
    setSelectedNguoiDanhGiaList(
      userDanhGiaList?.filter((item: any) =>
        (item?.IdPhongBan || "")
          .toString()
          .split(",")
          .find((p: any) => p == parseInt(e.target.value))
          ? true
          : false
      )
    );
    setSelectedNguoiDanhGia(-1);
  };

  const _onChangeNguoiDanhGia = (e: any) => {
    e.preventDefault();
    setSelectedNguoiDanhGia(
      selectedNguoiDanhGiaList?.find((item: any) => item?.Id === e.target.value)
    );
  };

  const _onChangeStepList = (type: string, value: any, index: any) => {
    switch (type) {
      case "stepName":
        let newArray = [...selectedBuocList];
        newArray[index].StepName = value;
        setSelectedBuocList(newArray);
        break;
      case "orderBy":
        let newArray2 = [...selectedBuocList];
        newArray2[index].SortOrder = value;
        setSelectedBuocList(newArray2);
        break;
      case "result":
        let newArray3 = [...selectedKetQuaList];
        newArray3[index].tenketqua = value;
        setSelectedKetQuaList(newArray3);
        break;
      default:
        break;
    }
  };

  const _onCheckNguoiLamViec = (id: any) => {
    const isTrue = selectedNguoiLamViecList?.find(
      (item: any) => item?.Id === id
    );

    const isCounterTrue = selectedNguoiGopYList?.find(
      (item: any) => item?.Id === id
    );

    if (isTrue === undefined) {
      setSelectedNguoiLamViecList((oldValue: any) => [
        ...oldValue,
        userList?.find((item: any) => item?.Id === id),
      ]);

      setUseHourSupporter((oldValue: any) => [
        ...oldValue,
        {
          id: id,
          value: 0,
        },
      ]);

      setUseMinutesSupporter((oldValue: any) => [
        ...oldValue,
        {
          id: id,
          value: 0,
        },
      ]);

      // Countering
      if (isCounterTrue !== undefined) {
        const tempArray = selectedNguoiGopYList?.filter(
          (item: any) => item?.Id !== id
        );

        setSelectedNguoiGopYList(tempArray);
      }
    } else {
      const tempArray = selectedNguoiLamViecList?.filter(
        (item: any) => item?.Id !== id
      );

      const tempHours = useHourSupporter?.filter(
        (item: any) => item?.id !== id
      );
      const tempMinutes = useMinutesSupporter?.filter(
        (item: any) => item?.id !== id
      );

      setSelectedNguoiLamViecList(tempArray);
      setUseHourSupporter(tempHours);
      setUseMinutesSupporter(tempMinutes);
    }
  };

  const _onCheckNguoiGopY = (id: any) => {
    const isTrue = selectedNguoiGopYList?.find((item: any) => item?.Id === id);

    const isCounterTrue = selectedNguoiLamViecList?.find(
      (item: any) => item?.Id === id
    );

    if (isTrue === undefined) {
      setSelectedNguoiGopYList((oldValue: any) => [
        ...oldValue,
        userList?.find((item: any) => item?.Id === id),
      ]);

      // Countering
      if (isCounterTrue !== undefined) {
        const tempArray = selectedNguoiLamViecList?.filter(
          (item: any) => item?.Id !== id
        );

        const tempHours = useHourSupporter?.filter(
          (item: any) => item?.id !== id
        );
        const tempMinutes = useMinutesSupporter?.filter(
          (item: any) => item?.id !== id
        );

        setSelectedNguoiLamViecList(tempArray);
        setUseHourSupporter(tempHours);
        setUseMinutesSupporter(tempMinutes);
      }
    } else {
      const tempArray = selectedNguoiGopYList?.filter(
        (item: any) => item?.Id !== id
      );

      setSelectedNguoiGopYList(tempArray);
    }
  };

  // Check available member in array
  // useEffect(() => {
  //   console.log("Nguoi phoi hop", selectedNguoiLamViecList)
  //   console.log("Nguoi gop y", selectedNguoiGopYList)
  // }, [selectedNguoiGopYList, selectedNguoiLamViecList])

  const _onChangeQuyTrinh = (e: any) => {
    e.preventDefault();

    if (e.target.value === "-1") {
      setSelectedQuyTrinh(-1);
      setSelectedBuocList(null);
      setSelectedKetQuaList(null);
    } else {
      const accessToken = localStorage.getItem("accessToken");

      setSelectedQuyTrinh(
        quyTrinhList?.find((item: any) => item?.id === parseInt(e.target.value))
      );

      const takeStep = async () => {
        try {
          await ServiceUtils.getV2(DETAIL_STEP_DROPDOWN + e.target.value, {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain",
            },
          })
            .then((res: any) => {
              let tempArray: any[] = [];

              res.data.Data.forEach((item: any, index: any) => {
                const body = {
                  StepName: item?.tenbuoc,
                  UserDo: "",
                  DeadLine_Step: "",
                  SortOrder: item?.orderBy,
                };
                tempArray.push(body);
              });

              setSelectedBuocList(tempArray);
            });
        } catch (error: any) { }
      };

      const takeResult = async () => {
        try {
          await ServiceUtils.getV2(DETAIL_RESULT_DROPDOWN + e.target.value, {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain",
            },
          })
            .then((res: any) => {
              setSelectedKetQuaList(res.data.Data);
            });
        } catch (error: any) { }
      };

      takeStep();
      takeResult();
    }
  };

  // non - funcs
  const _onChangeNguoiPhoiHop = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const _onClickEditBuoc = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const _onClickEditKetqua = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  // props - funcs
  // buoc
  const _onChangePositionStep = (e: any) => {
    e.preventDefault();
    setPositionStep(parseInt(e.target.value));
  };

  const _onChangeHourStep = (e: any) => {
    e.preventDefault();
    setHourStep(e.target.value);
  };

  const _onChangeContentStep = (e: any) => {
    e.preventDefault();
    setContentStep(e.target.value);
  };

  const _onPushStep = () => {
    const body = {
      StepName: contentStep,
      UserDo: "",
      DeadLine_Step: "",
      SortOrder: positionStep,
    };

    if (selectedBuocList === null || selectedBuocList === undefined) {
      setSelectedBuocList([body]);
    } else {
      let isExistedStep = selectedBuocList.find(
        (item: any) => item?.SortOrder === positionStep
      );

      if (isExistedStep === undefined) {
        setSelectedBuocList((oldValue: any) => [...oldValue, body]);
      } else {
      }
    }

    setPositionStep(null);
    setHourStep(null);
    setContentStep("");
  };

  const _onDeleteQuyTrinh = (step: any) => {
    setSelectedBuocList(
      selectedBuocList.filter((item: any) => item?.SortOrder !== step)
    );
  };

  const _onChangeNguoiPhoiHopEachStep = (orderBy: any, nguoiPhoiHop: any) => {
    const updateArray = selectedBuocList.map((item: any) => {
      if (item?.SortOrder === orderBy) {
        return {
          ...item,
          UserDo: nguoiPhoiHop,
        };
      }

      return item;
    });

    setSelectedBuocList(updateArray);
  };

  const _onChangDeadLineStep = (orderBy: any, deadLine: any) => {
    const updateArray = selectedBuocList.map((item: any) => {
      if (item?.SortOrder === orderBy) {
        return {
          ...item,
          DeadLine_Step: deadLine,
        };
      }

      return item;
    });

    setSelectedBuocList(updateArray);
  };
  const _onClickChooseFileChat = () => {
    uploadFileChat.current.click();
  };
  // ket qua
  const _onChangeKetqua = (e: any) => {
    e.preventDefault();
    setKetqua(e.target.value);
  };

  const _onPushKetqua = () => {
    const body = {
      idquytrinh: selectedQuyTrinh?.Id,
      tenketqua: ketqua,
    };

    if (selectedKetQuaList === null) {
      setSelectedKetQuaList([body]);
    } else {
      setSelectedKetQuaList((oldValue: any) => [...oldValue, body]);
    }

    setKetqua("");
  };

  const _onChangePhongBanSelect = (e: any, type: number) => {
    e.preventDefault();

    const nguoiNhan = userListForSelect?.filter((item: any) =>
      (item?.IdPhongBan || "")
        .toString()
        .split(",")
        .find((p: any) => p == parseInt(e?.target?.value))
        ? true
        : false
    );

    setUserListForSelected(nguoiNhan);

    setPhongBanSelectedForSelect(
      phongBanListForSelect?.find((item: any) =>
        (item?.IdPhongBan || "")
          .toString()
          .split(",")
          .find((p: any) => p == parseInt(e?.target?.value))
          ? true
          : false
      )
    );
  };

  const _onDeleteKetqua = (ketQua: string) => {
    setSelectedKetQuaList(
      selectedKetQuaList.filter((item: any) => item?.tenketqua !== ketQua)
    );
  };

  // files
  const _onChangeFileImage = (e: any) => {
    const files = e.target.files || [];

    let tempArray: any[] = [...filesList] || [];

    for (let i = 0; i < files.length; i++) {
      tempArray.push(
        new File([files[i]], files[i].name, { type: files[i].type })
      );
    }

    setFilesList(tempArray);
  };

  const _onDeleteElements = (index: number) => {
    const tempArray: any[] = [...filesList];
    tempArray.splice(index, 1);
    setFilesList(tempArray);
  };

  // other things
  const _onChangeTaskName = (e: any) => {
    e.preventDefault();
    setTaskName(e.target.value);
  };

  const _onChangeDeadLine = (e: any) => {
    e.preventDefault();
    setDeadLine(e.target.value);
  };

  const _onChangeDeadLine2 = (e: any) => {
    // e.preventDefault();

    const value = e;
    setDeadLine(value);
  };

  const _onChangeSelectedLoaiCongViec = (e: any) => {
    e.preventDefault();
    setSelectedLoaiViec(
      loaiViecList?.find(
        (item: any) => item?.ParameterId === parseInt(e.target.value)
      )
    );
  };

  const _onChangeNguonVanBan = (e: any) => {
    e.preventDefault();
    setSelectedNguonVanBan(
      nguonVanBanList?.find(
        (item: any) => item?.ParameterId === parseInt(e.target.value)
      )
    );
  };

  const _onChangeNewNguonVanBan = (e: any) => {
    e.preventDefault();
    setSelectedNguonVanBan(e.target.value);
  };

  const _onChangeDoKho = (e: any) => {
    e.preventDefault();
    setSelectedDoKho(
      doKhoList?.find((item: any) => item?.Id === parseInt(e.target.value))
    );
  };

  const _onChangeSelectedUuTien = (e: any) => {
    e.preventDefault();
    setSelectedUuTien(
      uuTienList?.find((item: any) => item?.Id === parseInt(e.target.value))
    );
  };

  const _onChangeDoMat = (e: any) => {
    e.preventDefault();
    setSelectedDoMat(
      doMatList?.find((item: any) => item?.Id === e.target.value)
    );
  };

  const _onChangeDauViec = (e: any) => {
    e.preventDefault();
    setSelectedDauViec(
      dauViecList?.find((item: any) => item?.Id === parseInt(e.target.value))
    );
  };

  const _onChangeNotes = (e: any) => {
    e.preventDefault();
    setNotes(e.target.value);
  };

  const _onChangeDescription = (e: any) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  const _onChangeIsForce = () => {
    setIsForce((item: any) => !item);
  };

  const _onChangeIsMulti = () => {
    setIsMulti((item: any) => !item);
  };

  const _onChangeIsChooseTaskReviewer = () => {
    setIsChooseTaskReviewer((item: any) => !item);
  };

  // -- Caching temporary data
  const _onClickCaching = () => {
    /*
      - selectedDauViec
      - taskName
      - selectedLoaiViec
      - selectedDoMat
      - deadLine
      - selectedNguonVanBan
      - selectedUuTien
      - selectedDoKho
      - selectedPhongBan
      - selectedNguoiNhan
      - isChooseTaskReviewer
      - selectedPhongBanDanhGia
      - selectedNguoiDanhGia
      - selectedQuyTrinh
      - isForce
      - selectedBuocList
      - selectedKetquaList
      - description
      - notes
      - selectedNguoiNhanList
      - selectedNguoiPhoiHopList
      - selectedNguoiLamViecList
      - dauViecList,
      - quyTrinhList
    */

    const body = {
      selectedDauViec,

      taskName,
      selectedLoaiViec,
      selectedDoMat,
      deadLine,
      selectedNguonVanBan,
      selectedUuTien,
      selectedDoKho,
      selectedPhongBan,
      selectedNguoiNhan,
      isChooseTaskReviewer,
      selectedPhongBanDanhGia,
      selectedNguoiDanhGia,
      selectedQuyTrinh,
      isForce,
      selectedBuocList,
      selectedKetQuaList,
      description,
      notes,
      selectedNguoiNhanList,
      selectedNguoiPhoiHopList,
      selectedNguoiLamViecList,
      selectedNguoiGopYList,

      dauViecList,
      quyTrinhList,
    };

    localStorage.setItem("tempTaskCreate", JSON.stringify(body));
    handleClose();
  };
  // -- End caching

  // Take Caching
  useEffect(() => {
    const tempTaskCreate = localStorage.getItem("tempTaskCreate");

    if (tempTaskCreate === null || tempTaskCreate === undefined) {
    } else {
      /*
        - selectedDauViec
        - taskName
        - selectedLoaiViec
        - selectedDoMat
        - deadLine
        - selectedNguonVanBan
        - selectedUuTien
        - selectedDoKho
        - selectedPhongBan
        - selectedNguoiNhan
        - isChooseTaskReviewer
        - selectedPhongBanDanhGia
        - selectedNguoiDanhGia
        - selectedQuyTrinh
        - isForce
        - selectedBuocList
        - selectedKetquaList
        - description
        - notes
        - selectedNguoiNhanList
        - selectedNguoiPhoiHopList
        - selectedNguoiLamViecList
        - dauViecList,
        - quyTrinhList
      */

      const tempObject = JSON.parse(tempTaskCreate);

      setSelectedDauViec(tempObject?.selectedDauViec);

      setTaskName(tempObject?.taskName);
      setSelectedLoaiViec(tempObject?.selectedLoaiViec);
      setSelectedDoMat(tempObject?.selectedDoMat);
      setDeadLine(tempObject?.deadLine);
      setSelectedNguonVanBan(tempObject?.selectedNguonVanBan);
      setSelectedUuTien(tempObject?.selectedUuTien);
      setSelectedDoKho(tempObject?.selectedDoKho);
      setSelectedPhongBan(tempObject?.selectedPhongBan);
      setSelectedNguoiNhan(tempObject?.selectedNguoiNhan);
      setIsChooseTaskReviewer(tempObject?.isChooseTaskReviewer);
      setSelectedPhongBanDanhGia(tempObject?.selectedPhongBanDanhGia);
      setSelectedNguoiDanhGia(tempObject?.selectedNguoiDanhGia);
      setSelectedQuyTrinh(tempObject?.selectedQuyTrinh);

      setIsForce(tempObject?.isForce);
      setSelectedBuocList(tempObject?.selectedBuocList);
      setSelectedKetQuaList(tempObject?.selectedKetQuaList);
      setDescription(tempObject?.description);
      setNotes(tempObject?.notes);
      setSelectedNguoiNhanList(tempObject?.selectedNguoiNhanList);
      setSelectedNguoiPhoiHopList(tempObject?.selectedNguoiPhoiHopList);
      setSelectedNguoiLamViecList(tempObject?.selectedNguoiLamViecList);
      setSelectedNguoiGopYList(tempObject?.selectedNguoiGopYList);

      setQuyTrinhList(tempObject?.quyTrinhList);
      setDauViecList(tempObject?.dauViecList);
    }
  }, []);

  // submit
  // funcs
  const _onClickSubmit = () => {
    // CHÚ Ý:
    // - Tạo 2 mảng
    // - Lưu
    if (!props.isTransfer) {
      setIsLoading(true);
    }

    let tempIsForce: string = isForce === true ? "1" : "0";

    if (!taskName) {
      toast.error("Bạn vui lòng nhập tên công việc");

      return {
        status: false,
        data: null,
      };
    }

    if (!selectedDauViec || selectedDauViec.length <= 0) {
      toast.error("Bạn vui lòng chọn đầu mục");

      return {
        status: false,
        data: null,
      };
    }

    if (!selectedNguoiNhan || !selectedNguoiNhan?.Id) {
      toast.error("Bạn vui lòng chọn người nhận");

      return {
        status: false,
        data: null,
      };
    }

    if (!selectedUuTien || !selectedUuTien?.Id) {
      toast.error("Bạn vui lòng chọn độ ưu tiên");

      return {
        status: false,
        data: null,
      };
    }

    if (isAutoSecurity && (!selectedDoMat || !selectedDoMat?.Id)) {
      toast.error("Bạn vui lòng chọn độ mật");

      return {
        status: false,
        data: null,
      };
    }

    if (isAutoDifference && (!selectedDoKho || !selectedDoKho?.Id)) {
      toast.error("Bạn vui lòng chọn độ khó");

      return {
        status: false,
        data: null,
      };
    }

    if (!deadLine) {
      toast.error("Bạn vui lòng chọn thời hạn hoàn thành");

      return {
        status: false,
        data: null,
      };
    }

    let tempStringNguoiPhoiHop: string = "";

    selectedNguoiLamViecList?.forEach((item: any, index: any) => {
      if (index === 0) {
        // do nothing
      } else if (index === selectedNguoiLamViecList?.length - 1) {
        tempStringNguoiPhoiHop += `${item?.Id.toString()}`;
      } else {
        tempStringNguoiPhoiHop += `${item?.Id.toString()},`;
      }
    });

    let tempStringNguoiGopY: string = "";

    selectedNguoiGopYList.forEach((item: any, index: any) => {
      if (index === 0) {
        // do nothing
      } else if (index === selectedNguoiGopYList.length - 1) {
        tempStringNguoiGopY += `${item?.Id.toString()}`;
      } else {
        tempStringNguoiGopY += `${item?.Id.toString()},`;
      }
    });

    let tempHourUseSupporter: string = "";

    const useHourSupporterFilter = useHourSupporter.filter(
      (p) => !isNaN(p.value)
    );

    useHourSupporterFilter.forEach((item: any, index: any) => {
      if (index === useHourSupporter.length - 1) {
        tempHourUseSupporter += `${item?.value.toString()}`;
      } else {
        tempHourUseSupporter += `${item?.value.toString()},`;
      }
    });

    let tempMinutesUseSupporter: string = "";

    const useMinutesSupporterFilter = useMinutesSupporter.filter(
      (p) => !isNaN(p.value)
    );

    useMinutesSupporterFilter.forEach((item: any, index: any) => {
      if (index === useMinutesSupporter.length - 1) {
        tempMinutesUseSupporter += `${item?.value.toString()}`;
      } else {
        tempMinutesUseSupporter += `${item?.value.toString()},`;
      }
    });

    const formData = new FormData();

    let tempArrayDauViec: string = "";

    selectedDauViec.forEach((item: any, index: any) => {
      if (index === selectedDauViec.length - 1) {
        tempArrayDauViec += `${item?.Id.toString()}`;
      } else {
        tempArrayDauViec += `${item?.Id.toString()},`;
      }
    });

    formData.append("TaskListID", tempArrayDauViec);

    formData.append("TaskName", taskName ? taskName : "");
    // formData.append(
    //   "TaskListID",
    //   (selectedDauViec ? selectedDauViec.Id : "").toString()
    // );
    formData.append(
      "PriorityLevelId",
      selectedUuTien ? selectedUuTien?.Id : ""
    );
    formData.append("SecurityLevelID", selectedDoMat ? selectedDoMat.Id : null);

    formData.append("DifficultLevelID", selectedDoKho ? selectedDoKho.Id : "");

    formData.append("RefCode", refCode);

    formData.append(
      "LoaiViecID",
      selectedLoaiViec ? selectedLoaiViec?.ParameterId : ""
    );
    formData.append(
      "NguonViecID",
      selectedNguonVanBan ? selectedNguonVanBan : ""
    );
    formData.append("QuyTrinhID", selectedQuyTrinh ? selectedQuyTrinh?.id : "");
    formData.append(
      "Recipient",
      selectedNguoiNhan ? selectedNguoiNhan?.Id : ""
    );
    formData.append(
      "TaskReviewer",
      selectedNguoiDanhGia ? selectedNguoiDanhGia.Id : ""
    );

    formData.append("Supporter", tempStringNguoiPhoiHop);
    formData.append("IDRole", tempStringNguoiGopY);
    formData.append("IsExChange", (isExChange ?? false).toString());
    formData.append("IsEvaluate", (isEvaluate ?? false).toString());

    formData.append("Description", description ? description : "");
    formData.append("Notes", notes ? notes : "");
    formData.append("DeadLine", deadLine ? deadLine : "");

    let fileString: string = "";

    if (filesList) {
      for (let i = 0; i < filesList.length; i++) {
        formData.append(`Files_TaskFiles`, filesList[i]);

        if (i === filesList.length - 1) {
          fileString += `${filesList[i].name}`;
        } else {
          fileString += `${filesList[i].name};`;
        }
      }
    }

    formData.append("FilesName_TaskFiles", fileString);

    if (selectedBuocList !== null) {
      for (let index = 0; index < selectedBuocList.length; index++) {
        formData.append(
          `TaskContent[${index}].StepName`,
          selectedBuocList[index].StepName
        );
        formData.append(
          `TaskContent[${index}].UserDo`,
          selectedBuocList[index].UserDo
        );
        formData.append(
          `TaskContent[${index}].SortOrder`,
          selectedBuocList[index].SortOrder
        );
        formData.append(
          `TaskContent[${index}].DeadLine_Step`,
          selectedBuocList[index].DeadLine_Step
        );
      }
    }

    if (selectedKetQuaList !== null) {
      for (let index = 0; index < selectedKetQuaList.length; index++) {
        formData.append(
          `TaskResult[${index}].ResultText`,
          selectedKetQuaList[index].tenketqua
        );
      }
    }

    formData.append("IsForce", isForce.toString());

    formData.append("HoursUsed", useHours ? useHours.toString() : "");
    formData.append("HoursUsed_Supporter", tempHourUseSupporter);
    formData.append("MinutesUsed", useMinutes ? useMinutes.toString() : "");
    formData.append("MinutesUsed_Supporter", tempMinutesUseSupporter);

    const takeData = async () => {
      const accessToken = localStorage.getItem("accessToken");

      try {
        await ServiceUtils.postFormDataV2(CREATE_TASK_LIST_NGUOI_GIAO, formData, {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              readList(
                NGUOI_GIAO_VIEC_LIST,
                data.pageCurrent,
                data.choosePage,
                data.searchFilter.taskName,
                data.searchFilter.selectedPrior,
                data.searchFilter.selectedReceiver,
                data.searchFilter.fromDate,
                data.searchFilter.toDate,
                data.searchFilter.deadLine,
                shouldLog3,
                urlLink,
                (res: any) => {
                  dispatch(ONLOAD_TASKLIST_ALL(res.data.Data));
                }
              );

              localStorage.removeItem("tempTaskCreate");

              setIsLoading(false);
              toast.success("Tạo công việc mới thành công.");
              handleClose();
            } else {
              setIsLoading(false);
            }
          });
      } catch (error: any) {
        setIsLoading(false);
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    if (_isTransfer) {
      formData.delete("ID");
      setDataUpdate(formData);

      return {
        status: true,
        data: formData,
      };
    } else {
      takeData();
    }
  };

  const _onClickSubmitNow = () => {
    // CHÚ Ý:
    // - Tạo 2 mảng
    // - Lưu
    setIsLoading(true);
    let tempIsForce: string = isForce === true ? "1" : "0";

    let tempStringNguoiPhoiHop: string = "";

    selectedNguoiLamViecList?.forEach((item: any, index: any) => {
      if (index === 0) {
        // do nothing
      } else if (index === selectedNguoiLamViecList?.length - 1) {
        tempStringNguoiPhoiHop += `${item?.Id.toString()}`;
      } else {
        tempStringNguoiPhoiHop += `${item?.Id.toString()},`;
      }
    });

    let tempStringNguoiGopY: string = "";

    selectedNguoiGopYList.forEach((item: any, index: any) => {
      if (index === 0) {
        // do nothing
      } else if (index === selectedNguoiGopYList.length - 1) {
        tempStringNguoiGopY += `${item?.Id.toString()}`;
      } else {
        tempStringNguoiGopY += `${item?.Id.toString()},`;
      }
    });

    let tempHourUseSupporter: string = "";

    useHourSupporter.forEach((item: any, index: any) => {
      if (index === useHourSupporter.length - 1) {
        tempHourUseSupporter += `${item?.value.toString()}`;
      } else {
        tempHourUseSupporter += `${item?.value.toString()},`;
      }
    });

    let tempMinutesUseSupporter: string = "";

    useMinutesSupporter.forEach((item: any, index: any) => {
      if (index === useMinutesSupporter.length - 1) {
        tempMinutesUseSupporter += `${item?.value.toString()}`;
      } else {
        tempMinutesUseSupporter += `${item?.value.toString()},`;
      }
    });

    const formData = new FormData();

    formData.append("TaskName", taskName ? taskName : "");

    let tempArrayDauViec: string = "";

    selectedDauViec.forEach((item: any, index: any) => {
      if (index === selectedDauViec.length - 1) {
        tempArrayDauViec += `${item?.Id.toString()}`;
      } else {
        tempArrayDauViec += `${item?.Id.toString()},`;
      }
    });

    formData.append("TaskListID", tempArrayDauViec);

    formData.append(
      "PriorityLevelId",
      selectedUuTien ? selectedUuTien?.Id : ""
    );
    formData.append("SecurityLevelID", selectedDoMat ? selectedDoMat.Id : null);

    formData.append("DifficultLevelID", selectedDoKho ? selectedDoKho.Id : "");

    formData.append("RefCode", refCode);

    formData.append(
      "LoaiViecID",
      selectedLoaiViec ? selectedLoaiViec?.ParameterId : ""
    );
    formData.append(
      "TaskReviewer",
      selectedNguoiDanhGia ? selectedNguoiDanhGia.Id : ""
    );
    formData.append(
      "NguonViecID",
      selectedNguonVanBan ? selectedNguonVanBan : ""
    );
    formData.append("QuyTrinhID", selectedQuyTrinh ? selectedQuyTrinh?.id : "");
    if (isMulti) {
      for (let index = 0; index < listMultiUser.length; index++) {
        formData.append(
          `listReptcient_ForMultiCreate[${index}]`,
          listMultiUser[index].Id
        );
      }
    } else {
      formData.append(
        "Recipient",
        selectedNguoiNhan ? selectedNguoiNhan?.Id : ""
      );
      formData.append("Supporter", tempStringNguoiPhoiHop);
      formData.append("IDRole", tempStringNguoiGopY);
    }
    formData.append("Description", description ? description : "");
    formData.append("Notes", notes ? notes : "");
    formData.append("DeadLine", deadLine ? deadLine : "");
    formData.append("IsExChange", isExChange.toString());

    let fileString: string = "";

    if (filesList) {
      for (let i = 0; i < filesList.length; i++) {
        formData.append(`Files_TaskFiles`, filesList[i]);

        if (i === filesList.length - 1) {
          fileString += `${filesList[i].name}`;
        } else {
          fileString += `${filesList[i].name};`;
        }
      }
    }

    formData.append("FilesName_TaskFiles", fileString);

    if (selectedBuocList !== null) {
      for (let index = 0; index < selectedBuocList.length; index++) {
        formData.append(
          `TaskContent[${index}].StepName`,
          selectedBuocList[index].StepName
        );
        formData.append(
          `TaskContent[${index}].UserDo`,
          selectedBuocList[index].UserDo
        );
        formData.append(
          `TaskContent[${index}].SortOrder`,
          selectedBuocList[index].SortOrder
        );
        formData.append(
          `TaskContent[${index}].DeadLine_Step`,
          selectedBuocList[index].DeadLine_Step
        );
      }
    }

    if (selectedKetQuaList !== null) {
      for (let index = 0; index < selectedKetQuaList.length; index++) {
        formData.append(
          `TaskResult[${index}].ResultText`,
          selectedKetQuaList[index].tenketqua
        );
      }
    }

    formData.append("IsForce", isForce.toString());
    formData.append("HoursUsed", useHours.toString());
    formData.append("MinutesUsed", useMinutes.toString());
    formData.append("HoursUsed_Supporter", tempHourUseSupporter);
    formData.append("MinutesUsed_Supporter", tempMinutesUseSupporter);

    const takeData = async () => {
      const accessToken = localStorage.getItem("accessToken");
      const API_CREATE = isMulti
        ? CREATE_TASK_MULTI_LIST_NGUOI_GIAO
        : CREATE_TASK_LIST_NGUOI_GIAO;
      try {
        setIsLoading(true);
        await ServiceUtils.postFormDataV2(API_CREATE, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res: any) => {
            if (isMulti) {
              let arrResult: any[] = [];
              arrResult = res?.data;

              let _count = arrResult?.filter(
                (x: any) => x?.Data?.RCode !== 200
              ).length;

              if (_count > 0) {
                arrResult.map((x: any) => {
                  if (x?.Data?.RCode !== 200) {
                    setIsLoading(false);
                    handleClose();
                    toast.error(
                      "Giao việc cho " +
                      userListForSelect?.find(
                        (i: any) => i?.Id?.trim() == x?.Data?.RId?.trim()
                      )?.FullName +
                      " thất bại!"
                    );
                  }
                });
              } else {
                setIsLoading(false);
                handleClose();
                toast.success("Giao việc thành công!");
              }
            }

            if (res.data.StatusCode === 200) {
              localStorage.removeItem("tempTaskCreate");
              if (!isMulti) {
                const approve = async () => {
                  const accessToken = localStorage.getItem("accessToken");
                  const API = APPROVE_TASK + res.data.Data.RId;

                  try {
                    await ServiceUtils.getV2(API, {
                      headers: {
                        Accept: "text/plain",
                      },
                    })
                      .then((res: any) => {
                        if (res.data.StatusCode === 200) {
                          toast.success("Tạo công việc và giao thành công.");
                          setIsLoading(false);
                          localStorage.removeItem("tempTaskCreate");
                          handleClose();
                        }
                      });
                  } catch (error: any) {
                    if (handleClose) {
                      setIsLoading(false);
                      handleClose();
                    }
                    toast.error(
                      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
                    );
                  }
                };
                approve();
              }
            } else {
              setIsLoading(false);
            }
          });
      } catch (error: any) {
        setIsLoading(false);
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    takeData();
  };
  console.log("time ", useHours, " ", useMinutes);
  // load selecting data
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (shouldLog.current) {
      shouldLog.current = false;

      if (isAutoForce === null) {
        const accessToken = localStorage.getItem("accessToken");

        const takeData = async () => {
          try {
            await ServiceUtils.getV2(CONFIG_IS_FORCE, {
              headers: {
                Accept: "text/plain",
                Authorization: "Bearer " + accessToken,
              },
            })
              .then((res: any) => {
                if (res.status === 200) {
                  if (res?.data?.Data[0]?.AllowTaskReject === false) {
                    setIsForce(true);
                  }
                  if (res?.data?.Data[0]?.AllowUseSecurity === false) {
                    setSelectedDoMat(null);
                  }
                  if (res?.data?.Data[0]?.AllowUseDifference === false) {
                    setSelectedDoKho(null);
                  }

                  setIsAutoForce(res?.data?.Data[0]?.AllowTaskReject);
                  setIsAutoRequiredUseHours(
                    res?.data?.Data[0]?.IsRequiredUseHours
                  );
                  setIsAutoRequiredUseMinutes(
                    res?.data?.Data[0]?.IsRequiredUseMinutes
                  );
                  setIsAutoSecurity(res?.data?.Data[0]?.AllowUseSecurity);
                  setIsAutoPriority(res?.data?.Data[0]?.AllowUsePriority);
                  setIsAutoDifference(res?.data?.Data[0]?.AllowUseDifference);
                  setIsAutoOrigin(res?.data?.Data[0]?.AllowUseOrigin);
                  setIsAutoLoaiViec(res?.data?.Data[0]?.AllowUseLoaiViec);
                  setPriorityDefault(res?.data?.Data[0]?.PriorityDefault);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (suggestList === null) {
        const fetchData = async () => {
          try {
            await ServiceUtils.getV2(LOAD_SUGGEST_LIST, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
              .then((res: any) => {
                if (res.data.StatusCode === 200) {
                  setSuggestList(res.data.Data);
                } else {
                  toast.error(res.data.Message);
                }
              });
          } catch (error: any) {
            toast.error(
              "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
            );
          }
        };

        fetchData();
      }

      if (phongBanList === null || userList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(LOAD_PHONG_BAN_BY_USER, {
              headers: {
                Accept: "text/plain",
                Authorization: "Bearer " + accessToken,
              },
            })
              .then((res: any) => {
                if (res.status === 200) {
                  setPhongBanList(res.data.Data.PhongBan);
                  setPhongBanListForSelect(
                    _onConvertToSelect(
                      res?.data?.Data?.PhongBan,
                      "TenPhongBan",
                      "IdPhongBan",
                      "ColorText"
                    )
                  );
                  setUserList(res.data.Data.NguoiNhan);
                  setUserListForSelect(
                    _onConvertToSelect(
                      res?.data?.Data?.NguoiNhan.filter(
                        (x: any) => x.Id !== userData?.Data[0]?.Id
                      ),
                      "FullName",
                      "Id"
                    )
                  );
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (phongBanDanhGiaList === null || userDanhGiaList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(LOAD_PHONG_BAN, {
              headers: {
                Accept: "text/plain",
                Authorization: "Bearer " + accessToken,
              },
            })
              .then((res: any) => {
                if (res.status === 200) {
                  setPhongBanDanhGiaList(res.data.Data.PhongBan);
                  setUserDanhGiaList(res.data.Data.NguoiNhan);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (loaiViecList === null) {
        const API = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_LOAIVIEC";

        const takeData = async () => {
          try {
            await ServiceUtils.getV2(API, {
              headers: {
                Accept: "text/plain",
                Authorization: "Bearer " + accessToken,
              },
            })
              .then((res: any) => {
                if (res.status === 200) {
                  setLoaiViecList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (nguonVanBanList === null) {
        const API = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_NGUONVANBAN";

        const takeData = async () => {
          try {
            await ServiceUtils.getV2(API, {
              headers: {
                Accept: "text/plain",
                Authorization: "Bearer " + accessToken,
              },
            })
              .then((res: any) => {
                if (res.status === 200) {
                  setNguonVanBanList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (uuTienList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(PRIOR_LIST_DROPDOWN, {
              headers: {
                Accept: "text/plain",
                Authorization: "Bearer " + accessToken,
              },
            })
              .then((res: any) => {
                if (res.status === 200) {
                  setUuTienList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (doMatList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(SECURITY_READ_ALL_DROPDOWN, {
              headers: {
                Accept: "text/plain",
                Authorization: "Bearer " + accessToken,
              },
            })
              .then((res: any) => {
                if (res.status === 200) {
                  setDoMatList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (dauViecList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(READ_TASK_LIST_BY_USER, {
              headers: {
                Accept: "text/plain",
                Authorization: "Bearer " + accessToken,
              },
            })
              .then((res: any) => {
                if (res.status === 200) {
                  setDauViecList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (quyTrinhList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(READ_ALL_LIST_QUYTRINH_DROPDOWN, {
              headers: {
                Accept: "text/plain",
                Authorization: "Bearer " + accessToken,
              },
            })
              .then((res: any) => {
                if (res.status === 200) {
                  setQuyTrinhList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (doKhoList === null) {
        const takeData = async () => {
          try {
            await ServiceUtils.getV2(DIFICULT_LEVEL_DROPDOWN, {
              headers: {
                Accept: "text/plain",
                Authorization: "Bearer " + accessToken,
              },
            })
              .then((res: any) => {
                if (res.status === 200) {
                  setDoKhoList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }
    }
  });

  const _onCheckValid = (): boolean => {
    if (
      selectedBuocList === null ||
      selectedKetQuaList === null ||
      selectedQuyTrinh === -1
    ) {
      return true;
    } else {
      for (let i = 0; i < selectedBuocList.length; i++) {
        if (
          selectedBuocList[i].UserDo === "" ||
          selectedBuocList[i].DeadLine_Step === ""
        ) {
          return false;
        }
      }
      return true;
    }
  };

  useEffect(() => {
    if (selectedBuocList === null || selectedBuocList === undefined) {
      setIsSelectedBuocOK(false);
    } else {
      for (let i = 0; i < selectedBuocList.length; i++) {
        if (
          selectedBuocList[i].UserDo === "" ||
          selectedBuocList[i].DeadLine_Step === ""
        ) {
          setIsSelectedBuocOK(false);
          break;
        } else {
          setIsSelectedBuocOK(true);
        }
      }
    }
  }, [selectedBuocList]);

  // Preprocessing
  const tempQuyTrinhList = quyTrinhList?.map((item) => {
    return { ...item, value: item?.tenquytrinh, label: item?.tenquytrinh };
  });

  const tempDauViecList = dauViecList?.map((item) => {
    return { ...item, value: item?.Tendauviec, label: item?.Tendauviec };
  });

  const convertToPercent = (total: any, param: any) => {
    let value = 0;
    if (param && total) {
      value = (param / total) * 100;
    }
    return value.toFixed(2);
  };

  const _onChangeQuytrinhList = (data: any) => {
    if (data === null) {
      setSelectedQuyTrinh(-1);
      setSelectedBuocList(null);
      setSelectedKetQuaList(null);
    } else {
      const accessToken = localStorage.getItem("accessToken");

      setSelectedQuyTrinh(
        quyTrinhList?.find((item: any) => item?.id === parseInt(data.id))
      );

      const takeStep = async () => {
        try {
          await ServiceUtils.getV2(DETAIL_STEP_DROPDOWN + data.id, {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain",
            },
          })
            .then((res: any) => {
              let tempArray: any[] = [];

              res.data.Data.forEach((item: any, index: any) => {
                const body = {
                  StepName: item?.tenbuoc,
                  UserDo: "",
                  DeadLine_Step: "",
                  SortOrder: item?.orderBy,
                };
                tempArray.push(body);
              });

              setSelectedBuocList(tempArray);
            });
        } catch (error: any) { }
      };

      const takeResult = async () => {
        try {
          await ServiceUtils.getV2(DETAIL_RESULT_DROPDOWN + data.id, {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain",
            },
          })
            .then((res: any) => {
              setSelectedKetQuaList(res.data.Data);
            });
        } catch (error: any) { }
      };

      takeStep();
      takeResult();
    }
  };

  const _onChangeDauViecList = (_data: any) => {
    if (_data === null) {
      setSelectedDauViec(-1);
    } else {
      setSelectedDauViec(
        dauViecList?.find((item: any) => item?.Id === _data?.Id)
      );
    }
  };

  const getUnique = (arr: any[], index: any) => {
    if (arr.length == 0) {
      return [];
    }
    const unique = arr
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e: any) => arr[e])
      .map((e: any) => arr[e]);

    return unique;
  };

  const _onChangeListMultiUser = (type: number, e: any) => {
    const _listMultiUser = [...listMultiUser];
    if (type === 1) {
      userListForSelect?.map((item: any) => {
        if (
          item &&
          e.value &&
          (item?.IdPhongBan || "")
            .toString()
            .split(",")
            .find((p: any) => p == e.value)
        ) {
          if (item?.Id !== userData?.Data[0]?.Id) {
            _listMultiUser.push(item);
          }
        }
      });

      setListMultiUser(getUnique(_listMultiUser, "value"));
    } else if (type === 2 || type === 3) {
      if (e?.Id !== userData?.Data[0]?.Id) {
        _listMultiUser.push(e);
      }
      setListMultiUser(getUnique(_listMultiUser, "value"));
    } else if (type === 4) {
      setListMultiUser(e);
    } else if (type === 5) {
      // setListMultiUser(e)
      setIsAllUser((item: any) => !item);
    }
  };

  useEffect(() => {
    let _listMultiUser: any[] = [];
    _listMultiUser = [...listMultiUser];
    if (isAllUser) {
      userListForSelect?.map((item: any) => {
        if (item?.Id !== userData?.Data[0]?.Id) {
          _listMultiUser.push(item);
        }
      });
    } else {
      _listMultiUser = [];
    }
    setListMultiUser(getUnique(_listMultiUser, "value"));
  }, [isAllUser]);

  // funcs
  const _onChangeWorkerViaDepart = (data: any) => {
    if (data === null) {
      setSelectedNguoiPhoiHopList(
        userList?.filter(
          (item: any) => item?.Id !== selectedNguoiLamViecList[0]?.Id
        )
      );
    } else {
      setSelectedNguoiPhoiHopList(
        userList?.filter(
          (item: any) =>
            (item?.IdPhongBan || "")
              .toString()
              .split(",")
              .find((p: any) => p == data.ParameterId) &&
            item?.Id !== selectedNguoiLamViecList[0].Id
        )
      );
    }
  };

  // reset filter
  const resetFilter = () => {
    setSelectedNguoiPhoiHopList(
      userList?.filter(
        (item: any) => item?.Id !== selectedNguoiLamViecList[0]?.Id
      )
    );
  };

  const tempSuggestList = useMemo(() => {
    return suggestList?.filter((item) => item.NameSuggest.includes(suggest));
  }, [suggest]);

  const finalDauViec = useMemo(() => {
    return {
      ...selectedDauViec,
      value: selectedDauViec?.Tendauviec,
      label: selectedDauViec?.Tendauviec,
    };
  }, [selectedDauViec]);

  const finalQuyTrinh = useMemo(() => {
    return {
      ...selectedQuyTrinh,
      value: selectedQuyTrinh?.tenquytrinh,
      label: selectedQuyTrinh?.tenquytrinh,
    };
  }, [selectedQuyTrinh]);

  const isOkWithHourSupporter = useMemo(() => {
    if (selectedNguoiLamViecList?.length === 0) {
      return true;
    } else if (selectedNguoiLamViecList?.length !== 0) {
      for (let i = 0; i < useHourSupporter?.length; i++) {
        if (
          useHourSupporter[i].value === 0 ||
          useHourSupporter[i].value === "0" ||
          isNaN(useHourSupporter[i].value) === true
        ) {
          return false;
        }
      }
    }
  }, [selectedNguoiLamViecList, useHourSupporter]);

  // Load detail
  useEffect(() => {
    if (
      phongBanList === null ||
      userList === null ||
      loaiViecList === null ||
      nguonVanBanList === null ||
      uuTienList === null ||
      doMatList === null ||
      dauViecList === null ||
      quyTrinhList === null ||
      doKhoList === null
    ) {
      // do nothing
    } else {
      const accessToken = localStorage.getItem("accessToken");
      const API = NGUOI_GIAO_VIEC_DETAIL(taskId);

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(API, {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain",
            },
          })
            .then((res: any) => {
              if (res.status === 200) {
                // main thing
                let suppTaskListID: any = "";

                if (res?.data?.Data?.task?.TaskListID === null) {
                  suppTaskListID = ",";
                } else {
                  suppTaskListID = res?.data?.Data?.task?.TaskListID.replace(
                    "{",
                    ""
                  )
                    .replaceAll("}{", ",")
                    .replace("}", "");
                }

                const defaultDV = suppTaskListID.split(",");

                let tempArrayDV: any[] = [];

                defaultDV.forEach((item: any, index: number) => {
                  const tempValue = dauViecList.find(
                    (_item: any) => _item?.Id === parseInt(item)
                  );
                  tempValue ? tempArrayDV.push(tempValue) : (tempArrayDV = []);
                });

                const arraySomething = tempArrayDV.map((item: any) => {
                  return {
                    ...item,
                    value: item.Tendauviec,
                    label: item.Tendauviec,
                  };
                });

                setSelectedDauViec(arraySomething);
                // other things
                // setSelectedDauViec(
                //   dauViecList?.find(
                //     (item: any) =>
                //       item?.Id ===
                //       parseInt(
                //         res?.data?.Data?.task?.TaskListID.replace("{", "").replace(
                //           "}",
                //           ""
                //         )
                //       )
                //   )
                // );

                setUseHours(res?.data?.Data?.task?.HoursUsed);
                setUseMinutes(res?.data?.Data?.task?.MinutesUsed);

                setTaskStatus(res?.data?.Data?.task?.TaskStatus);

                setDeadLine(res?.data?.Data?.task?.DeadLine);
                setIsEvaluate(res?.data?.Data?.task?.isEvaluate);

                setNotes(res?.data?.Data?.task?.Notes);
                setDescription(res?.data?.Data?.task?.Description);
                setIsForce(res?.data?.Data?.task?.IsForce);
                setRefCode(res?.data?.Data?.task?.RefCode);
                setSelectedLoaiViec(
                  loaiViecList?.find(
                    (item: any) =>
                      item?.ParameterId === res?.data?.Data?.task?.LoaiViecID
                  )
                );
                setSelectedNguonVanBan(res?.data?.Data?.task?.NguonViecID);
                setSelectedQuyTrinh(
                  quyTrinhList?.find(
                    (item: any) =>
                      item?.id === res?.data?.Data?.task?.QuyTrinhID
                  )
                );

                // other things
                setTaskName(res?.data?.Data?.task?.TaskName);

                setSelectedDoMat(
                  doMatList?.find(
                    (item: any) =>
                      item?.Id ===
                      res?.data?.Data?.task?.SecurityLevelID?.toString()
                  )
                );
                setSelectedUuTien(
                  uuTienList?.find(
                    (item: any) =>
                      item?.Id === res?.data?.Data?.task?.PriorityLevelID
                  )
                );

                setSelectedPhongBan(
                  phongBanList?.find((item: any) =>
                    (item?.IdPhongBan || "")
                      .toString()
                      .split(",")
                      .find(
                        (p: any) =>
                          p == parseInt(res?.data?.Data?.task?.IdPhongBan)
                      )
                      ? true
                      : false
                  )
                );

                const users = userList.filter((p: any) =>
                  (p.IdPhongBan || "")
                    .split(",")
                    .find((m: any) => m == res.data.Data.task.IdPhongBan)
                    ? true
                    : false
                );

                setSelectedNguoiNhanList(users);
                setSelectedNguoiNhan(
                  userList?.find(
                    (item: any) => item?.Id === res?.data?.Data?.task?.Recipient
                  )
                );
                setSelectedNguoiPhoiHopList(
                  userList?.filter(
                    (item: any) => item?.Id !== res?.data?.Data?.task?.Recipient
                  )
                );
                setSelectedDoKho(
                  doKhoList?.find(
                    (item: any) =>
                      item?.Id ===
                      parseInt(res?.data?.Data?.task?.DifficultLevelID)
                  )
                );

                let supp: any = "";

                if (res?.data?.Data?.task?.Supporter === null) {
                  supp = ",";
                } else {
                  supp = res?.data?.Data?.task?.Supporter.replace("{", "")
                    .replaceAll("}{", ",")
                    .replace("}", "");
                }

                const suppArray = supp.split(",");

                let tempArray: any[] = [];

                tempArray.push(
                  userList?.find(
                    (item: any) => item?.Id === res?.data?.Data?.task?.Recipient
                  )
                );

                userList.forEach((item: any, index: any) => {
                  const isExist = suppArray.includes(item?.Id);

                  if (isExist === true) {
                    tempArray.push(item);
                  } else {
                    // do nothing
                  }
                });

                setSelectedNguoiLamViecList(tempArray);

                // -- IDRole
                let suppIDRole: any = "";

                if (res?.data?.Data?.task?.IDRole === null) {
                  suppIDRole = ",";
                } else {
                  suppIDRole = res?.data?.Data?.task?.IDRole.replace("{", "")
                    .replaceAll("}{", ",")
                    .replace("}", "");
                }

                const suppArrayIDRole = suppIDRole.split(",");

                let tempArrayIDRole: any[] = [];

                tempArrayIDRole.push(
                  userList?.find(
                    (item: any) => item?.Id === res?.data?.Data?.task?.Recipient
                  )
                );

                userList.forEach((item: any, index: any) => {
                  const isExist = suppArrayIDRole.includes(item?.Id);

                  if (isExist === true) {
                    tempArrayIDRole.push(item);
                  } else {
                    // do nothing
                  }
                });

                setSelectedNguoiGopYList(tempArrayIDRole);
                // -- End IDRole

                // -- HourSupporter
                let suppHourRole: any = "";

                if (res?.data?.Data?.task?.HoursUsed_Supporter === null) {
                  suppHourRole = ",";
                } else {
                  suppHourRole =
                    res?.data?.Data?.task?.HoursUsed_Supporter.replace("{", "")
                      .replaceAll("}{", ",")
                      .replace("}", "");
                }

                const suppHourArrayIDRole = suppHourRole.split(",");

                let tempArrayOfHourRole: any[] = [];

                suppHourArrayIDRole.forEach((item: any, index: number) => {
                  tempArrayOfHourRole.push({
                    id: suppArray[index],
                    value: parseInt(item),
                  });
                });

                setUseHourSupporter(tempArrayOfHourRole);
                // // -- End HourSupporter

                // --MinutesSupporter
                let suppMinutesRole: any = "";

                if (res?.data?.Data?.task?.MinutesUsed_Supporter === null) {
                  suppMinutesRole = ",";
                } else {
                  suppMinutesRole =
                    res?.data?.Data?.task?.MinutesUsed_Supporter.replace(
                      "{",
                      ""
                    )
                      .replaceAll("}{", ",")
                      .replace("}", "");
                }

                const suppMinutesArrayIDRole = suppMinutesRole.split(",");

                let tempArrayOfMinutesRole: any[] = [];

                suppMinutesArrayIDRole.forEach((item: any, index: number) => {
                  tempArrayOfMinutesRole.push({
                    id: suppArray[index],
                    value: parseInt(item),
                  });
                });

                setUseMinutesSupporter(tempArrayOfMinutesRole);
                // // -- End MinutesSupporter

                // // -- IDRole
                // let suppIDRole: any = "";

                // if (res?.data?.Data?.task?.IDRole === null) {
                //   suppIDRole = ",";
                // } else {
                //   suppIDRole = res?.data?.Data?.task?.IDRole
                //     .replace("{", "")
                //     .replaceAll("}{", ",")
                //     .replace("}", "");
                // }

                // const suppArrayIDRole = suppIDRole.split(",");

                // let tempArrayIDRole: any[] = [];

                // tempArrayIDRole.push(
                //   userList?.find(
                //     (item: any) => item?.Id === res?.data?.Data?.task?.Recipient
                //   )
                // );

                // userList.forEach((item: any, index: any) => {
                //   const isExist = suppArrayIDRole.includes(item?.Id);

                //   if (isExist === true) {
                //     tempArrayIDRole.push(item);
                //   } else {
                //     // do nothing
                //   }
                // });

                // setSelectedNguoiGopYList(tempArrayIDRole);
                // // -- End IDRole

                // // -- HourSupporter
                // let suppHourRole: any = "";

                // if (res?.data?.Data?.task?.HoursUsed_Supporter === null) {
                //   suppHourRole = ",";
                // } else {
                //   suppHourRole = res?.data?.Data?.task?.HoursUsed_Supporter
                //     .replace("{", "")
                //     .replaceAll("}{", ",")
                //     .replace("}", "");
                // }

                // const suppHourArrayIDRole = suppHourRole.split(",");

                // let tempArrayOfHourRole: any[] = [];

                // console.log(suppHourArrayIDRole)

                // suppHourArrayIDRole.forEach((item: any, index: number) => {
                //   tempArrayOfHourRole.push({
                //     id: suppArray[index],
                //     value: parseInt(item)
                //   })
                // })

                // // console.log(tempArrayOfHourRole)
                // setUseHourSupporter(tempArrayOfHourRole)

                // // -- End HourSupporter

                // console.log(suppHourArrayIDRole)
                let tempArrayBuoc: any[] = [];
                let tempArrayKetqua: any[] = [];

                res?.data?.Data?.taskContents.forEach((item: any) => {
                  const body = {
                    ID: item.ID,
                    StepName: item.StepName,
                    UserDo: item.UserDo,
                    DeadLine_Step: item.DeadLine,
                    SortOrder: item.SortOrder,
                  };

                  tempArrayBuoc.push(body);
                });

                res?.data?.Data?.taskResults.forEach((item: any) => {
                  const body = {
                    id: item.ID,
                    tenketqua: item.ResultText,
                  };

                  tempArrayKetqua.push(body);
                });

                setSelectedBuocList(tempArrayBuoc);
                setSelectedKetQuaList(tempArrayKetqua);

                const fileListResponse =
                  res?.data?.Data?.task?.ListFiles === null
                    ? []
                    : res?.data?.Data?.task?.ListFiles?.split(";");
                const fileListNameResponse =
                  res?.data?.Data?.task?.ListFilesName === null
                    ? []
                    : res?.data?.Data?.task?.ListFilesName?.split(";");

                let tempFileArray: any[] = [];

                fileListNameResponse.forEach((item: any, index: any) => {
                  let tempObject = {
                    listFile: fileListResponse[index],
                    listFileName: item,
                  };

                  tempFileArray.push(tempObject);
                });

                setFileListAPI(tempFileArray);
                setTaskCode(res?.data?.Data?.task?.TaskCode);

                setOrganizationId(res?.data?.Data?.task?.IdPhongBan);
              }
            });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  }, [
    phongBanList,
    userList,
    loaiViecList,
    nguonVanBanList,
    uuTienList,
    doMatList,
    dauViecList,
    quyTrinhList,
    taskId,
  ]);

  // console.log("Deadline", deadLine)
  // console.log("finalDeadline", finalDeadLine)

  const onOpenRecoveryTask = () => {
    const isCanUpdate: any = _onClickSubmit();
    if (taskStatus != 9) {
      if (isCanUpdate.status) {
        handleOpenSub();
        setFuncsSub(MODAL_KEYS.recoveryTask);
      }
    } else {
      _transferTask();
    }
  };

  const _transferTask = () => {

    dataUpdate.append("IDClone", taskId);

    dataUpdate.append("TaskCode", taskCode);

    TaskRepository.transferTask(dataUpdate).then((result2: any) => {
      if (result2.status) {
        toast.success("Chuyển giao thành công!");

        // getList();

        // setIsLoading(false);

        handleClose();

        // context.handleClose();

        // setReason("");

        // if (props.onHandleRecoveryTask) {
        //   props.onHandleRecoveryTask();
        // }
      } else {
        // toast.error(result2.message || messageFailure);

        // setIsLoading(false);
        console.log('flase');

      }
    });
  };

  const onHandleRecoveryTask = (result: any) => {
    const API_reload =
      window.location.pathname === "/working/new-task"
        ? NGUOI_GIAO_VIEC_LIST
        : NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN;

    if (result.status) {
      readList(
        API_reload,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog3,
        urlLink,
        (res: any) => {
          dispatch(ONLOAD_TASKLIST_ALL(res.data.Data));
        }
      );
    } else {
    }
  };

  const onRecall = (): void => { };

  const onTransfer = (): void => { };

  // JSX Template
  const isRecall = (): boolean => {
    let check = false;

    return check;
  };

  const renderMenuRecall = (): any => {
    let ui = (
      <>
        {/* detail */}
        <button
          className="flex w-full h-full hover:text-sky-500"
          onClick={onRecall}
        >
          {/* detail - contain */}
          <div className="flex w-full h-full space-x-1 p-2">
            {/* icon */}
            <ReplayIcon
              className="text-sky-600"
              sx={{ fontSize: 18 }}
              fontSize="small"
            />
            {/* title */}
            <div>Thu hồi</div>
          </div>
        </button>
      </>
    );

    return ui;
  };

  const _onChangeDauViecList2 = (_data: any) => {
    setSelectedDauViec(_data);
    // console.log(_data)
  };

  const DeadLineJSX = () => {
    return (
      <DateTimePickerControl
        className={"width-100-percent padding-right-0"}
        customLabelHTML={
          '<p>Thời hạn hoàn thành <span class="text-red-500">(*)</span></p>'
        }
        value={deadLine}
        onChange={(e: any) => {
          const value = e;
          _onChangeDeadLine2(value);
        }}
      />
    );
  };

  const DauMucViecJSX = () => {
    return (
      <div className="w-full h-max">
        <label>
          Đầu mục&nbsp;<span className="text-red-500">(*)</span>
        </label>

        <Select
          className="z-[5]"
          options={tempDauViecList}
          value={selectedDauViec}
          onChange={(data: any) => _onChangeDauViecList2(data)}
          isClearable
          isMulti
          placeholder="--- Chọn đầu mục ---"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          defaultValue={tempDauViecList?.find(
            (item) => item?.Id === selectedDauViec?.Id
          )}
        />
      </div>
    );
  };

  const QuytrinhJSX = () => {
    return (
      <>
        <div className="w-full h-max justify-start items-center flex space-x-3">
          <label>Quy trình&nbsp;(nếu có)</label>
          <div
            className={`
                    ${menu?.PerInsert === true ? "flex" : "hidden"}
                    w-max h-max
                  `}
          >
            {/* <button className="text-xs pl-1.5 pr-1.5 pt-0.5 pb-0.5 bg-primary
                      text-white rounded-md hover:bg-sky-400
                    "
                      onClick={() => {
                        setFuncsSub("createWorkflowSub")
                        handleOpenSub()
                      }}
                    > 
                      <p>Tạo quy trình</p>
                    </button> */}
          </div>
        </div>
        <Select
          className="z-10"
          options={tempQuyTrinhList}
          onChange={(data: any) => _onChangeQuytrinhList(data)}
          isClearable
          placeholder="--- Chọn quy trình ---"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          defaultValue={tempQuyTrinhList?.find(
            (item) => item?.id === selectedQuyTrinh?.id
          )}
        />
      </>
    );
  };

  const StepListJSX = () => {
    return (
      <>
        {/* Web */}
        <div
          className={`w-2/3 pr-3 tableFixHead
          ${selectedBuocList === null || selectedBuocList === undefined
              ? "lg:flex hidden"
              : "lg:flex hidden"
            }
        `}
        >
          <table className="w-full">
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border border-slate-300">Bước</th>

                <th className="border border-slate-300">Nội dung</th>
                <th className="border border-slate-300">Người thực hiện</th>
                <th className="border border-slate-300">Thời hạn</th>
                <th className="border border-slate-300 w-[1rem]">
                  <button
                    className="
                      flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                      rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                    "
                    onClick={() => _onClickEditBuoc("editBuoc")}
                  >
                    +
                  </button>
                </th>
              </tr>
            </thead>

            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              {selectedBuocList === null || selectedBuocList === undefined
                ? ""
                : selectedBuocList
                  .sort((a: any, b: any) => {
                    return a.SortOrder - b.SortOrder;
                  })
                  .map((item: any, index: any) => (
                    <tr key={index} className="w-max h-[3rem] bg-white">
                      <td className="text-center border border-slate-300">
                        <p>{item?.SortOrder}</p>
                      </td>
                      <td className="border border-slate-300">
                        <p>{item?.StepName}</p>
                      </td>
                      <td className="text-center border border-slate-300">
                        <select
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          value={item?.UserDo}
                          onChange={(e: any) => {
                            e.preventDefault();
                            _onChangeNguoiPhoiHopEachStep(
                              item?.SortOrder,
                              e.target.value
                            );
                          }}
                        >
                          <option
                            className="text-gray-300"
                            value={""}
                            disabled
                          >
                            --- Chọn người phối hợp ---
                          </option>
                          {selectedNguoiLamViecList === null
                            ? ""
                            : selectedNguoiLamViecList?.map(
                              (item: any, index: any) => (
                                <option key={index} value={item?.Id}>
                                  {item?.FullName}
                                </option>
                              )
                            )}
                        </select>
                      </td>
                      <td className="text-center border border-slate-300">
                        <DateTimePickerControl
                          className={"width-100-percent padding-right-0"}
                          value={item?.DeadLine_Step}
                          onChange={(e: any) => {
                            _onChangDeadLineStep(item?.SortOrder, e);
                          }}
                        />
                      </td>
                      <td className="border border-slate-300">
                        <button
                          className="text-red-500 w-1/6"
                          onClick={() => _onDeleteQuyTrinh(item?.SortOrder)}
                        >
                          <DeleteTwoToneIcon fontSize="small" />
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>

        {/* Phone */}
        <div
          className={`w-full h-max tableFixHead ${selectedBuocList === null || selectedBuocList === undefined
            ? "flex lg:hidden"
            : "flex lg:hidden"
            }`}
        >
          <table className="w-full">
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border border-slate-300">Nội dung</th>
                <th className="border border-slate-300 w-[1rem]">
                  <button
                    className="
                              flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                              rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                            "
                    onClick={() => _onClickEditBuoc("editBuoc")}
                  >
                    +
                  </button>
                </th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              {selectedBuocList === null || selectedBuocList === undefined
                ? ""
                : selectedBuocList.map((item: any, index: any) => (
                  <tr key={index} className="w-max h-[3rem] bg-white">
                    <td className="flex flex-col border border-slate-300 space-y-2">
                      <p className="font-bold">
                        Bước&nbsp;{item?.SortOrder}:
                      </p>
                      <p>{item?.StepName}</p>
                      <select
                        className="lg:w-full sm:w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={item?.UserDo}
                        onChange={(e: any) => {
                          e.preventDefault();
                          _onChangeNguoiPhoiHopEachStep(
                            item?.SortOrder,
                            e.target.value
                          );
                        }}
                      >
                        <option className="text-gray-300" value={""} disabled>
                          --- Chọn người thực hiện ---
                        </option>
                        {selectedNguoiLamViecList === null
                          ? ""
                          : selectedNguoiLamViecList?.map(
                            (item: any, index: any) => (
                              <option key={index} value={item?.Id}>
                                {item?.FullName}
                              </option>
                            )
                          )}
                      </select>
                      <DateTimePickerControl
                        className={"width-100-percent padding-right-0"}
                        value={item?.DeadLine_Step}
                        onChange={(e: any) => {
                          _onChangDeadLineStep(item?.SortOrder, e);
                        }}
                      />
                    </td>
                    <td className="border border-slate-300">
                      <button
                        className="text-red-500 w-1/6"
                        onClick={() => _onDeleteQuyTrinh(item?.SortOrder)}
                      >
                        <DeleteTwoToneIcon fontSize="small" />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const _onClickSwitchToBoss = () => {
    setFuncsSub("switchBoss");
    handleOpenSub();
  };

  console.log("useHourSupporter ", useHourSupporter);
  console.log("useMinutesSupporter ", useMinutesSupporter);

  const _onClickUpdate = async (type?: boolean): Promise<any> => {
    // CHÚ Ý:
    // - Tạo 2 mảng
    // - Lưu
    if (type === undefined) {
      type = false;
    }

    let tempIsForce: string = isForce === true ? "1" : "0";

    if (!taskName) {
      toast.error("Bạn vui lòng nhập tên công việc");

      return false;
    }

    if (!selectedDauViec || selectedDauViec.length <= 0) {
      toast.error("Bạn vui lòng chọn đầu mục");

      return false;
    }

    if (!selectedNguoiNhan || !selectedNguoiNhan?.Id) {
      toast.error("Bạn vui lòng chọn người nhận");

      return false;
    }

    if (isAutoPriority && (!selectedUuTien || !selectedUuTien?.Id)) {
      toast.error("Bạn vui lòng chọn độ ưu tiên");

      return false;
    }

    if (isAutoSecurity && (!selectedDoMat || !selectedDoMat?.Id)) {
      toast.error("Bạn vui lòng chọn độ mật");

      return false;
    }

    if (isAutoDifference && (!selectedDoKho || !selectedDoKho?.Id)) {
      toast.error("Bạn vui lòng chọn độ khó");

      return false;
    }

    if (!deadLine) {
      toast.error("Bạn vui lòng chọn thời hạn hoàn thành");

      return false;
    }

    let tempStringNguoiPhoiHop: string = "";

    selectedNguoiLamViecList?.forEach((item: any, index: any) => {
      if (index === 0) {
        // do nothing
      } else if (index === selectedNguoiLamViecList?.length - 1) {
        tempStringNguoiPhoiHop += `${item?.Id.toString()}`;
      } else {
        tempStringNguoiPhoiHop += `${item?.Id.toString()},`;
      }
    });

    const formData = new FormData();

    formData.append("ID", taskId);
    formData.append("TaskName", taskName ? taskName : "");

    let tempArrayDauViec: string = "";

    selectedDauViec.forEach((item: any, index: any) => {
      if (index === selectedDauViec.length - 1) {
        tempArrayDauViec += `${item?.Id.toString()}`;
      } else {
        tempArrayDauViec += `${item?.Id.toString()},`;
      }
    });

    formData.append(
      "TaskListID",
      tempArrayDauViec
      // (selectedDauViec ? selectedDauViec.Id : "").toString()
    );

    formData.append(
      "PriorityLevelId",
      selectedUuTien != -1 && selectedUuTien
        ? selectedUuTien?.Id
        : priorityDefault
          ? priorityDefault
          : ""
    );
    formData.append("SecurityLevelID", selectedDoMat ? selectedDoMat.Id : "");

    formData.append("DifficultLevelID", selectedDoKho ? selectedDoKho.Id : "");

    formData.append("RefCode", "1");

    formData.append(
      "LoaiViecID",
      selectedLoaiViec ? selectedLoaiViec?.ParameterId : ""
    );
    formData.append(
      "NguonViecID",
      selectedNguonVanBan ? selectedNguonVanBan : ""
    );
    formData.append("QuyTrinhID", selectedQuyTrinh ? selectedQuyTrinh?.id : "");
    formData.append(
      "Recipient",
      selectedNguoiNhan ? selectedNguoiNhan?.Id : ""
    );

    formData.append("Supporter", tempStringNguoiPhoiHop);
    formData.append("Description", description ? description : "");
    formData.append("Notes", notes ? notes : "");
    formData.append("DeadLine", deadLine ? deadLine : "");

    let tempHourUseSupporter: string = "";

    useHourSupporter.forEach((item: any, index: any) => {
      if (index === useHourSupporter.length - 1) {
        tempHourUseSupporter += `${item?.value.toString()}`;
      } else {
        tempHourUseSupporter += `${item?.value.toString()},`;
      }
    });

    let tempMinutesUseSupporter: string = "";

    useMinutesSupporter.forEach((item: any, index: any) => {
      if (index === useMinutesSupporter.length - 1) {
        tempMinutesUseSupporter += `${item?.value.toString()}`;
      } else {
        tempMinutesUseSupporter += `${item?.value.toString()},`;
      }
    });

    formData.append("HoursUsed", useHours ? useHours.toString() : "");
    formData.append("HoursUsed_Supporter", tempHourUseSupporter);
    formData.append("MinutesUsed", useMinutes ? useMinutes.toString() : "");
    formData.append("MinutesUsed_Supporter", tempMinutesUseSupporter);

    let listFileString: string = "";
    let listFileNameString: string = "";
    let listFileNoTaskString: string = "";

    if (filesList.length === 0) {
      // No files changing
      fileListAPI.forEach((item: any, index: any) => {
        if (index === fileListAPI.length - 1) {
          listFileString += `${item.listFile}`;
          listFileNameString += `${item.listFileName}`;
        } else {
          listFileString += `${item.listFile};`;
          listFileNameString += `${item.listFileName};`;
        }
      });

      formData.append("ListFiles", listFileString);
      formData.append("FilesName_TaskFiles", listFileNameString);
      formData.append("Files_TaskFiles", listFileNoTaskString);
    } else {
      // Adding new
      fileListAPI.forEach((item: any, index: any) => {
        if (index === fileListAPI.length - 1) {
          listFileString += `${item.listFile}`;
          listFileNameString += `${item.listFileName}`;
        } else {
          listFileString += `${item.listFile};`;
          listFileNameString += `${item.listFileName};`;
        }
      });

      filesList.forEach((item: any, index: any) => {
        formData.append("Files_TaskFiles", item);

        if (index === 0) {
          if (fileListAPI.length === 0 || fileListAPI === null) {
            listFileNameString += `${item.name};`;
          } else {
            listFileNameString += `;${item.name};`;
          }
        } else if (index === filesList.length - 1) {
          listFileNameString += `${item.name}`;
        } else {
          listFileNameString += `${item.name};`;
        }
      });

      formData.append("ListFiles", listFileString);
      formData.append("FilesName_TaskFiles", listFileNameString);
    }

    // formData.append("ListFiles", listFileString)
    // formData.append("FilesName_TaskFiles", listFileNameString)
    // formData.append("Files_TaskFiles", listFileNoTaskString)

    // if (filesList) {
    //   for (let i = 0; i < filesList.length; i++) {
    //     formData.append(`Files_TaskFiles`, filesList[i]);

    //     if (i === filesList.length - 1) {
    //       fileString += `${filesList[i].name}`;
    //     } else {
    //       fileString += `${filesList[i].name};`;
    //     }
    //   }
    //   formData.append("FilesName_TaskFiles", fileString);
    // }

    // fileListAPI.forEach((item: any, index: any) => {
    //   if (index === fileListAPI.length - 1) {
    //     fileStringReady += `${item.listFileName}`
    //   } else {
    //     fileStringReady += `${item.listFileName};`
    //   }
    // });

    // formData.append("ListFiles", fileStringReady);

    if (selectedBuocList !== null) {
      for (let index = 0; index < selectedBuocList.length; index++) {
        formData.append(
          `TaskContent[${index}].StepName`,
          selectedBuocList[index].StepName
        );
        formData.append(
          `TaskContent[${index}].UserDo`,
          selectedBuocList[index].UserDo
        );
        formData.append(
          `TaskContent[${index}].SortOrder`,
          selectedBuocList[index].SortOrder
        );
        formData.append(
          `TaskContent[${index}].DeadLine_Step`,
          selectedBuocList[index].DeadLine_Step
        );
      }
    }

    if (selectedKetQuaList !== null) {
      for (let index = 0; index < selectedKetQuaList.length; index++) {
        formData.append(
          `TaskResult[${index}].ResultText`,
          selectedKetQuaList[index].tenketqua
        );
      }
    }

    if (!props.isTransfer) {
      setIsLoading(true);
    }

    formData.append("IsForce", isForce.toString());
    formData.append("PhongBanId", organizationId);
    const API_reload =
      window.location.pathname === "/working/new-task"
        ? NGUOI_GIAO_VIEC_LIST
        : NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN;

    const takeData = (): Promise<any> => {
      return new Promise((resolve, reject) => {
        const accessToken = localStorage.getItem("accessToken");

        try {
          ServiceUtils.postFormDataV2(NGUOI_GIAO_VIEC_UPDATE, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                readList(
                  API_reload,
                  data.pageCurrent,
                  data.choosePage,
                  data.searchFilter.taskName,
                  data.searchFilter.selectedPrior,
                  data.searchFilter.selectedReceiver,
                  data.searchFilter.fromDate,
                  data.searchFilter.toDate,
                  data.searchFilter.deadLine,
                  shouldLog3,
                  urlLink,
                  (res: any) => {
                    window.location.pathname === "/working/new-task"
                      ? dispatch(ONLOAD_TASKLIST_ALL(res.data.Data))
                      : dispatch(ONCHANGE_VIEC_CAN_GIAO(res.data.Data));
                  }
                );
                setIsLoading(false);
                if (type !== true) {
                  toast.success("Chỉnh sửa thành công.");
                }
                handleClose();
                resolve(true);
              } else {
                setIsLoading(false);
                toast.error(res.data.Message);
                resolve(false);
              }
            });
        } catch (error: any) {
          setIsLoading(false);
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
          resolve(false);
        }
      });
    };

    if (props.isTransfer) {
      formData.delete("ID");
      setDataUpdate(formData);
    } else {
      return takeData();
    }
    return true;
  };

  const renderMenuTransfer = (): any => {
    let ui = (
      <>
        {/* detail */}
        <button
          className="flex w-full h-full hover:text-sky-500"
          onClick={onTransfer}
        >
          {/* detail - contain */}
          <div className="flex w-full h-full space-x-1 p-2">
            {/* icon */}
            <NextPlanIcon
              className="text-sky-600"
              sx={{ fontSize: 18 }}
              fontSize="small"
            />
            {/* title */}
            <div>Chuyển giao</div>
          </div>
        </button>
      </>
    );

    return ui;
  };

  const isTransfer = (): boolean => {
    let check = false;

    return check;

  };

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[70rem] w-screen lg:h-[40rem] h-[calc(100vh-5rem)]
      rounded-md
    "
    >
      <SubModal
        bodyAdding={bodyAdding}
        _onChangeWorkerViaDepart={_onChangeWorkerViaDepart}
        resetFilter={resetFilter}
        _onCheckNguoiGopY={_onCheckNguoiGopY}
        open={openSub}
        handleClose={handleCloseSub}
        _onCheckNguoiPhoiHop={_onCheckNguoiLamViec}
        selectedNguoiPhoiHopList={selectedNguoiPhoiHopList}
        selectedNguoiLamViecList={selectedNguoiLamViecList}
        selectedNguoiGopYList={selectedNguoiGopYList}
        selectedBuocList={selectedBuocList}
        positionStep={positionStep}
        hourStep={hourStep}
        contentStep={contentStep}
        _onChangePositionStep={_onChangePositionStep}
        _onChangeHourStep={_onChangeHourStep}
        _onChangeContentStep={_onChangeContentStep}
        _onPushStep={_onPushStep}
        _onDeleteQuyTrinh={_onDeleteQuyTrinh}
        _onChangeNguoiPhoiHopEachStep={_onChangeNguoiPhoiHopEachStep}
        selectedKetQuaList={selectedKetQuaList}
        ketqua={ketqua}
        _onPushKetqua={_onPushKetqua}
        _onChangeKetqua={_onChangeKetqua}
        _onDeleteKetqua={_onDeleteKetqua}
        _onChangeStepList={_onChangeStepList}
        funcs={funcsSub}
        isTransfer={_isTransfer}
        onHandleRecoveryTask={onHandleRecoveryTask}
        dataUpdateRecovery={dataUpdate}
        taskId={taskId}
        callBack={props.callBack}
        typeTransfer={props.typeTransfer}
        taskCode={taskCode}
        _onClickUpdate={_onClickUpdate}
        _onClickSwitchToBoss={() => { }}
      />

      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Chi tiết / Chỉnh sửa</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={() => handleClose()}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      {phongBanList === null ||
        userList === null ||
        loaiViecList === null ||
        nguonVanBanList === null ||
        uuTienList === null ||
        doMatList === null ||
        dauViecList === null ||
        quyTrinhList === null ||
        doKhoList === null ||
        isAutoSecurity === null ||
        isAutoForce === null ? (
        <div className="flex text-primary justify-center items-center flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars">
          <LoaderSpinner w={32} h={32} />
          <p>Đang tải biểu mẫu</p>
        </div>
      ) : isLoading === true ? (
        <div className="flex text-primary justify-center items-center flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars">
          <LoaderSpinner w={32} h={32} />
          <p>Đang tạo công việc mới</p>
        </div>
      ) : (
        <div className="flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-2">
          <div className="w-full h-max flex justify-between items-center gap-2">
            <p className="font-semibold text-red-500">
              Lưu ý: Việc đã được giao thì không được phép chỉnh sửa thông tin
            </p>
            <button
              className="w-[80px] h-max rounded-md p-2 bg-slate-300 font-bold hover:bg-slate-200"
              onClick={() => {
                localStorage.removeItem("tempTaskCreate");
                window.location.reload();
              }}
            >
              Tải lại
            </button>
          </div>

          <>
            {/* Web detail */}
            <div className="hidden lg:flex w-full h-max flex-col mt-4 text-sm space-y-2 z-[70]">
              {/* <div className="w-full h-max">
                <label>
                  Đầu mục&nbsp;<span className="text-red-500">(*)</span>
                </label>

                <Select
                  className="z-[5]"
                  options={tempDauViecList}
                  onChange={(data: any) => _onChangeDauViecList(data)}
                  // value={finalDauViec}
                  isClearable
                  placeholder="--- Chọn đầu mục ---"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  value={finalDauViec}
                />
              </div> */}

              <DauMucViecJSX />

              {/* <div className="w-full h-max relative pb-16 z-[60]">
                <div className="w-full h-max absolute">
                  <label>
                    Gợi ý&nbsp;<span className="text-red-500">(*)</span>
                  </label>

                  <div className="w-full h-max inline-block space-y-1.5">
                    <input
                      className="w-full h-[2.5rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      placeholder="Nhập gợi ý"
                      value={suggest}
                      onChange={(e: any) => {
                        e.preventDefault()
                        setSuggest(e.target.value)
                      }}
                      onClick={() => {
                        setIsShowSuggest(true)
                      }}
                    />

                    <div
                      className={`
                      w-full h-max max-h-96
                      ${isShowSuggest ? "block" : "hidden"} overflow-y-auto
                      p-2 rounded-sm bg-stone-50 drop-shadow-md
                    `}
                    >
                      {
                        tempSuggestList?.map((item: any, index: number) => (
                          <button key={index} className="w-full h-[30px] flex justify-start items-center hover:bg-slate-200 px-2"
                            onClick={() => {
                              setSuggest(item?.NameSuggest)
                              setIsShowSuggest(false)
                            }}
                          >
                            {item?.NameSuggest}
                          </button>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="w-full h-max">
                <label>
                  Tên công việc &nbsp;
                  <span className="text-red-500">(*)</span>{" "}
                </label>
                <textarea
                  className="w-full h-[2.5rem] rounded-md p-2 border-2 border-slate-200"
                  placeholder="Nhập tên công việc"
                  value={taskName}
                  onChange={(e: any) => _onChangeTaskName(e)}
                />
              </div>
              <div className="flex w-full space-x-4">
                <div className="w-full space-y-2">
                  <div className="flex w-full space-x-3">
                    <div className="w-full space-y-1">
                      <label>
                        Loại công việc/dịch vụ/tư vấn&nbsp;
                        {isAutoLoaiViec && (
                          <span className="text-red-500">(*)</span>
                        )}
                      </label>
                      <select
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={
                          selectedLoaiViec === -1
                            ? -1
                            : selectedLoaiViec?.ParameterId
                        }
                        onChange={(e: any) => _onChangeSelectedLoaiCongViec(e)}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn loại công việc ---
                        </option>
                        {loaiViecList === null ? (
                          <></>
                        ) : (
                          loaiViecList.map((item: any, index: any) => (
                            <option key={index} value={item?.ParameterId}>
                              {item?.ParameterValue}
                            </option>
                          ))
                        )}
                      </select>
                    </div>

                    <div className="w-full space-y-1">
                      <label>
                        Cơ sở căn cứ bắt đầu công việc
                        {isAutoOrigin && (
                          <span className="text-red-500">(*)</span>
                        )}
                      </label>
                      {/* <select
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={
                          selectedNguonVanBan === -1
                            ? -1
                            : selectedNguonVanBan?.ParameterId
                        }
                        onChange={(e: any) => _onChangeNguonVanBan(e)}
                      >
                        <option value={-1} disabled>
                          --- Chọn căn cứ ---
                        </option>
                        {nguonVanBanList === null ? (
                          <></>
                        ) : (
                          nguonVanBanList.map((item: any, index: any) => (
                            <option key={index} value={item?.ParameterId}>
                              {item?.ParameterValue}
                            </option>
                          ))
                        )}
                      </select> */}
                      <input
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        placeholder="Nhập căn cứ"
                        value={selectedNguonVanBan}
                        onChange={(e: any) => _onChangeNewNguonVanBan(e)}
                      />
                    </div>
                  </div>

                  <div className="flex w-full space-x-3">
                    <div className={`w-full space-y-1 flex-col`}>
                      <label>
                        Độ mật&nbsp;
                        {isAutoSecurity && (
                          <span className={`text-red-500`}>(*)</span>
                        )}
                      </label>
                      <select
                        className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                    ${selectedDoMat === -1 || !selectedDoMat ? "" : "font-bold"}
                  `}
                        value={
                          selectedDoMat === -1 || !selectedDoMat
                            ? -1
                            : (selectedDoMat || {}).Id
                        }
                        onChange={(e: any) => _onChangeDoMat(e)}
                        style={{
                          color: `${selectedDoMat === -1 || !selectedDoMat
                            ? ""
                            : selectedDoMat.Color
                            }`,
                          borderColor: `${selectedDoMat === -1 || !selectedDoMat
                            ? ""
                            : selectedDoMat.Color
                            }`,
                        }}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn độ mật ---
                        </option>
                        {doMatList === null ? (
                          <></>
                        ) : (
                          doMatList.map((item: any, index: any) => (
                            <option
                              style={{ color: `${item?.Color}` }}
                              key={index}
                              value={item?.Id}
                            >
                              {item?.SecurityLevelName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>

                    <div className="w-full space-y-1">
                      <label>
                        Mức độ ưu tiên&nbsp;
                        {isAutoPriority && (
                          <span className={`text-red-500`}>(*)</span>
                        )}
                      </label>
                      <select
                        className={`
                      w-full h-[2rem] rounded-md pl-2 pr-2 border-2
                      ${selectedUuTien === -1 ? "" : "font-bold"}
                    `}
                        value={selectedUuTien === -1 ? -1 : selectedUuTien?.Id}
                        onChange={(e: any) => _onChangeSelectedUuTien(e)}
                        style={{
                          color: `${selectedUuTien === -1 ? "" : selectedUuTien?.Color
                            }`,
                          borderColor: `${selectedUuTien === -1 ? "" : selectedUuTien?.Color
                            }`,
                        }}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn mức độ ---
                        </option>
                        {uuTienList === null ? (
                          <></>
                        ) : (
                          uuTienList.map((item: any, index: any) => (
                            <option
                              style={{ color: `${item?.Color}` }}
                              key={index}
                              value={item?.Id}
                            >
                              {item?.PriorityLevelName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="flex w-full space-x-3">
                    {/* <div className="w-full space-y-1">
                      <label>
                        Thời hạn hoàn thành&nbsp;
                        <span className="text-red-500">(*)</span>
                      </label>
                      <input
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        placeholder="Nhập tên công việc"
                        type="datetime-local"
                        value={deadLine}
                        onChange={(e: any) => _onChangeDeadLine(e)}
                      />
                    </div> */}

                    {/* <DateTimePickerControl className={'width-100-percent padding-right-0'} label={'Thời hạn hoàn thành (*)'} value={deadLine} onChange={(e: any) => {
                      const value = e;

                      _onChangeDeadLine2(value);
                    }} /> */}

                    <DeadLineJSX />

                    <div className="w-full space-y-1">
                      <label>
                        Độ khó&nbsp;
                        {isAutoDifference && (
                          <span className="text-red-500">(*)</span>
                        )}
                      </label>
                      <select
                        className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                    ${selectedDoKho === -1 || !selectedDoKho ? "" : "font-bold"}
                  `}
                        value={
                          selectedDoKho === -1 || !selectedDoKho
                            ? -1
                            : selectedDoKho.Id
                        }
                        onChange={(e: any) => _onChangeDoKho(e)}
                        style={{
                          color: `${selectedDoKho === -1 || !selectedDoKho
                            ? ""
                            : selectedDoKho.Color
                            }`,
                          borderColor: `${selectedDoKho === -1 || !selectedDoKho
                            ? ""
                            : selectedDoKho.Color
                            }`,
                        }}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn độ khó ---
                        </option>
                        {doKhoList === null ? (
                          <></>
                        ) : (
                          doKhoList.map((item: any, index: any) => (
                            <option
                              style={{ color: `${item?.Color}` }}
                              key={index}
                              value={item?.Id}
                            >
                              {item?.DifficultLevelName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>
                  {/* <div className="flex w-full pr-3 h-max space-x-3">
                    <div className="w-full h-max">
                      <label>
                        Số giờ hoàn thành&nbsp;
                        {isAutoRequiredUseHours && (
                          <span className={`text-red-500`}>(*)</span>
                        )}
                      </label>
                      <input
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        placeholder="Nhập số giờ"
                        type="number"
                        value={useHours}
                        onChange={(e: any) => _onChangeHours(e)}
                      />
                    </div>
                  </div> */}

                  <div className="w-full h-max">
                    <label>
                      Thời gian thực hiện
                      {isAutoRequiredUseHours && (
                        <span className={`text-red-500`}>(*)</span>
                      )}
                    </label>
                    <div className="flex w-full h-max space-x-2">
                      <div className="w-1/2 h-max flex flex-col">
                        <label>Số giờ</label>
                        <input
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          placeholder="Nhập số giờ"
                          type="number"
                          value={useHours}
                          onChange={(e: any) => _onChangeHours(e)}
                        />
                      </div>
                      <div className="w-1/2 h-max flex flex-col">
                        <label>Số phút</label>
                        <input
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          placeholder="Nhập số phút"
                          type="number"
                          value={useMinutes}
                          onChange={(e: any) => _onChangeMinutes(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full space-y-2">
                  <div className="w-full">
                    <div className="flex w-full h-max">
                      {/* elemetns */}
                      <div className="flex flex-col w-1/2 space-x-3 space-y-4">
                        <div className={`flex-col w-full h-max space-y-1`}>
                          <div className={`w-max h-max flex flex-col`}>
                            <div className="w-max h-max flex space-x-2">
                              <input
                                type="checkbox"
                                className="w-5 h-5"
                                checked={isMulti}
                                onChange={_onChangeIsMulti}
                              />
                              <label className="text-slate-500 font-bold pl-2 pr-2 bg-slate-200 flex justify-center items-center">
                                Giao việc cho nhiều người
                              </label>
                            </div>

                            <p className="font-semibold text-red-500">
                              (Chức năng giao việc cho nhiều người không thể
                              chọn người hỗ trợ.)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isMulti ? (
                    <div className="flex space-x-2 items-top">
                      <div className="w-full space-y-2">
                        <div className="flex w-max h-max space-x-3">
                          <input
                            type="checkbox"
                            className="w-5 h-5"
                            checked={isAllUser}
                            onChange={(e: any) => _onChangeListMultiUser(5, e)}
                          />
                          <label className="text-slate-500 font-bold pl-2 pr-2">
                            Chọn toàn bộ
                          </label>
                        </div>
                        <label>Chọn nhanh cả phòng</label>
                        <Select
                          className="w-full z-50"
                          options={phongBanListForSelect}
                          value={null}
                          onChange={(e: any) => _onChangeListMultiUser(1, e)}
                          isClearable
                          placeholder="Chọn nhanh cả phòng"
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                        />
                        <label>Chọn từng người</label>
                        <Select
                          className="w-full z-49"
                          options={userListForSelect}
                          value={null}
                          onChange={(e: any) => _onChangeListMultiUser(2, e)}
                          placeholder="Chọn từng người"
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                        />
                        {/* <div className="w-full space-y-1"> */}
                        <label>Chọn theo phòng ban</label>
                        {/* <div className="flex w-full space-x-3"> */}
                        {/* <div className="w-full space-y-1"> */}
                        <select
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2"
                          value={
                            phongBanSelectedForSelect === -1
                              ? -1
                              : phongBanSelectedForSelect?.IdPhongBan
                          }
                          onChange={(e: any) => _onChangePhongBanSelect(e, 2)}
                        >
                          <option value={-1} disabled>
                            --- Chọn phòng ban ---
                          </option>
                          {phongBanList === null ? (
                            <></>
                          ) : (
                            phongBanList.map((item: any, index: any) => (
                              <option key={index} value={item?.IdPhongBan}>
                                {item?.TenPhongBan}
                              </option>
                            ))
                          )}
                        </select>
                        {/* </div> */}

                        <Select
                          className="w-full z-48"
                          options={userListForSelected}
                          // value={single}
                          onChange={(e: any) => _onChangeListMultiUser(3, e)}
                          placeholder="Chọn nhân viên"
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }}
                        />

                        {/* </div> */}
                        {/* </div> */}
                      </div>

                      <div className="w-full">
                        <Select
                          className="w-full"
                          // options={votingList}
                          isMulti
                          value={listMultiUser}
                          onChange={(e: any) => _onChangeListMultiUser(4, e)}
                          isClearable
                          placeholder="Danh sách người nhận"
                          components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            NoOptionsMessage: () => null,
                          }}
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              whiteSpace: "normal",
                            }),
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="flex w-full space-x-3">
                        <div className="w-full space-y-1">
                          <label>
                            Phòng ban&nbsp;
                            <span className="text-red-500">(*)</span>
                          </label>
                          <select
                            className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 ${selectedPhongBan === -1 ? "" : "font-bold"
                              }`}
                            value={
                              selectedPhongBan === -1
                                ? -1
                                : selectedPhongBan?.IdPhongBan
                            }
                            onChange={(e: any) => _onChangePhongBan(e)}
                            style={{
                              color: `${selectedPhongBan === -1
                                ? ""
                                : selectedPhongBan?.ColorText
                                }`,
                              borderColor: `${selectedPhongBan === -1
                                ? ""
                                : selectedPhongBan?.ColorText
                                }`,
                            }}
                          >
                            <option value={-1} disabled>
                              --- Chọn phòng ban ---
                            </option>
                            {phongBanList === null ? (
                              <></>
                            ) : (
                              phongBanList.map((item: any, index: any) => (
                                <option key={index} value={item?.IdPhongBan}>
                                  {item?.TenPhongBan}
                                </option>
                              ))
                            )}
                          </select>
                        </div>

                        <div className="w-full space-y-1">
                          <label>
                            Người nhận&nbsp;
                            <span className="text-red-500">(*)</span>
                          </label>
                          <select
                            className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                      ${selectedNguoiNhan === -1 ? "" : "font-bold"}
                    `}
                            style={{
                              color: `${selectedNguoiNhan === -1
                                ? ""
                                : selectedNguoiNhan?.ColorText
                                }`,
                              borderColor: `${selectedNguoiNhan === -1
                                ? ""
                                : selectedNguoiNhan?.ColorText
                                }`,
                            }}
                            value={
                              selectedNguoiNhan === -1
                                ? -1
                                : selectedNguoiNhan?.Id
                            }
                            onChange={(e: any) => _onChangeNguoiNhan(e)}
                            disabled={selectedPhongBan === -1 ? true : false}
                          >
                            <option
                              className="text-gray-300"
                              value={-1}
                              disabled
                            >
                              --- Chọn người nhận ---
                            </option>
                            {selectedNguoiNhanList === null ? (
                              <></>
                            ) : (
                              selectedNguoiNhanList?.map(
                                (item: any, index: any) => (
                                  <option
                                    key={index}
                                    value={item?.Id}
                                    style={{
                                      color: `${item?.ColorText}`,
                                    }}
                                  >
                                    {item?.FullName}
                                  </option>
                                )
                              )
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="flex w-full space-x-3">
                        {selectedNguoiNhan && selectedNguoiNhan != -1 && (
                          <p>
                            <b>{selectedNguoiNhan?.FullName}</b> đã sử dụng{" "}
                            <b
                              style={{
                                color: `${selectedNguoiNhan?.ColorText}`,
                              }}
                            >
                              {selectedNguoiNhan?.Hours_in_month_used_percent}%
                              ({selectedNguoiNhan?.Hours_in_month_used} giờ)
                            </b>{" "}
                            thời gian làm việc của tháng. Còn lại{" "}
                            <b>
                              {
                                selectedNguoiNhan?.Hours_in_month_remaining_percent
                              }
                              % ({selectedNguoiNhan?.Hours_in_month_remaining}{" "}
                              giờ)
                            </b>{" "}
                            thời gian rảnh.
                          </p>
                        )}
                      </div>
                    </>
                  )}

                  <div className="flex w-full items-center">
                    <input
                      id="evalutionChoose"
                      className="mr-2 w-5 h-5"
                      type="checkbox"
                      checked={isChooseTaskReviewer}
                      onChange={_onChangeIsChooseTaskReviewer}
                    />
                    <label
                      className="hover:cursor-pointer text-lg"
                      htmlFor="evalutionChoose"
                    >
                      Chọn người đánh giá
                    </label>
                  </div>
                  <div className="flex w-full">
                    <p className="font-semibold text-red-500">
                      (Nếu không chọn thì mặc định người giao việc là người đánh
                      giá)
                    </p>
                  </div>

                  {isChooseTaskReviewer && (
                    <div className="flex w-full space-x-3">
                      <div className="w-full space-y-1">
                        <label>
                          Phòng ban người đánh giá&nbsp;
                          <span className="text-red-500">(*)</span>
                        </label>
                        <select
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2"
                          value={selectedPhongBanDanhGia.IdPhongBan}
                          onChange={(e: any) => _onChangePhongBanDanhGia(e)}
                        >
                          {/* <option value={userData.Data[0].IdPhongBan} disabled>
                      {userData.Data[0].TenPhongBan}
                    </option> */}
                          {phongBanDanhGiaList === null ? (
                            <></>
                          ) : (
                            phongBanDanhGiaList.map((item: any, index: any) => (
                              <option key={index} value={item?.IdPhongBan}>
                                {item?.TenPhongBan}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      <div className="w-full space-y-1">
                        <label>
                          Người đánh giá&nbsp;
                          <span className="text-red-500">(*)</span>
                        </label>
                        <select
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          value={selectedNguoiDanhGia.Id}
                          onChange={(e: any) => _onChangeNguoiDanhGia(e)}
                          disabled={
                            selectedPhongBanDanhGia === -1 ? true : false
                          }
                        >
                          <option value={selectedNguoiDanhGia.Id} disabled>
                            {selectedNguoiDanhGia.FullName}
                          </option>
                          {selectedNguoiDanhGiaList === null ? (
                            <></>
                          ) : (
                            selectedNguoiDanhGiaList.map(
                              (item: any, index: any) => (
                                <option key={index} value={item?.Id}>
                                  {item?.FullName}
                                </option>
                              )
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  )}
                  {!isMulti && (
                    <div className="w-full h-max space-y-1">
                      <div className="flex w-max h-max space-x-3 items-center">
                        <label
                          style={{
                            fontSize: "1rem",
                          }}
                        >
                          Người phối hợp (nếu có)
                        </label>
                        <button
                          style={{
                            padding: "8px 16px",
                            fontSize: "0.9rem",
                          }}
                          className={`
                    text-white pl-2 pr-2 pt-0.5 pb-0.5 rounded-md
                    text-xs
                    ${selectedNguoiNhan === -1
                              ? "bg-slate-300"
                              : "bg-primary hover:bg-sky-400"
                            }
                  `}
                          disabled={selectedNguoiNhan === -1 ? true : false}
                          onClick={() =>
                            _onChangeNguoiPhoiHop("multipleChoicePartner")
                          }
                        >
                          Chọn
                        </button>
                      </div>
                      <div className="w-full h-max flex flex-col space-y-2">
                        {/* Support Executing */}
                        {/* <div className="w-full h-max">
                          <p className="font-semibold">Hỗ trợ thực hiện / Thời gian hoàn thành</p>
                          {selectedNguoiLamViecList === null
                            ? ""
                            : selectedNguoiLamViecList?.map(
                              (item: any, index: any) => (
                                <>
                                  {item?.Id === selectedNguoiNhan?.Id ? (
                                    <></>
                                  ) : (
                                    <div
                                      key={index}
                                      className="flex w-max h-max text-primary items-center justify-between py-1"
                                    >
                                      <input
                                        className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                        placeholder="Nhập số giờ"
                                        type="number"
                                        value={
                                          useHourSupporter?.find((_item: any) => _item?.id === item?.Id)?.value
                                        }
                                        onChange={(e: any) => _onChangeHoursSupporter(e, item?.Id)}
                                      />
                                      <p className="text-black">&nbsp;/&nbsp;</p>
                                      <div
                                        className="flex w-max h-max space-x-1 font-bold mr-2"
                                        style={{
                                          color: `${item?.ColorText}`,
                                        }}
                                      >
                                        <PersonOutlineTwoToneIcon fontSize="small" />
                                        <p>{item?.FullName}</p>
                                      </div>
                                      <button
                                        className="text-red-500 justify-end"
                                        onClick={() =>
                                          _onCheckNguoiLamViec(item?.Id)
                                        }
                                      >
                                        <DeleteTwoToneIcon fontSize="small" />
                                      </button>
                                    </div>
                                  )}
                                </>
                              )
                            )}
                        </div> */}

                        <div className="w-full h-max">
                          <p className="font-semibold">
                            Hỗ trợ thực hiện / Thời gian hoàn thành
                          </p>

                          {/* Result */}
                          <div className="w-full h-max">
                            <table className="w-full">
                              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                                <tr>
                                  <th className="border border-slate-300 text-left">
                                    Người thực hiện
                                  </th>
                                  <th className="border border-slate-300">
                                    Số giờ hoàn thành
                                  </th>
                                  <th className="border border-slate-300">
                                    Số phút hoàn thành
                                  </th>
                                  <th className="border border-slate-300 w-[1rem]"></th>
                                </tr>
                              </thead>
                              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                                {selectedNguoiLamViecList === null
                                  ? ""
                                  : selectedNguoiLamViecList?.map(
                                    (item: any, index: any) => (
                                      <>
                                        {item?.Id ===
                                          selectedNguoiNhan?.Id ? (
                                          <></>
                                        ) : (
                                          <tr
                                            key={index}
                                            className="w-max h-[3rem] bg-white"
                                          >
                                            <td className="text-left border border-slate-300">
                                              <div
                                                className="flex w-max h-max space-x-1 font-bold mr-2"
                                                style={{
                                                  color: `${item?.ColorText}`,
                                                }}
                                              >
                                                <PersonOutlineTwoToneIcon fontSize="small" />
                                                <p>{item?.FullName}</p>
                                              </div>
                                            </td>
                                            <td className="text-center border border-slate-300">
                                              {isAutoRequiredUseHours ===
                                                true ? (
                                                <p className="text-xs"></p>
                                              ) : (
                                                <input
                                                  className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                                  placeholder="Nhập số giờ"
                                                  type="number"
                                                  value={
                                                    useHourSupporter?.find(
                                                      (_item: any) =>
                                                        _item?.id === item?.Id
                                                    )?.value
                                                  }
                                                  onChange={(e: any) =>
                                                    _onChangeHoursSupporter(
                                                      e,
                                                      item?.Id
                                                    )
                                                  }
                                                />
                                              )}
                                            </td>
                                            <td className="text-center border border-slate-300">
                                              {isAutoRequiredUseMinutes ===
                                                true ? (
                                                <p className="text-xs"></p>
                                              ) : (
                                                <input
                                                  className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                                  placeholder="Nhập số giờ"
                                                  type="number"
                                                  value={
                                                    useMinutesSupporter?.find(
                                                      (_item: any) =>
                                                        _item?.id === item?.Id
                                                    )?.value
                                                  }
                                                  onChange={(e: any) =>
                                                    _onChangeMinutesSupporter(
                                                      e,
                                                      item?.Id
                                                    )
                                                  }
                                                />
                                              )}
                                            </td>
                                            <td className="text-left border border-slate-300">
                                              <button
                                                className="text-red-500 justify-end"
                                                onClick={() =>
                                                  _onCheckNguoiLamViec(
                                                    item?.Id
                                                  )
                                                }
                                              >
                                                <DeleteTwoToneIcon fontSize="small" />
                                              </button>
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {/* Support Recomment */}
                        <div className="w-full h-max">
                          <p className="font-semibold">Hỗ trợ góp ý</p>
                          {selectedNguoiGopYList === null
                            ? ""
                            : selectedNguoiGopYList.map(
                              (item: any, index: any) => (
                                <>
                                  {item?.Id === selectedNguoiNhan?.Id ? (
                                    <></>
                                  ) : (
                                    <div
                                      key={index}
                                      className="flex w-max h-max text-primary items-center justify-between"
                                    >
                                      <div
                                        className="flex w-max h-max space-x-1 font-bold mr-2"
                                        style={{
                                          color: `${item?.ColorText}`,
                                        }}
                                      >
                                        <PersonOutlineTwoToneIcon fontSize="small" />
                                        <p>{item?.FullName}</p>
                                      </div>
                                      <button
                                        className="text-red-500 justify-end"
                                        onClick={() =>
                                          _onCheckNguoiGopY(item?.Id)
                                        }
                                      >
                                        <DeleteTwoToneIcon fontSize="small" />
                                      </button>
                                    </div>
                                  )}
                                </>
                              )
                            )}
                        </div>
                      </div>
                      <div className="flex w-full h-max items-center space-x-6">
                        {/* overload */}
                        <div className="flex items-center space-x-1">
                          <CircleIcon className="text-[#00ff00]" />
                          <p>Có thể nhận việc</p>
                        </div>
                        <div className="flex items-center space-x-1">
                          <CircleIcon className="text-[#FFAA00]" />
                          <p>Đang nhiều việc</p>
                        </div>
                        <div className="flex items-center space-x-1">
                          <CircleIcon className="text-[#ff0000]" />
                          <p>Quá tải</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full">
                <div className="flex w-full h-max">
                  {/* elemetns */}
                  <div className="flex flex-col w-1/2 space-y-4">
                    {/* <div
                      className={`
                flex-col w-full h-max space-y-1
              `}
                    >
                      <div
                        className={`${isAutoForce === false ? "hidden" : "flex"
                          } w-max h-max space-x-3`}
                      >
                        <input
                          type="checkbox"
                          checked={isForce}
                          onChange={_onChangeIsForce}
                        />
                        <label className="text-slate-500 font-bold pl-2 pr-2 bg-slate-200">
                          KHÔNG CHO PHÉP TRẢ VIỆC
                        </label>
                      </div>
                    </div> */}
                    <div
                      className={`
                        flex-col w-full h-max space-y-1
                      `}
                    >
                      <div
                        className={`${isAutoForce === false ? "hidden" : "flex"
                          } w-max h-max space-x-3`}
                      >
                        <input
                          type="checkbox"
                          className="w-5 h-5"
                          checked={isExChange}
                          onChange={() => {
                            if (isExChange === true) {
                              setIsExChange(false);
                            } else {
                              setIsExChange(true);
                            }
                          }}
                          id="isExChange"
                        // checked={isForce}
                        // onChange={_onChangeIsForce}
                        />
                        <label
                          htmlFor="isExChange"
                          className="text-slate-500 hover:cursor-pointer flex justify-center items-center font-bold pl-2 pr-2 bg-slate-200"
                        >
                          CHO PHÉP THOẢ THUẬN THỜI HẠN
                        </label>
                      </div>
                    </div>

                    <div
                      className={`
                        flex-col w-full h-max space-y-1
                      `}
                    >
                      <div
                        className={`${isAutoForce === false ? "hidden" : "flex"
                          } w-max h-max space-x-3`}
                      >
                        <input
                          type="checkbox"
                          className="w-5 h-5"
                          checked={isEvaluate}
                          onChange={() => {
                            if (isEvaluate === true) {
                              setIsEvaluate(false);
                            } else {
                              setIsEvaluate(true);
                            }
                          }}
                          id="IsEvaluate"
                        // checked={isForce}
                        // onChange={_onChangeIsForce}
                        />
                        <label
                          htmlFor="IsEvaluate"
                          className="text-slate-500 hover:cursor-pointer flex justify-center items-center font-bold pl-2 pr-2 bg-slate-200"
                        >
                          CÔNG VIỆC KHÔNG CẦN ĐÁNH GIÁ
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col w-full h-max space-y-2">
                <QuytrinhJSX />
              </div>
              <div className="flex w-full h-max">
                {/* buoc */}
                <StepListJSX />

                {/* ket qua */}
                <div
                  className={`
              w-1/3 h-max pl-3
              ${selectedKetQuaList === null ? "flex" : "flex"}
            `}
                >
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300 ">STT</th>
                        <th className="border border-slate-300 ">
                          Kết quả cần đạt
                        </th>
                        <th className="border text-center border-slate-300 w-[1rem]">
                          <button
                            className="
                  flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                  rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                "
                            onClick={() => _onClickEditKetqua("editKetqua")}
                          >
                            +
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {selectedKetQuaList === null ? (
                        <tr></tr>
                      ) : (
                        selectedKetQuaList?.map((item: any, index: any) => (
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="text-center border border-slate-300">
                              {index + 1}
                            </td>
                            <td className="text-left border border-slate-300">
                              {item?.tenketqua}
                            </td>
                            <td className="border text-center border-slate-300 w-[1rem]">
                              <button
                                className="text-red-500"
                                onClick={() => _onDeleteKetqua(item?.tenketqua)}
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Phone detail */}
            <div className="lg:hidden space-y-1.5">
              {/* <div className="w-full h-max space-y-0.5">
                <label>
                  Đầu mục&nbsp;<span className="text-red-500">(*)</span>
                </label>
                <Select
                  className="z-[5]"
                  options={tempDauViecList}
                  onChange={(data: any) => _onChangeDauViecList(data)}
                  isClearable
                  placeholder="Nhập đầu mục"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div> */}
              <DauMucViecJSX />

              <div className="w-full h-max space-y-0.5">
                <label>
                  Tên công việc &nbsp;
                  <span className="text-red-500">(*)</span>{" "}
                </label>
                <input
                  className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập tên công việc"
                  value={taskName}
                  onChange={(e: any) => _onChangeTaskName(e)}
                />
              </div>
              <div className="w-full h-max space-y-0.5">
                <label>
                  Loại công việc/dịch vụ/tư vấn&nbsp;
                  {isAutoLoaiViec && <span className="text-red-500">(*)</span>}
                </label>
                <select
                  className="w-full h-[2rem] rounded-md px-1 border-2 border-slate-200"
                  value={
                    selectedLoaiViec === -1 ? -1 : selectedLoaiViec?.ParameterId
                  }
                  onChange={(e: any) => _onChangeSelectedLoaiCongViec(e)}
                >
                  <option className="text-gray-300" value={-1} disabled>
                    Chọn loại công việc
                  </option>
                  {loaiViecList === null ? (
                    <></>
                  ) : (
                    loaiViecList.map((item: any, index: any) => (
                      <option key={index} value={item?.ParameterId}>
                        {item?.ParameterValue}
                      </option>
                    ))
                  )}
                </select>
              </div>

              <div className="flex w-full h-max space-x-1 core-control-group">
                <div className="w-full h-max space-y-0.5">
                  <label>
                    Mức độ&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`
                      w-full h-[2rem] rounded-md px-1 border-2 border-slate-200 text-gray-500
                      ${selectedUuTien === -1 ? "" : "font-bold"}
                    `}
                    value={selectedUuTien === -1 ? -1 : selectedUuTien?.Id}
                    onChange={(e: any) => _onChangeSelectedUuTien(e)}
                    style={{
                      color: `${selectedUuTien === -1 ? "" : selectedUuTien?.Color
                        }`,
                      borderColor: `${selectedUuTien === -1 ? "" : selectedUuTien?.Color
                        }`,
                    }}
                  >
                    <option value={-1} disabled>
                      Chọn mức độ
                    </option>
                    {uuTienList === null ? (
                      <></>
                    ) : (
                      uuTienList.map((item: any, index: any) => (
                        <option
                          key={index}
                          value={item?.Id}
                          style={{ color: `${item?.Color}` }}
                        >
                          {item?.PriorityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-full h-max space-y-0.5">
                  <label>
                    Nguồn văn bản&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  {/* <select
                    className="w-full h-[2rem] rounded-md px-1 border-2 border-slate-200 text-gray-500"
                    value={
                      selectedNguonVanBan === -1
                        ? -1
                        : selectedNguonVanBan?.ParameterId
                    }
                    onChange={(e: any) => _onChangeNguonVanBan(e)}
                  >
                    <option value={-1} disabled>
                      Chọn căn cứ
                    </option>
                    {nguonVanBanList === null ? (
                      <></>
                    ) : (
                      nguonVanBanList.map((item: any, index: any) => (
                        <option key={index} value={item?.ParameterId}>
                          {item?.ParameterValue}
                        </option>
                      ))
                    )}
                  </select> */}
                  <input
                    className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    placeholder="Nhập căn cứ"
                    value={selectedNguonVanBan}
                    onChange={(e: any) => _onChangeNewNguonVanBan(e)}
                  />
                </div>
              </div>

              <div className="flex w-full h-max space-x-1 core-control-group">
                <div
                  className={`w-full flex-col h-max space-y-0.5 ${isAutoSecurity === false ? "hidden" : "flex"
                    }`}
                >
                  <label>
                    Độ mật<template></template>&nbsp;
                    <span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`
                    w-full h-[2rem] rounded-md px-1 border-2 border-slate-200 text-gray-500
                    ${selectedDoMat === -1 || !selectedDoMat ? "" : "font-bold"}
                  `}
                    value={
                      selectedDoMat === -1 || !selectedDoMat
                        ? -1
                        : (selectedDoMat || {}).Id
                    }
                    onChange={(e: any) => _onChangeDoMat(e)}
                    style={{
                      color: `${selectedDoMat === -1 || !selectedDoMat
                        ? ""
                        : selectedDoMat.Color
                        }`,
                      borderColor: `${selectedDoMat === -1 || !selectedDoMat
                        ? ""
                        : selectedDoMat.Color
                        }`,
                    }}
                  >
                    <option value={-1} disabled>
                      Chọn độ mật
                    </option>
                    {doMatList === null ? (
                      <></>
                    ) : (
                      doMatList.map((item: any, index: any) => (
                        <option
                          style={{ color: `${item?.Color}` }}
                          key={index}
                          value={item?.Id}
                        >
                          {item?.SecurityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-full h-max space-y-0.5">
                  <label>
                    Độ khó&nbsp;
                    {isAutoDifference && (
                      <span className="text-red-500">(*)</span>
                    )}
                  </label>
                  <select
                    className={`
                    w-full h-[2rem] rounded-md px-1 border-2 border-slate-200 text-gray-500
                    ${selectedDoKho === -1 || !selectedDoKho ? "" : "font-bold"}
                  `}
                    value={
                      selectedDoKho === -1 ||
                        !selectedDoKho ||
                        selectedDoKho === null ||
                        selectedDoKho === 0 ||
                        selectedDoKho === undefined
                        ? -1
                        : selectedDoKho.Id
                    }
                    onChange={(e: any) => _onChangeDoKho(e)}
                    style={{
                      color: `${selectedDoKho === -1 || !selectedDoKho
                        ? ""
                        : selectedDoKho.Color
                        }`,
                      borderColor: `${selectedDoKho === -1 || !selectedDoKho
                        ? ""
                        : selectedDoKho.Color
                        }`,
                    }}
                  >
                    <option value={-1} disabled>
                      Chọn độ khó
                    </option>
                    {doKhoList === null ? (
                      <></>
                    ) : (
                      doKhoList.map((item: any, index: any) => (
                        <option
                          style={{ color: `${item?.Color}` }}
                          key={index}
                          value={item?.Id}
                        >
                          {item?.DifficultLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>

              <div className="w-full h-max">
                <label>
                  Thời gian thực hiện
                  {isAutoRequiredUseHours && (
                    <span className={`text-red-500`}>(*)</span>
                  )}
                </label>
                <div className="flex w-full h-max space-x-2">
                  <div className="w-1/2 h-max flex flex-col">
                    <label>Số giờ</label>
                    <input
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      placeholder="Nhập số giờ"
                      type="number"
                      value={useHours}
                      onChange={(e: any) => _onChangeHours(e)}
                    />
                  </div>
                  <div className="w-1/2 h-max flex flex-col">
                    <label>Số phút</label>
                    <input
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      placeholder="Nhập số giờ"
                      type="number"
                      value={useMinutes}
                      onChange={(e: any) => _onChangeMinutes(e)}
                    />
                  </div>
                </div>
              </div>

              {/* <DateTimePickerControl className={'width-100-percent padding-right-0'} label={'Thời hạn hoàn thành (*)'} value={deadLine} onChange={(e: any) => {
                const value = e;

                _onChangeDeadLine2(value);
              }} /> */}

              <DeadLineJSX />

              {/* <div className="flex w-full h-max">
                <div className="flex w-full h-max space-x-3">
                  <div className="w-full h-max">
                    <label>
                      Thời hạn hoàn thành&nbsp;
                      <span className="text-red-500">(*)</span>
                    </label>
                    <input
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      type="datetime-local"
                      value={deadLine}
                      onChange={(e: any) => _onChangeDeadLine(e)}
                    />
                  </div>
                </div>
              </div> */}

              <div className="w-full">
                <div className="flex flex-col w-full h-max">
                  {/* elemetns */}
                  <div className={`flex w-max h-max space-x-1`}>
                    <input
                      type="checkbox"
                      className="w-5 h-5"
                      checked={isMulti}
                      onChange={_onChangeIsMulti}
                    />
                    <label
                      className="text-slate-500 font-bold pl-2 pr-2 bg-slate-200
                      flex items-center
                    "
                    >
                      Giao việc cho nhiều người
                    </label>
                  </div>
                  <p className="font-semibold text-red-500">
                    (Chức năng giao việc cho nhiều người không thể chọn người hỗ
                    trợ.)
                  </p>
                </div>
              </div>
              {isMulti ? (
                <div className="flex space-x-2 items-top">
                  <div className="w-full space-y-2">
                    <div className="flex w-max h-max space-x-3">
                      <input
                        type="checkbox"
                        className="w-5 h-5"
                        checked={isAllUser}
                        onChange={(e: any) => _onChangeListMultiUser(5, e)}
                      />
                      <label className="text-slate-500 font-bold pl-2 pr-2">
                        Chọn toàn bộ
                      </label>
                    </div>
                    <label>Chọn nhanh cả phòng</label>
                    <Select
                      className="w-full z-50"
                      options={phongBanListForSelect}
                      value={null}
                      onChange={(e: any) => _onChangeListMultiUser(1, e)}
                      isClearable
                      placeholder="Chọn nhanh cả phòng"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                    <label>Chọn từng người</label>
                    <Select
                      className="w-full z-49"
                      options={userListForSelect}
                      value={null}
                      onChange={(e: any) => _onChangeListMultiUser(2, e)}
                      placeholder="Chọn từng người"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                    {/* <div className="w-full space-y-1"> */}
                    <label>Chọn theo phòng ban</label>
                    {/* <div className="flex w-full space-x-3"> */}
                    {/* <div className="w-full space-y-1"> */}
                    <select
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2"
                      value={
                        phongBanSelectedForSelect === -1
                          ? -1
                          : phongBanSelectedForSelect?.IdPhongBan
                      }
                      onChange={(e: any) => _onChangePhongBanSelect(e, 2)}
                    >
                      <option value={-1} disabled>
                        --- Chọn phòng ban ---
                      </option>
                      {phongBanList === null ? (
                        <></>
                      ) : (
                        phongBanList.map((item: any, index: any) => (
                          <option key={index} value={item?.IdPhongBan}>
                            {item?.TenPhongBan}
                          </option>
                        ))
                      )}
                    </select>
                    {/* </div> */}

                    <Select
                      className="w-full z-48"
                      options={userListForSelected}
                      // value={single}
                      onChange={(e: any) => _onChangeListMultiUser(3, e)}
                      placeholder="Chọn nhân viên"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />

                    {/* </div> */}
                    {/* </div> */}
                  </div>

                  <div className="w-full">
                    <Select
                      className="w-full"
                      // options={votingList}
                      isMulti
                      value={listMultiUser}
                      onChange={(e: any) => _onChangeListMultiUser(4, e)}
                      isClearable
                      placeholder="Danh sách người nhận"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        NoOptionsMessage: () => null,
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          whiteSpace: "normal",
                        }),
                      }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <div className="w-full h-max space-y-0.5">
                    <label>
                      Phòng ban&nbsp;<span className="text-red-500">(*)</span>
                    </label>
                    <select
                      className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 text-gray-500 ${selectedPhongBan === -1 ? "" : "font-bold"
                        }`}
                      value={
                        selectedPhongBan === -1
                          ? -1
                          : selectedPhongBan?.IdPhongBan
                      }
                      onChange={(e: any) => _onChangePhongBan(e)}
                      style={{
                        color: `${selectedPhongBan === -1
                          ? ""
                          : selectedPhongBan?.ColorText
                          }`,
                        borderColor: `${selectedPhongBan === -1
                          ? ""
                          : selectedPhongBan?.ColorText
                          }`,
                      }}
                    >
                      <option value={-1} disabled>
                        --- Chọn phòng ban ---
                      </option>
                      {phongBanList === null ? (
                        <></>
                      ) : (
                        phongBanList.map((item: any, index: any) => (
                          <option key={index} value={item?.IdPhongBan}>
                            {item?.TenPhongBan}
                          </option>
                        ))
                      )}
                    </select>
                    <label>
                      Người nhận&nbsp;<span className="text-red-500">(*)</span>
                    </label>

                    <select
                      className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 text-gray-500
                    ${selectedNguoiNhan === -1 ? "" : "font-bold"}
                  `}
                      style={{
                        color: `${selectedNguoiNhan === -1
                          ? ""
                          : selectedNguoiNhan?.ColorText
                          }`,
                        borderColor: `${selectedNguoiNhan === -1
                          ? ""
                          : selectedNguoiNhan?.ColorText
                          }`,
                      }}
                      value={
                        selectedNguoiNhan === -1 ? -1 : selectedNguoiNhan?.Id
                      }
                      onChange={(e: any) => _onChangeNguoiNhan(e)}
                      disabled={selectedPhongBan === -1 ? true : false}
                    >
                      <option value={-1} disabled>
                        --- Chọn người nhận ---
                      </option>
                      {selectedNguoiNhanList === null ? (
                        <></>
                      ) : (
                        selectedNguoiNhanList?.map((item: any, index: any) => (
                          <option
                            style={{ color: `${item?.ColorText}` }}
                            key={index}
                            value={item?.Id}
                          >
                            {item?.FullName}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="w-full h-max space-y-0.5">
                    {selectedNguoiNhan && selectedNguoiNhan != -1 && (
                      <p>
                        <b>{selectedNguoiNhan?.FullName}</b> đã sử dụng{" "}
                        <b style={{ color: `${selectedNguoiNhan?.ColorText}` }}>
                          {selectedNguoiNhan?.Hours_in_month_used_percent}% (
                          {selectedNguoiNhan?.Hours_in_month_used} giờ)
                        </b>{" "}
                        thời gian làm việc của tháng. Còn lại{" "}
                        <b>
                          {selectedNguoiNhan?.Hours_in_month_remaining_percent}%
                          ({selectedNguoiNhan?.Hours_in_month_remaining} giờ)
                        </b>{" "}
                        thời gian rảnh.
                      </p>
                    )}
                  </div>
                </>
              )}

              <div className="flex w-full items-center">
                <input
                  className="mr-2 w-5 h-5"
                  type="checkbox"
                  checked={isChooseTaskReviewer}
                  onChange={_onChangeIsChooseTaskReviewer}
                />
                <p>Chọn người đánh giá</p>
              </div>
              <div className="flex w-full">
                <p className="font-semibold text-red-500">
                  (Nếu không chọn thì mặc định người giao việc là người đánh
                  giá)
                </p>
              </div>
              {isChooseTaskReviewer && (
                <>
                  <div className="w-full space-y-0.5">
                    <label>
                      Phòng ban người đánh giá&nbsp;
                      <span className="text-red-500">(*)</span>
                    </label>
                    <select
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2"
                      value={selectedPhongBanDanhGia.IdPhongBan}
                      onChange={(e: any) => _onChangePhongBanDanhGia(e)}
                    >
                      {phongBanDanhGiaList === null ? (
                        <></>
                      ) : (
                        phongBanDanhGiaList.map((item: any, index: any) => (
                          <option key={index} value={item?.IdPhongBan}>
                            {item?.TenPhongBan}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="w-full space-y-0.5">
                    <label>
                      Người đánh giá&nbsp;
                      <span className="text-red-500">(*)</span>
                    </label>
                    <select
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      value={selectedNguoiDanhGia.Id}
                      onChange={(e: any) => _onChangeNguoiDanhGia(e)}
                      disabled={selectedPhongBanDanhGia === -1 ? true : false}
                    >
                      <option value={selectedNguoiDanhGia.Id} disabled>
                        {selectedNguoiDanhGia.FullName}
                      </option>
                      {selectedNguoiDanhGiaList === null ? (
                        <></>
                      ) : (
                        selectedNguoiDanhGiaList.map(
                          (item: any, index: any) => (
                            <option key={index} value={item?.Id}>
                              {item?.FullName}
                            </option>
                          )
                        )
                      )}
                    </select>
                  </div>
                </>
              )}

              <div className="w-full space-y-4">
                <div className="w-full space-y-1">
                  <div className="flex w-max h-max space-x-3">
                    <label>Người phối hợp (nếu có)</label>
                    <button
                      className={`
                    text-white p-2 rounded-md
                    text-xs
                    ${selectedNguoiNhan === -1
                          ? "bg-slate-300"
                          : "bg-primary hover:bg-sky-400"
                        }
                  `}
                      disabled={selectedNguoiNhan === -1 ? true : false}
                      onClick={() =>
                        _onChangeNguoiPhoiHop("multipleChoicePartner")
                      }
                    >
                      Chọn
                    </button>
                  </div>

                  <div className="w-full h-max">
                    <p className="font-semibold">
                      Hỗ trợ thực hiện / Thời gian hoàn thành
                    </p>

                    {/* Result */}
                    <div className="w-full h-max">
                      <table className="w-full">
                        <thead className="text-white font-semibold text-center w-full h-[2rem]">
                          <tr>
                            <th className="border border-slate-300 text-left">
                              Người thực hiện
                            </th>
                            <th className="border border-slate-300">
                              Số giờ hoàn thành
                            </th>
                            <th className="border border-slate-300">
                              Số phút hoàn thành
                            </th>
                            <th className="border border-slate-300 w-[1rem]"></th>
                          </tr>
                        </thead>
                        <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                          {selectedNguoiLamViecList === null
                            ? ""
                            : selectedNguoiLamViecList?.map(
                              (item: any, index: any) => (
                                <>
                                  {item?.Id === selectedNguoiNhan?.Id ? (
                                    <></>
                                  ) : (
                                    <tr
                                      key={index}
                                      className="w-max h-[3rem] bg-white"
                                    >
                                      <td className="text-left border border-slate-300">
                                        <div
                                          className="flex w-max h-max space-x-1 font-bold mr-2"
                                          style={{
                                            color: `${item?.ColorText}`,
                                          }}
                                        >
                                          <PersonOutlineTwoToneIcon fontSize="small" />
                                          <p>{item?.FullName}</p>
                                        </div>
                                      </td>
                                      <td className="text-center border border-slate-300">
                                        <input
                                          className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                          placeholder="Nhập số giờ"
                                          type="number"
                                          value={
                                            useHourSupporter?.find(
                                              (_item: any) =>
                                                _item?.id === item?.Id
                                            )?.value
                                          }
                                          onChange={(e: any) => {
                                            _onChangeHoursSupporter(
                                              e,
                                              item?.Id
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="text-center border border-slate-300">
                                        <input
                                          className="w-[3rem] h-[1.5rem] rounded-md pl-2 pr-2 border-2 text-black border-slate-200"
                                          placeholder="Nhập số giờ"
                                          type="number"
                                          value={
                                            useMinutesSupporter?.find(
                                              (_item: any) =>
                                                _item?.id === item?.Id
                                            )?.value
                                          }
                                          onChange={(e: any) => {
                                            _onChangeMinutesSupporter(
                                              e,
                                              item?.Id
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="text-left border border-slate-300">
                                        <button
                                          className="text-red-500 justify-end"
                                          onClick={() =>
                                            _onCheckNguoiLamViec(item?.Id)
                                          }
                                        >
                                          <DeleteTwoToneIcon fontSize="small" />
                                        </button>
                                      </td>
                                    </tr>
                                  )}
                                </>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="w-full h-max">
                    <p>Hỗ trợ góp ý</p>
                    {selectedNguoiGopYList === null
                      ? ""
                      : selectedNguoiGopYList.map((item: any, index: any) => (
                        <>
                          {item?.Id === selectedNguoiNhan?.Id ? (
                            <></>
                          ) : (
                            <div
                              key={index}
                              className="flex w-max h-max text-primary items-center justify-between"
                            >
                              <div
                                className="flex w-max h-max space-x-1 font-bold mr-2"
                                style={{
                                  color: `${item?.ColorText}`,
                                }}
                              >
                                <PersonOutlineTwoToneIcon fontSize="small" />
                                <p>{item?.FullName}</p>
                              </div>
                              <button
                                className="text-red-500 justify-end"
                                onClick={() => _onCheckNguoiGopY(item?.Id)}
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </button>
                            </div>
                          )}
                        </>
                      ))}
                  </div>
                </div>
                <div
                  className={`
                flex-col w-full h-max space-y-1
              `}
                >
                  {/* <div
                    className={`${isAutoForce === false ? "hidden" : "flex"
                      } w-max h-max space-x-3`}
                  >
                    <input
                      type="checkbox"
                      checked={isForce}
                      onChange={_onChangeIsForce}
                    />
                    <label className="text-slate-500 font-bold pl-2 pr-2 bg-slate-200">
                      KHÔNG CHO PHÉP TRẢ VIỆC
                    </label>
                  </div> */}
                  <div
                    className={`${isAutoForce === false ? "hidden" : "flex"
                      } w-max h-max space-x-3`}
                  >
                    <input
                      type="checkbox"
                      className="w-5 h-5"
                      checked={isExChange}
                      onChange={() => {
                        if (isExChange === true) {
                          setIsExChange(false);
                        } else {
                          setIsExChange(true);
                        }
                      }}
                      id="isExChange"
                    // checked={isForce}
                    // onChange={_onChangeIsForce}
                    />
                    <label
                      htmlFor="isExChange"
                      className="text-slate-500 hover:cursor-pointer flex justify-center items-center font-bold pl-2 pr-2 bg-slate-200"
                    >
                      CHO PHÉP THOẢ THUẬN THỜI HẠN
                    </label>
                  </div>

                  <div
                    className={`
                        flex-col w-full h-max space-y-1
                      `}
                  >
                    <div
                      className={`${isAutoForce === false ? "hidden" : "flex"
                        } w-max h-max space-x-3`}
                    >
                      <input
                        type="checkbox"
                        className="w-5 h-5"
                        checked={isEvaluate}
                        onChange={() => {
                          if (isEvaluate === true) {
                            setIsEvaluate(false);
                          } else {
                            setIsEvaluate(true);
                          }
                        }}
                        id="IsEvaluate"
                      // checked={isForce}
                      // onChange={_onChangeIsForce}
                      />
                      <label
                        htmlFor="IsEvaluate"
                        className="text-slate-500 hover:cursor-pointer flex justify-center items-center font-bold pl-2 pr-2 bg-slate-200"
                      >
                        CÔNG VIỆC KHÔNG CẦN ĐÁNH GIÁ
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full">
                {/* overload */}
                <div className="flex items-center space-x-1">
                  <CircleIcon className="text-[#00ff00]" />
                  <p>Có thể nhận việc</p>
                </div>
                <div className="flex items-center space-x-1">
                  <CircleIcon className="text-[#FFAA00]" />
                  <p>Đang nhiều việc</p>
                </div>
                <div className="flex items-center space-x-1">
                  <CircleIcon className="text-[#ff0000]" />
                  <p>Quá tải</p>
                </div>
              </div>

              {/* <div className="w-full h-max space-y-0.5">
                <label>Mã tham chiếu công việc</label>
                <input
                  className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập mã"
                  value={refCode}
                  onChange={(e: any) => _onChangeRefCode(e)}
                />
              </div> */}

              <div className="flex flex-col w-full h-max space-y-0.5">
                <QuytrinhJSX />
              </div>

              <div className="flex w-full h-max my-1.5">
                {/* buoc */}
                <StepListJSX />
              </div>
              <div className="flex w-full h-max my-1.5">
                {/* ket qua */}
                <div
                  className={` w-full h-max ${selectedKetQuaList === null ? "flex" : "flex"
                    }`}
                >
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300">STT</th>
                        <th className="border border-slate-300 w-full">
                          Kết quả cần đạt
                        </th>
                        <th className="border text-center border-slate-300 w-[1rem]">
                          <button
                            className="
                  flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                  rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                "
                            onClick={() => _onClickEditKetqua("editKetqua")}
                          >
                            +
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {selectedKetQuaList === null ? (
                        <tr></tr>
                      ) : (
                        selectedKetQuaList?.map((item: any, index: any) => (
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="text-center border border-slate-300">
                              {index + 1}
                            </td>
                            <td className="text-left border border-slate-300">
                              {item?.tenketqua}
                            </td>
                            <td className="border text-center border-slate-300 w-[1rem]">
                              <button className="text-red-500">
                                <DeleteTwoToneIcon fontSize="small" />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>

          <div className="w-full h-max space-y-4">
            {/* elements */}
            <div className="flex flex-col w-full h-max">
              <label>Mô tả</label>
              <textarea
                className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
                placeholder="Nhập mô tả"
                value={description}
                onChange={(e: any) => _onChangeDescription(e)}
              />
            </div>
            <div className="flex flex-col w-full h-max">
              <label>Ghi chú</label>
              <textarea
                className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
                placeholder="Nhập ghi chú"
                value={notes}
                onChange={(e: any) => _onChangeNotes(e)}
              />
            </div>
            <div className="flex w-full h-max">
              {/* elements */}
              <div className="flex flex-col w-1/2 h-max">
                <div className="flex w-max h-full space-x-2 text-xs">
                  <button
                    className="flex items-center p-2 bg-primary rounded-md text-white hover:bg-sky-400 "
                    onClick={_onClickChooseFileChat}
                  >
                    <AttachFileIcon fontSize="small" />
                    {/* <p>Chọn tệp tin</p> */}
                    <p>Chọn tệp tin đính kém</p>
                  </button>
                </div>
                <input
                  ref={uploadFileChat}
                  className={`w-max h-max`}
                  multiple
                  type="file"
                  onChange={(e: any) => _onChangeFileImage(e)}
                  hidden
                />
                {/* fields of file */}
                <div className="flex flex-col w-full h-max">
                  {/* element */}
                  <div className="flex flex-col w-full h-max text-primary items-center justify-between">
                    {/* fileAPI ready */}
                    <div className="flex flex-col w-full h-max">
                      {fileListAPI.length === 0 ||
                        typeof fileListAPI === "undefined" ? (
                        <></>
                      ) : (
                        fileListAPI?.map((item: any, index: any) => (
                          <div key={index} className="flex w-full h-max">
                            <div className="flex w-full h-max space-x-1">
                              <a
                                href={item.listFile}
                                target="_blank"
                                rel="noreferrer"
                                className="flex w-max h-max space-x-1"
                              >
                                <InsertDriveFileTwoToneIcon fontSize="small" />
                                <p>{item?.listFileName}</p>
                              </a>

                              <div className="w-max h-max flex space-x-6">
                                <button
                                  className="text-green-500 w-1/6 flex"
                                  onClick={() =>
                                    _onClickEditFile(
                                      index,
                                      filesList,
                                      setFilesList
                                    )
                                  }
                                >
                                  <EditIcon fontSize="small" />
                                </button>

                                <button
                                  className="text-red-500 w-1/6 flex"
                                  onClick={() => _onDeleteElements(index)}
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>

                    <div className="flex flex-col w-full h-max">
                      {filesList.length === 0 ||
                        typeof filesList === "undefined" ? (
                        <></>
                      ) : (
                        filesList.map((item: any, index: any) => (
                          <div key={index} className="flex w-full h-max">
                            <div className="flex w-full h-max space-x-1">
                              <InsertDriveFileTwoToneIcon fontSize="small" />
                              <p>{item?.name}</p>

                              <div className="w-max h-max flex space-x-6">
                                <button
                                  className="text-green-500 w-1/6 flex"
                                  onClick={() =>
                                    _onClickEditFile(
                                      index,
                                      filesList,
                                      setFilesList
                                    )
                                  }
                                >
                                  <EditIcon fontSize="small" />
                                </button>

                                <button
                                  className="text-red-500 w-1/6 flex"
                                  onClick={() => _onDeleteElements(index)}
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </button>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max z-[70] gap-3 flex-wrap justify-end">
          {urlLink === "/working/task-total" && taskStatus !== 9 ? (
            <></>
          ) : (
            <>
              {/* DESKTOP */}

              {_isTransfer ? (
                <>
                  <button
                    className="
                        flex items-center space-x-1 w-max h-max p-2 bg-rose-500 text-white rounded-md
                        hover:bg-slate-200
                        "
                    onClick={onOpenRecoveryTask}
                  >
                    {/* detail - contain */}
                    <div className="flex w-full h-full space-x-1">
                      {/* icon */}
                      <RedoIcon sx={{ fontSize: 18 }} fontSize="small" />
                      {/* title */}
                      <div>{taskStatus == 9 ? 'Chuyển giao' : 'Thu hồi và chuyển giao'}  </div>
                    </div>
                  </button>

                </>
              ) : (
                <>
                  {/* button options */}
                  {/* <button
                      className={`flex items-center space-x-1 w-max h-max p-2 text-white rounded-md ${
                        selectedPhongBan === -1 ||
                        selectedNguoiNhan === -1 ||
                        selectedLoaiViec === -1 ||
                        selectedNguonVanBan === -1 ||
                        selectedUuTien === -1 ||
                        selectedDoMat === -1 ||
                        selectedDauViec === -1 ||
                        selectedDoKho === -1 ||
                        deadLine === "" ||
                        taskName === "" ||
                        _onCheckValid() === false ||
                        isLoading === true
                          ? // || selectedQuyTrinh === -1 ||
                            // isSelectedBuocOK === false
                            "bg-slate-300"
                          : "bg-primary hover:bg-sky-400"
                      }`}
                      disabled={
                        selectedPhongBan === -1 ||
                        selectedNguoiNhan === -1 ||
                        selectedLoaiViec === -1 ||
                        selectedNguonVanBan === -1 ||
                        selectedUuTien === -1 ||
                        selectedDoMat === -1 ||
                        selectedDauViec === -1 ||
                        selectedDoKho === -1 ||
                        deadLine === "" ||
                        taskName === "" ||
                        _onCheckValid() === false ||
                        isLoading === true
                          ? // || selectedQuyTrinh === -1 ||
                            // isSelectedBuocOK === false
                            true
                          : false
                      }
                    >
                      <AssignmentIcon fontSize="small" />
                      <p>Thao tác123</p>
                    </button> */}
                  {_isTransfer ? (
                    <></>
                  ) : (
                    <>
                      <button
                        className="flex items-center space-x-1 w-max h-max p-2 text-white rounded-md bg-red-500 hover:bg-red-600"
                        onClick={() => _onClickEditBuoc("deleteTask")}
                      >
                        {/* delete - contain */}
                        <div className="flex w-full space-x-1">
                          {/* icon */}
                          <DeleteTwoToneIcon
                            className=""
                            sx={{ fontSize: 18 }}
                            fontSize="small"
                          />
                          {/* title */}
                          <div>Xoá</div>
                        </div>
                      </button>
                      {/* delitask */}
                      <button
                        className="flex items-center space-x-1 w-max h-max p-2 text-white rounded-md bg-emerald-500 hover:bg-emerald-600"
                        onClick={() => {
                          _onClickEditBuoc("saveAndGiveTask");
                        }}
                      >
                        {/* detail - contain */}
                        <div className="flex w-full h-full space-x-1">
                          {/* icon */}
                          <SendTwoToneIcon
                            className=""
                            sx={{ fontSize: 18 }}
                            fontSize="small"
                          />
                          {/* title */}
                          <div>Giao việc</div>
                        </div>
                      </button>
                      {/* tranf boss */}

                      <button
                        className={`flex items-center space-x-1 w-max h-max p-2 text-white rounded-md bg-amber-500 hover:bg-amber-600 ${urlLink === "/working/task-need-give"
                          ? "hidden"
                          : "flex"
                          }
                      `}
                        onClick={_onClickSwitchToBoss}
                      >
                        {/* detail - contain */}
                        <div className="flex w-full h-full space-x-1">
                          {/* icon */}
                          <CameraFrontTwoToneIcon
                            className=""
                            sx={{ fontSize: 18 }}
                            fontSize="small"
                          />
                          {/* title */}
                          <div>Chuyển sếp</div>
                        </div>
                      </button>

                      {/* detail */}
                      <button
                        className="flex items-center space-x-1 w-max h-max p-2 text-white rounded-md bg-sky-500 hover:bg-sky-600"
                        onClick={() => _onClickUpdate()}
                      >
                        {/* detail - contain */}
                        <div className="flex w-full h-full space-x-1">
                          {/* icon */}
                          <DescriptionTwoToneIcon
                            className=""
                            sx={{ fontSize: 18 }}
                            fontSize="small"
                          />
                          {/* title */}
                          <div>Lưu chỉnh sửa</div>
                        </div>
                      </button>
                    </>
                  )}
                  {isRecall() && renderMenuRecall()}
                  {isTransfer() && renderMenuTransfer()}
                  {/* options fields */}
                </>
              )}
              {/* END DESKTOP*/}
              {/* MOBILE */}
              {/* <div className="lg:hidden flex justify-end items-center">
                <div className="dropdown2">
                  
                  <button
                    style={{ width: "100%", height: "100%" }}
                    className="dropbtn2"
                    onClick={() => _onClickFuncs("menuCon")}
                  >
                    <AssignmentIcon fontSize="small" />
                    <p>Thao tác</p>
                  </button>


                  <div
                    id={"menuCon"}
                    className="dropdown-content2 text-xs font-semibold"
                  >
                    {_isTransfer ? (
                      <>
                        {urlLink == "/working/task-recovery" ? (
                          <button
                            className="flex w-full h-full hover:text-sky-500"
                            // onClick={onOpenRecoveryTask}
                          >

                            <div className="flex w-full h-full space-x-1">

                              <RedoIcon
                                className="text-sky-600"
                                sx={{ fontSize: 18 }}
                                fontSize="small"
                              />
                              <div>Chuyển giao</div>
                            </div>
                          </button>
                        ) : (
                          <button
                            className="flex w-full h-full hover:text-sky-500"
                            onClick={onOpenRecoveryTask}
                          >
                            <div className="flex w-full h-full space-x-1">
                              <RedoIcon
                                className="text-sky-600"
                                sx={{ fontSize: 18 }}
                                fontSize="small"
                              />
                              <div>Thu hồi và chuyển giao</div>
                            </div>
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        <button
                          className="flex w-full h-full hover:text-red-500"
                          onClick={() => _onClickEditBuoc("deleteTask")}
                        >

                          <div className="flex w-full space-x-1">

                            <DeleteTwoToneIcon
                              className="text-red-600"
                              sx={{ fontSize: 18 }}
                              fontSize="small"
                            />

                            <div className="h-full">Xoá</div>
                          </div>
                        </button>

                        <button
                          className="flex w-full h-full hover:text-emerald-500"
                          onClick={() => _onClickEditBuoc("giveTask")}
                        >

                          <div className="flex w-full h-full space-x-1">
                            <SendTwoToneIcon
                              className="text-emerald-600"
                              sx={{ fontSize: 18 }}
                              fontSize="small"
                            />

                            <div>Giao việc</div>
                          </div>
                        </button>


                        <div
                          className={`${
                            urlLink === "/working/task-need-give"
                              ? "hidden"
                              : "flex"
                          }`}
                        >
                          <button
                            className={`w-full h-full hover:text-amber-500
                        hidden
                      `}
                            onClick={_onClickSwitchToBoss}
                          >

                            <div className="flex w-full h-full space-x-1">

                              <CameraFrontTwoToneIcon
                                className="text-amber-600"
                                sx={{ fontSize: 18 }}
                                fontSize="small"
                              />

                              <div>Chuyển sếp</div>
                            </div>
                          </button>
                        </div>

                        <button
                          className="flex w-full h-full hover:text-sky-500"
                          onClick={_onClickUpdate}
                        >
                          <div className="flex w-full h-full space-x-1">
                            <DescriptionTwoToneIcon
                              className="text-sky-600"
                              sx={{ fontSize: 18 }}
                              fontSize="small"
                            />
                            <div>Lưu chỉnh sửa</div>
                          </div>
                        </button>
                      </>
                    )}
                    {isRecall() && renderMenuRecall()}
                    {isTransfer() && renderMenuTransfer()}
                  </div>
                </div>
              </div> */}
              {/* END MOBILE */}
            </>
          )}

          <button
            className="
              flex items-center w-max h-max p-2 bg-slate-300 text-white rounded-md
              hover:bg-slate-200
            "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};
export default EditTask;
