import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ONCHANGE_DATE,
  ONCHANGE_SELECTED_BOSS,
  ONCHANGE_FILTER_SELECTED_PHONGBAN,
  ONCHANGE_FROM_HOME_TO_PROCESSING,
} from "../redux/reducers/workingReducer/_index";

import WorkingInOtherJSX from "./contentBottom/WorkingInOtherJSX";
import WorkingUpdateDelivery from "./contentBottom/WorkingUpdateDelivery";
import WorkingNewTaskJSX from "./contentBottom/WorkingNewTaskJSX";
import WorkingTaskFinishJSX from "./contentBottom/WorkingReceiverBottomContent";

import moment from "moment";

import { onTouchForm } from "../funcs/onTochForm";

import Select from "react-select";

import WorkingTotalTaskOfBossJSX from "./contentBottom/WorkingTotalTaskOfBossJSX";
import WorkingReceiverTaskWaiting from "./contentBottom/WorkingReceiverTaskWaiting";
import DateTimePickerControl from "../controls/datetimePicker/datetimePickerControl";
import WorkingReceiverAllJSX from "./contentBottom/WorkingReceiverAllJSX";
import { NAVIGATION_KEYS } from "../constants/constants";
import WorkingReceiverTaskMyself from "./contentBottom/WorkingReceiverTaskMyself";

const ContentBottom = () => {
  const urlLink = window.location.pathname;

  // redux
  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  const [fromDate, setFromDate] = useState<string>(
    workingData.searchFilter.fromDate === ""
      ? new Date(new Date().getFullYear(), new Date().getMonth(), 1, 7, 0, 0)
          .toISOString()
          .slice(0, 16)
      : workingData.searchFilter.fromDate
  );

  const [toDate, setToDate] = useState<string>(
    workingData.searchFilter.toDate === ""
      ? new Date(
          new Date().getTime() + new Date().getTimezoneOffset() * -60 * 1000
        )
          .toISOString()
          .slice(0, 16)
      : workingData.searchFilter.toDate
  );

  // useEffect(() => {
  //   setFromDate(moment(workingData.searchFilter.fromDate).format("DD/MM/YYYY"))
  // }, [workingData.searchFilter.fromDate])

  const tempReceiverList = workingData.receiverList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });
  const tempBossList = workingData.bossList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });
  const tempPhongBan = workingData.listPhongBan?.map((item: any) => {
    return { ...item, label: item.ParameterValue, value: item.ParameterValue };
  });

  // jsx
  // const WorkingNewTaskJSX = () => {
  //   return (
  //     <div className="flex lg:flex-row flex-col w-full h-max">
  //       <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
  //         <label>Ngày giao (Từ ngày)</label>
  //         <input
  //           className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
  //           type="datetime-local"
  //           value={fromDate}
  //           onChange={(e: any) => {
  //             setFromDate(e.target.value);
  //             dispatch(
  //               ONCHANGE_DATE({ type: "fromDate", value: e.target.value })
  //             );
  //           }}
  //         // onTouchStart={(e: any) => onTouchForm(e)}
  //         />
  //       </div>
  //       <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
  //         <label>Ngày giao (Đến ngày)</label>
  //         <input
  //           className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
  //           type="datetime-local"
  //           value={toDate}
  //           onChange={(e: any) => {
  //             setToDate(e.target.value);
  //             dispatch(
  //               ONCHANGE_DATE({
  //                 type: "toDate",
  //                 value: e.target.value || toDate,
  //               })
  //             );
  //           }}
  //         />
  //       </div>

  //       <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
  //         <label>Thời hạn</label>
  //         <input
  //           className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
  //           placeholder="Thời hạn"
  //           type="datetime-local"
  //           value={workingData.searchFilter.deadLine}
  //           onChange={(e: any) =>
  //             dispatch(
  //               ONCHANGE_DATE({ type: "deadLine", value: e.target.value })
  //             )
  //           }
  //           onTouchStart={(e: any) => onTouchForm(e)}
  //         />
  //       </div>

  //       {/* <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
  //         <label>Phòng ban</label>
  //         <Select
  //           className="z-[5]"
  //           options={tempPhongBan}
  //           onChange={(data: any) => dispatch(ONCHANGE_FILTER_SELECTED_PHONGBAN(data))}
  //           isClearable
  //           placeholder="--- Chọn phòng ban ---"
  //           components={{
  //             DropdownIndicator: () => null,
  //             IndicatorSeparator: () => null,
  //           }}
  //         />
  //       </div> */}
  //     </div>
  //   );
  // };

  // const WorkingTaskFinishJSX = () => {
  //   return (
  //     <div className="flex lg:flex-row flex-col w-full h-max">
  //       <div className="flex lg:flex-row flex-col w-full h-max">
  //         <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
  //           <label>Ngày giao (Từ ngày)</label>
  //           <input
  //             className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
  //             type="datetime-local"
  //             value={fromDate}
  //             onChange={(e: any) => {
  //               setFromDate(e.target.value);
  //               dispatch(
  //                 ONCHANGE_DATE({ type: "fromDate", value: e.target.value })
  //               );
  //             }}
  //           // onTouchStart={(e: any) => onTouchForm(e)}
  //           />
  //         </div>

  //         <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
  //           <label>Ngày giao (Đến ngày)</label>
  //           <input
  //             className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
  //             type="datetime-local"
  //             value={toDate}
  //             onChange={(e: any) => {
  //               setToDate(e.target.value);
  //               dispatch(
  //                 ONCHANGE_DATE({
  //                   type: "toDate",
  //                   value: e.target.value || toDate,
  //                 })
  //               );
  //             }}
  //           />
  //         </div>

  //         <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
  //           <label>Thời hạn</label>
  //           <input
  //             className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
  //             placeholder="Thời hạn"
  //             type="datetime-local"
  //             value={workingData.searchFilter.deadLine}
  //             onChange={(e: any) =>
  //               dispatch(
  //                 ONCHANGE_DATE({ type: "deadLine", value: e.target.value })
  //               )
  //             }
  //             onTouchStart={(e: any) => onTouchForm(e)}
  //           />
  //         </div>

  //         <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
  //           <label>Phòng ban</label>
  //           <Select
  //             className="z-[5]"
  //             options={tempPhongBan}
  //             onChange={(data: any) => dispatch(ONCHANGE_FILTER_SELECTED_PHONGBAN(data))}
  //             isClearable
  //             placeholder="--- Chọn phòng ban ---"
  //             components={{
  //               DropdownIndicator: () => null,
  //               IndicatorSeparator: () => null,
  //             }}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const WorkingUpdateDelivery = () => {
  //   return (
  //     <div className="flex lg:flex-row flex-col w-full h-max">
  //       <div className="flex lg:flex-row flex-col w-full h-max">

  //         <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
  //           <label>Ngày giao (Từ ngày)</label>
  //           <input
  //             className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
  //             type="datetime-local"
  //             value={workingData.searchFilter.fromDate}
  //             onChange={(e: any) => {
  //               dispatch(
  //                 ONCHANGE_DATE({ type: "fromDate", value: e.target.value })
  //               );
  //             }}
  //           // onTouchStart={(e: any) => onTouchForm(e)}
  //           />
  //         </div>

  //         <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
  //           <label>Ngày giao (Đến ngày)</label>
  //           <input
  //             className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
  //             type="datetime-local"
  //             value={toDate}
  //             onChange={(e: any) => {
  //               setToDate(e.target.value);
  //               dispatch(
  //                 ONCHANGE_DATE({
  //                   type: "toDate",
  //                   value: e.target.value || toDate,
  //                 })
  //               );
  //             }}
  //           />
  //         </div>

  //         <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
  //           <label>Thời hạn</label>
  //           <input
  //             className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
  //             placeholder="Thời hạn"
  //             type="datetime-local"
  //             value={workingData.searchFilter.deadLine}
  //             onChange={(e: any) =>
  //               dispatch(
  //                 ONCHANGE_DATE({ type: "deadLine", value: e.target.value })
  //               )
  //             }
  //             onTouchStart={(e: any) => onTouchForm(e)}
  //           />
  //         </div>

  //         <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
  //           <label>Phòng ban</label>
  //           <Select
  //             className="z-[5]"
  //             options={tempPhongBan}
  //             onChange={(data: any) => dispatch(ONCHANGE_FILTER_SELECTED_PHONGBAN(data))}
  //             isClearable
  //             placeholder="--- Chọn phòng ban ---"
  //             components={{
  //               DropdownIndicator: () => null,
  //               IndicatorSeparator: () => null,
  //             }}
  //           />
  //         </div>

  //       </div>
  //     </div>
  //   );
  // }

  const WorkingUpdateReceiver = () => {
    return (
      <div className="flex lg:flex-row flex-col w-full h-max">
        <div className="flex lg:flex-row flex-col w-full h-max gap-2 lg:gap-5">
          {/* Elements */}
          {/* <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
                        <label>Ngày giao (Từ ngày)</label>
                        <input
                            className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                            type="datetime-local"
                            value={workingData.searchFilter.fromDate}
                            onChange={(e: any) => {
                                dispatch(
                                    ONCHANGE_DATE({ type: "fromDate", value: e.target.value })
                                );
                            }}
                        // onTouchStart={(e: any) => onTouchForm(e)}
                        />
                    </div> */}

          <DateTimePickerControl
            label={"Ngày giao (Từ ngày)"}
            value={workingData.searchFilter.fromDate}
            onChange={(e: any) => {
              const value = e;

              dispatch(ONCHANGE_DATE({ type: "fromDate", value }));
            }}
          />

          <DateTimePickerControl
            label={"Ngày giao (Đến ngày)"}
            value={workingData.searchFilter.toDate}
            onChange={(e: any) => {
              const value = e;
              dispatch(
                ONCHANGE_DATE({
                  type: "toDate",
                  value: value,
                })
              );
            }}
          />

          {/* <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
                        <label>Ngày giao (Đến ngày)</label>
                        <input
                            className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                            type="datetime-local"
                            value={toDate}
                            onChange={(e: any) => {
                                setToDate(e.target.value);
                                dispatch(
                                    ONCHANGE_DATE({
                                        type: "toDate",
                                        value: e.target.value || toDate,
                                    })
                                );
                            }}
                        />
                    </div> */}

          <DateTimePickerControl
            label={"Thời hạn"}
            value={workingData.searchFilter.deadLine}
            onChange={(e: any) => {
              const value = e;

              dispatch(ONCHANGE_DATE({ type: "deadLine", value: value }));
            }}
          />

          {/* <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
                        <label>Thời hạn</label>
                        <input
                            className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                            placeholder="Thời hạn"
                            type="date"
                            value={workingData.searchFilter.deadLine}
                            onChange={(e: any) =>
                                dispatch(
                                    ONCHANGE_DATE({ type: "deadLine", value: e.target.value })
                                )
                            }
                            onTouchStart={(e: any) => onTouchForm(e)}
                        />
                    </div> */}
          {/* thangdong20230927 */}
          {/* <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
                        <label>Người giao</label>
                        <Select
                            className="z-[5]"
                            options={tempBossList}
                            onChange={(data: any) => dispatch(ONCHANGE_SELECTED_BOSS(data))}
                            isClearable
                            placeholder="--- Chọn người giao ---"
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                            }}
                        />
                    </div> */}
        </div>
      </div>
    );
  };

  return (
    <>
      {urlLink === "/report/deliver/task-general-deliver" ? (
        <WorkingTaskFinishJSX />
      ) : urlLink === "/working/receiver/task-processing" ||
        urlLink === "/working/receiver/task-delay" ||
        urlLink === "/working/receiver/task-returnwork" ||
        urlLink === "/working/receiver/task-finish_early" ||
        urlLink === "/working/receiver/task-finish_puntual" ||
        urlLink === "/working/receiver/task-share" ||
        urlLink === "/working/receiver/task-finish_late" ||
        urlLink === "/working/receiver/all-task" ? (
        <WorkingReceiverAllJSX />
      ) : urlLink === "/working/receiver/task-waiting" ? (
        <WorkingReceiverTaskWaiting />
      ) : urlLink === "/working/new-task" ||
        urlLink === "/working/task-finish" ||
        urlLink === "/working/task-need-give" ? (
        <WorkingUpdateDelivery />
      ) : urlLink === "/working/receiver/task-new" ||
        urlLink === "/working/receiver/task-received" ? (
        <WorkingUpdateReceiver />
      ) : urlLink === "/working/task-totalall" ? (
        <WorkingTotalTaskOfBossJSX />
      ) : urlLink === "/working/task-total" ? (
        <WorkingTaskFinishJSX />
      ) : urlLink === "/working/task-proccessing" ? (
        <WorkingInOtherJSX />
      ) : urlLink === "/working/receiver/task-myself" ? (
        <WorkingReceiverTaskMyself />
      ) : (
        <WorkingNewTaskJSX />
      )}
    </>
  );
};

export default ContentBottom;
