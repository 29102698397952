import Title from "../../components/title";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useEffect, useState, useRef, useCallback } from "react";
import ServiceUtils from "../../utils/services";
import { GET_CALENDAR_DATA, USER_READ_ALL } from "../../apis/_index";
import { toast } from "react-toastify";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { CustomToolbar } from "./components";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { Calendar as BigCalendar } from "react-big-calendar";
interface Event {
  event_id?: string;
  start: Date;
  end: Date;
  title: string;
  type: number;
}

const DnDCalendar = withDragAndDrop<Event>(BigCalendar);
moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const ScheduleUI = () => {
  const options = [
    { value: "1", label: "Công việc" },
    { value: "2", label: "Công tác" },
    { value: "3", label: "Ngày lễ" },
    { value: "4", label: "Ngày nghỉ" },
  ];
  const animatedComponents = makeAnimated();
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [userList, setUserList] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [fromDate, setFromDate] = useState<any>(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 7, 0, 0)
      .toISOString()
      .slice(0, 24)
  );
  const [toDate, setToDate] = useState<any>(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1, 7, 0, 0)
      .toISOString()
      .slice(0, 24)
  );

  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await ServiceUtils.getV2(USER_READ_ALL);
        if (res.data.StatusCode === 200) {
          const users = res.data.Data;
          const convertedUserOptions = users.map((user: any) => ({
            label: user.FullName,
            value: user.Id,
          }));
          setUserList(convertedUserOptions);
        }
      } catch {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    fetchUser();
  }, []);

  const fetchScheduleData = async () => {
    let text = "";
    if (selectedOptions.length === 0) {
      text = "type=0";
    } else {
      selectedOptions.map((item: any) => {
        text += "type=" + item.value + "&";
      });
      text = text.slice(0, -1);
    }
    let text2 = "";
    if (selectedUser) {
      text2 = "&employee=" + selectedUser.value;
    } else {
      text2 = "";
    }

    let url = `${GET_CALENDAR_DATA}?start=${fromDate}&end=${toDate}&${text}${text2}`;

    const res = await ServiceUtils.getV2(url);
    try {
      if (res.data.StatusCode === 200) {
        const data = res.data.Data;
        const processedEventsArray = data.map((event: any) =>
          processedEvents(event)
        );
        setEvents(processedEventsArray);
      }
    } catch {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };
  useEffect(() => {
    fetchScheduleData();
  }, [fromDate, toDate, selectedUser, selectedOptions]);

  const handleCategoryChange = (selectedValues: any) => {
    setSelectedOptions(selectedValues);
    
  };

  // const _onFilterCalendar = async () => {
  //   if (!selectedUser) {
  //     toast.error("Vui lòng chọn cán bộ!");
  //     return;
  //   }
  //   if (selectedOptions.length == 0) {
  //     toast.error("Vui lòng chọn danh mục!");
  //     return;
  //   }

  //   fetchScheduleData();
  // };

  const processedEvents = (event: any) => {
    return {
      event_id: event.eventId,
      title: event.title,
      start: new Date(event.start),
      end: new Date(event.end),
      type: event.type,
    };
  };

  const handleSelect = ({ start, end }: { start: Date; end: Date }) => {
    const title = window.prompt("New Event name");
    const type = 2;
    if (title) {
      const newEvent: Event = {
        start,
        end,
        title,
        type,
      };
      setEvents([...events, newEvent]);
    }
  };

  const handleEventDrop = ({ event, start, end }: any) => {
    const updatedEvents = events.map((e: any) =>
      e.event_id === event.event_id
        ? { ...e, start: new Date(start), end: new Date(end) }
        : e
    );

    setEvents(updatedEvents);

    // You can perform additional actions here, such as making an API call to update the event on the server
    console.log("Event dropped:", event);
    console.log("New start date:", start);
    console.log("New end date:", end);
  };

  // const onChangeEventTime = useCallback(
  //   ({
  //     event,
  //     start,
  //     end,
  //     event_id,
  //   }: {
  //     event: Event;
  //     start: stringOrDate;
  //     end: stringOrDate;
  //     event_id: number;
  //   }) => {
  //     setEvents((prevEvents) =>
  //       prevEvents.map((prevEvent) =>
  //         prevEvent?.data?.appointment?.id === event?.data?.appointment?.id
  //           ? { ...event, start, end, resourceId }
  //           : prevEvent
  //       )
  //     );
  //   },
  //   []
  // );

  // const onDroppedFromOutside = useCallback(
  //   ({
  //     start,
  //     end,
  //     resource,
  //   }: {
  //     start: stringOrDate;
  //     end: stringOrDate;
  //     resource: number;
  //   }) => {
  //     if (draggedEvent === "undroppable") return;
  //     setEvents((prevEvents) => [
  //       ...prevEvents,
  //       {
  //         start,
  //         end,
  //         resourceId: resource,
  //         data: { appointment: draggedEvent },
  //         isDraggable: true,
  //         isResizable: true,
  //       },
  //     ]);
  //   },
  //   [draggedEvent]
  // );

  const calendarRef = useRef<any>(null);

  const [clientWidthOptionListScroll, setClientWidthOptionListScroll] =
    useState<any>(0);
  const [clientWidthNavbar, setClientWidthNavbar] = useState<any>(0);
  const scheduleRef = useRef<any>(null);

  useEffect(() => {
    if (scheduleRef.current) {
      scheduleRef.current.scrollLeft = scheduleRef.current.scrollWidth;
    }
  }, []);

  useEffect(() => {
    const optionListScroll = document.getElementById("optionListScroll");

    setClientWidthOptionListScroll(optionListScroll?.clientWidth ?? 0);

    let windowWidth = window.visualViewport?.width ?? window.innerWidth;

    const clientWidthNavbar = calculatorWidthNavbar(windowWidth);

    setClientWidthNavbar(clientWidthNavbar);

    window.addEventListener("resize", () => {
      windowWidth = window.visualViewport?.width ?? window.innerWidth;

      const clientWidthNavbar = calculatorWidthNavbar(windowWidth);

      setClientWidthNavbar(clientWidthNavbar);

      const optionListScroll = document.getElementById("optionListScroll");

      setClientWidthOptionListScroll(optionListScroll?.clientWidth ?? 0);
    });
  }, []);

  const calculatorWidthNavbar = (windowWidth: any) => {
    const navbar = document.getElementById("navbar");
    const navbar2 = document.getElementById("navbar2");

    const isMobile = windowWidth <= 1024 ? true : false;

    let clientWidthNavbar = 0;

    if (navbar && !isMobile) {
      clientWidthNavbar = navbar.clientWidth ?? 0;
    }

    if (navbar2 && isMobile) {
      clientWidthNavbar = navbar2.clientWidth ?? 0;
    }

    return clientWidthNavbar;
  };

  const isSmallScreen = window.innerWidth < 500;

  return (
    <div className="relative">
      <Title />
      <div className="px-3 pt-0 pb-6 text-sm lg:flex gap-5 ">
        <div className="lg:w-1/3 w-full">
          <label>Chọn cán bộ:</label>
          <Select
            className="z-[101] w-full mt-1"
            options={userList}
            onChange={(data: any) => {
              setSelectedUser(data);
            }}
            value={selectedUser}
            isClearable
            placeholder="--- Chọn người nhận ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        <div className="lg:w-1/3 w-full">
          <label>Chọn danh mục:</label>
          <Select
            className="z-[100] w-full mt-1"
            closeMenuOnSelect={true}
            components={animatedComponents}
            placeholder={"-----Chọn danh mục-----"}
            value={selectedOptions}
            options={options}
            isClearable={true}
            isSearchable={true}
            isMulti={true}
            onChange={handleCategoryChange}
          />
        </div>

        {/* <button
          className="lg:mt-[23px] mt-2 lg:w-max w-full
                flex items-center h-max p-2 bg-blue-500 rounded-md text-white
                hover:bg-blue-400 active:shadow-inner active:shadow-slate-500 justify-center core-button-resize
              "
          onClick={_onFilterCalendar}
        >
          <p>Tìm kiếm</p>
        </button> */}
      </div>
      <div
        style={{
          width: isSmallScreen
            ? `calc(100vw - ${
                clientWidthOptionListScroll + clientWidthNavbar
              }px)`
            : undefined,
        }}
        className="lg:px-3  w-full mx-auto relative"
      >
        <DnDCalendar
          ref={calendarRef}
          views={["day", "week", "month"]}
          selectable
          localizer={localizer}
          defaultDate={new Date()}
          defaultView="month"
          events={events}
          style={{ height: "100vh" }}
          onSelectEvent={(event: Event) => alert(event.title)}
          onSelectSlot={handleSelect}
          onEventDrop={handleEventDrop}
          eventPropGetter={(event: Event) => {
            let color;

            switch (event.type) {
              case 1:
                color = "blue";
                break;
              case 2:
                color = "green";
                break;
              case 3:
                color = "yellow";
                break;
              case 4:
                color = "red";
                break;
              default:
                color = "gray";
            }

            const style = {
              backgroundColor: color,
              color: "white",
              borderRadius: "5px",
              border: "1px solid #ddd",
              display: "block",
            };

            return {
              style,
            };
          }}
          // draggableAccessor={(event) => !!event.isDraggable}
          // resizableAccessor={"isResizable"}
          // onEventResize={onChangeEventTime}
          // onDropFromOutside={onDroppedFromOutside}
          components={{
            toolbar: (props: any) => (
              <CustomToolbar
                {...props}
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
              />
            ),
          }}
        />
      </div>
    </div>
  );
};
export default ScheduleUI;
