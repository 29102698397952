import axios from "axios";
import { toast } from "react-toastify";

import { LOAD_SEP } from "../apis/_index";
import {
  USER_READ_ALL_DROPDOWN,
  PRIOR_LIST,
  PRIOR_LIST_DROPDOWN,
  TAKE_PARAMETER_LISTKEY_DROPDOWN,
} from "../apis/_index";
import { NAVIGATION_KEYS } from "../constants/constants";
import ServiceUtils from "../utils/services";

export const readList = (
  api: any,
  page: number,
  limit: number,
  filterTaskName: any,
  filterSelectedPrior: any,
  filterSelectedReceiver: any,
  filterFromDate: any,
  filterToDate: any,
  filterDeadLine: any,
  shouldLog: any,
  link: string,
  callback: (res: any) => void,
  filterStatus?: any,
  bossSearch?: any,
  resultSearch?: any,
  phongBan?: any
) => {
  const taskName = filterTaskName === "" ? null : filterTaskName;

  const searchPhongBan = phongBan === "" ? null : phongBan;
  const selectedPrior = filterSelectedPrior === -1 ? null : filterSelectedPrior;
  const selectedReceiver =
    filterSelectedReceiver === -1 ? null : filterSelectedReceiver;

  const fromDate =
    filterFromDate === ""
      ? new Date(new Date().getFullYear(), new Date().getMonth(), 1, 7, 0, 0)
          .toISOString()
          .slice(0, 16)
      : filterFromDate;

  const toDate =
    filterToDate === ""
      ? new Date(
          new Date().getTime() + new Date().getTimezoneOffset() * -60 * 1000
        )
          .toISOString()
          .slice(0, 16)
      : filterToDate;

  const deadLine = filterDeadLine === "" ? null : filterDeadLine;

  let userData: any = localStorage.getItem("userData");
  userData = userData.replace(/'/g, '"'); //replacing all ' with "
  userData = JSON.parse(userData);

  const userId = userData.Data[0].Id;

  const _checkBossSearch = () => {
    if (bossSearch === -1) {
      return "";
    } else {
      return bossSearch;
    }
  };

  let body: any = {
    Page: page,
    Limit: limit,
    TaskName: taskName,
    PriorityLevelID: selectedPrior,
    Recipient: selectedReceiver,

    DateAdd_From:
      filterFromDate === "" &&
      (link === "/working/receiver/task-new" ||
        link === "/working/receiver/task-received" ||
        link === "/working/receiver/task-waiting")
        ? null
        : fromDate,
    DateAdd_To:
      filterToDate === "" &&
      (link === "/working/receiver/task-new" ||
        link === "/working/receiver/task-received" ||
        link === "/working/receiver/task-waiting")
        ? null
        : toDate,

    Deadline: deadLine,
    TaskOwner: _checkBossSearch(),
  };

  let body2: any = {
    Page: page,
    Limit: limit,
    TaskName: taskName,
    PriorityLevelID: selectedPrior,
    Recipient: selectedReceiver,
    DateAdd_From:
      filterFromDate === "" &&
      (link === "/working/new-task" ||
        link === "/working/task-change-deadline" ||
        link === "/working/task-finish" ||
        link === "/working/task-need-give" ||
        link == NAVIGATION_KEYS.recoveryTask ||
        link == NAVIGATION_KEYS.revokeTask ||
        link == NAVIGATION_KEYS.settingProceduce)
        ? null
        : fromDate,

    DateAdd_To:
      filterToDate === "" &&
      (link === "/working/new-task" ||
        link === "/working/task-change-deadline" ||
        link === "/working/task-finish" ||
        link === "/working/task-need-give" ||
        link == NAVIGATION_KEYS.recoveryTask ||
        link == NAVIGATION_KEYS.revokeTask ||
        link == NAVIGATION_KEYS.settingProceduce)
        ? null
        : toDate,
    Deadline: deadLine,
    TaskOwner: userId,
    PhongBan: searchPhongBan,
  };

  let body3: any = {
    Page: page,
    Limit: limit,
    TaskName: taskName,
    PriorityLevelID: selectedPrior,
    Recipient: selectedReceiver,
    DateAdd_From: fromDate,
    DateAdd_To: toDate,
    Deadline: deadLine,
    TaskOwner: _checkBossSearch(),
  };

  console.log(body2);

  if (link === "/working/task-proposed") {
    if (shouldLog.current) {
      let params: any = {
        TaskName: taskName,
        PriorityLevelID: selectedPrior,
        Page: page,
        Limit: limit,
        FromDate: fromDate,
        ToDate: toDate,
        Deadline: deadLine,
        PhongBan: searchPhongBan,
        Recipient: selectedReceiver,
      };
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, params, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/new-task") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body2, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/task-need-give") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body2, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/task-delay") {
    if (shouldLog.current) {
      const link = window.location.pathname;

      body2 = {
        Page: page,
        Limit: limit,
        TaskName: taskName,
        PriorityLevelID: selectedPrior,
        Recipient: selectedReceiver,
        DateAdd_From:
          filterFromDate === "" &&
          (link === "/working/new-task" ||
            link === "/working/task-change-deadline" ||
            link === "/working/task-finish" ||
            link === "/working/task-need-give")
            ? null
            : fromDate,

        DateAdd_To:
          filterToDate === "" &&
          (link === "/working/new-task" ||
            link === "/working/task-change-deadline" ||
            link === "/working/task-finish" ||
            link === "/working/task-need-give")
            ? null
            : toDate,
        Deadline: deadLine,
        TaskOwner: userId,
        PhongBan: phongBan,
      };

      if (filterStatus === 0 || filterStatus === undefined) {
        delete body2.TaskStatus;
      }

      if (resultSearch === 0 || resultSearch === undefined) {
        delete body2.Result;
      }

      if (phongBan === "" || phongBan === undefined) {
        delete body2.PhongBan;
      }

      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body2, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/task-change-deadline") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body2, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (
    link === "/working/receiver/task-returnwork" ||
    link === "/working/receiver/task-waiting"
  ) {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (
    link === "/working/task-end-today" ||
    link === "/working/task-return"
  ) {
    if (shouldLog.current) {
      const link = window.location.pathname;

      body2 = {
        Page: page,
        Limit: limit,
        TaskName: taskName,
        PriorityLevelID: selectedPrior,
        Recipient: selectedReceiver,
        DateAdd_From:
          filterFromDate === "" &&
          (link === "/working/new-task" ||
            link === "/working/task-change-deadline" ||
            link === "/working/task-finish" ||
            link === "/working/task-need-give")
            ? null
            : fromDate,

        DateAdd_To:
          filterToDate === "" &&
          (link === "/working/new-task" ||
            link === "/working/task-change-deadline" ||
            link === "/working/task-finish" ||
            link === "/working/task-need-give")
            ? null
            : toDate,
        Deadline: deadLine,
        TaskOwner: userId,
        PhongBan: phongBan,
      };

      if (filterStatus === 0 || filterStatus === undefined) {
        delete body2.TaskStatus;
      }

      if (resultSearch === 0 || resultSearch === undefined) {
        delete body2.Result;
      }

      if (phongBan === "" || phongBan === undefined) {
        delete body2.PhongBan;
      }

      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body2, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/task-end-soon") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/receiver/task-myself") {
    if (shouldLog.current) {
      let params: any = {
        TaskName: taskName,
        PriorityLevelID: selectedPrior,
        Page: page,
        Limit: limit,
        FromDate: fromDate,
        ToDate: toDate,
        Deadline: deadLine,
      };
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, params, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/receiver/task-new") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/receiver/task-received") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/receiver/task-processing") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/receiver/task-delay") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/receiver/task-end-today") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/task-finish") {
    if (shouldLog.current) {
      const link = window.location.pathname;

      body2 = {
        Page: page,
        Limit: limit,
        TaskName: taskName,
        PriorityLevelID: selectedPrior,
        Recipient: selectedReceiver,
        DateAdd_From:
          filterFromDate === "" &&
          (link === "/working/new-task" ||
            link === "/working/task-change-deadline" ||
            link === "/working/task-finish" ||
            link === "/working/task-need-give")
            ? null
            : fromDate,

        DateAdd_To:
          filterToDate === "" &&
          (link === "/working/new-task" ||
            link === "/working/task-change-deadline" ||
            link === "/working/task-finish" ||
            link === "/working/task-need-give")
            ? null
            : toDate,
        Deadline: deadLine,
        TaskOwner: userId,
        PhongBan: phongBan,
      };

      if (filterStatus === 0 || filterStatus === undefined) {
        delete body2.TaskStatus;
      }

      if (resultSearch === 0 || resultSearch === undefined) {
        delete body2.Result;
      }

      if (phongBan === "" || phongBan === undefined) {
        delete body2.PhongBan;
      }

      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body2, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/receiver/task-end-soon") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (
    link === "/working/receiver/all-task" ||
    link === "/working/task-total"
  ) {
    body = {
      Page: page,
      Limit: limit,
      TaskName: taskName,
      PriorityLevelID: selectedPrior,
      Recipient: selectedReceiver,
      DateAdd_From: fromDate,
      DateAdd_To: toDate,
      Deadline: deadLine,
      TaskStatus: filterStatus,
      TaskOwner: bossSearch === -1 ? "" : bossSearch,
      Result: resultSearch,
      PhongBan: phongBan,
    };

    if (filterStatus === 0 || filterStatus === undefined) {
      delete body.TaskStatus;
    }

    if (resultSearch === 0 || resultSearch === undefined) {
      delete body.Result;
    }

    if (bossSearch === -1 || bossSearch === undefined) {
      delete body.TaskOwner;
    }

    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              console.log("body", body);
              console.log("response", res);
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/task-totalall") {
    body = {
      Page: page,
      Limit: limit,
      TaskName: taskName,
      PriorityLevelID: selectedPrior,
      Recipient: selectedReceiver,
      DateAdd_From: fromDate,
      DateAdd_To: toDate,
      Deadline: deadLine,
      TaskStatus: filterStatus,
      Result: resultSearch,
      PhongBan: phongBan,
      TaskOwner: bossSearch === -1 ? "" : bossSearch,
    };

    if (filterStatus === 0 || filterStatus === undefined) {
      delete body.TaskStatus;
    }

    if (resultSearch === 0 || resultSearch === undefined) {
      delete body.Result;
    }

    if (phongBan === "" || phongBan === undefined) {
      delete body.PhongBan;
    }

    if (bossSearch === -1 || bossSearch === undefined) {
      delete body.TaskOwner;
    }

    console.log("object", body.Result);

    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/receiver/task-finish_early") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/receiver/task-finish_puntual") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/receiver/task-share") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/receiver/task-finish_late") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/task-proccessing") {
    const link = window.location.pathname;

    body2 = {
      Page: page,
      Limit: limit,
      TaskName: taskName,
      PriorityLevelID: selectedPrior,
      Recipient: selectedReceiver,
      DateAdd_From:
        filterFromDate === "" &&
        (link === "/working/new-task" ||
          link === "/working/task-change-deadline" ||
          link === "/working/task-finish" ||
          link === "/working/task-need-give")
          ? null
          : fromDate,

      DateAdd_To:
        filterToDate === "" &&
        (link === "/working/new-task" ||
          link === "/working/task-change-deadline" ||
          link === "/working/task-finish" ||
          link === "/working/task-need-give")
          ? null
          : toDate,
      Deadline: deadLine,
      TaskOwner: _checkBossSearch(),
      PhongBan: phongBan,
    };

    if (filterStatus === 0 || filterStatus === undefined) {
      delete body2.TaskStatus;
    }

    if (resultSearch === 0 || resultSearch === undefined) {
      delete body2.Result;
    }

    if (phongBan === "" || phongBan === undefined) {
      delete body2.PhongBan;
    }

    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body2, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/task-finish-late") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body2, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/task-finish-punctual") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body2, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (link === "/working/task-finish-early") {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body2, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  } else if (
    link == NAVIGATION_KEYS.recoveryTask ||
    link == NAVIGATION_KEYS.revokeTask ||
    link == NAVIGATION_KEYS.settingProceduce
  ) {
    if (shouldLog.current) {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body2, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    } else {
      const takeData = async () => {
        try {
          await ServiceUtils.postV2(api, body2, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "application/json",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              callback(res);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  }
};

// sub funcs
export const loadPrior = async (
  callback: (res: any) => void,
  shouldLog: any
) => {
  // const accessToken = localStorage.getItem("accessToken");

  if (shouldLog.current) {
    shouldLog.current = false;

    try {
      await ServiceUtils.getV2(PRIOR_LIST_DROPDOWN, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.status === 200) {
          callback(res);
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  }
};

export const loadReceiver = async (
  callback: (res: any) => void,
  shouldLog: any
) => {
  const accessToken = localStorage.getItem("accessToken");

  if (shouldLog.current) {
    shouldLog.current = false;

    try {
      await ServiceUtils.getV2(USER_READ_ALL_DROPDOWN, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.status === 200) {
          callback(res);
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  }
};

export const loadPhongBan = async (
  callback: (res: any) => void,
  shouldLog: any
) => {
  const accessToken = localStorage.getItem("accessToken");

  if (accessToken) {
    if (shouldLog.current) {
      shouldLog.current = false;

      try {
        await ServiceUtils.getV2(
          TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_PHONGBAN",
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain",
            },
          }
        ).then((res: any) => {
          if (res.status === 200) {
            callback(res);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    }
  }
};

export const loadBoss = async (
  callback: (res: any) => void,
  shouldLog: any
) => {
  // const accessToken = localStorage.getItem("accessToken");

  try {
    await ServiceUtils.getV2(LOAD_SEP, {
      headers: {
        Accept: "text/plain",
      },
    }).then((res: any) => {
      if (res.status === 200) {
        callback(res);
      }
    });
  } catch (error: any) {
    toast.error(
      "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
    );
  }
};
