import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";
import { useEffect, useRef, useState } from "react";
import HandleDate from "../../../../utils/date/handleDate";

const CustomToolbar = ({ setFromDate, setToDate, ...props }: any) => {
  const dropdownRef = useRef<any>(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  function addMonths(date: any, months: any) {
    const d = date.getDate();
    date.setMonth(date.getMonth() + months);
    if (date.getDate() !== d) {
      date.setDate(0);
    }

    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth());
    const first = startOfWeek(new Date(startOfMonth(newDate)), {
      weekStartsOn: 0,
    });
    // first.setDate(first.getDate() + 1);
    const end = endOfWeek(new Date(endOfMonth(newDate)), { weekStartsOn: 0 });
    end.setDate(end.getDate() + 1);
    // console.log("first2, ", new Date(HandleDate.convertDateToISOString(first)));
    // console.log("end2, ", new Date(HandleDate.convertDateToISOString(end)));

    // let from = new Date(
    //   first.getFullYear(),
    //   first.getMonth(),
    //   first.getDate(),
    //   7,
    //   0,
    //   0
    // )
    //   .toISOString()
    //   .slice(0, 16);
    // let to = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 7, 0, 0)
    //   .toISOString()
    //   .slice(0, 16);
    // console.log("first, ", first);
    // console.log("end, ", end);
    // console.log("from, ", from);
    // console.log("to, ", to);

    setFromDate(HandleDate.convertDateToISOString(first));
    setToDate(HandleDate.convertDateToISOString(end));

    return date;
  }

  function addWeeks(date: any, weeks: any) {
    date.setDate(date.getDate() + 7 * weeks);

    const firstDayOfWeek = startOfWeek(new Date(date), { weekStartsOn: 0 });

    const lastDayOfWeek = endOfWeek(new Date(date), { weekStartsOn: 0 });
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 1);

    setFromDate(HandleDate.convertDateToISOString(firstDayOfWeek));
    setToDate(HandleDate.convertDateToISOString(lastDayOfWeek));

    return date;
  }

  function addDays(date: any, days: any) {
    date.setDate(date.getDate() + days);
    const first = new Date(date);

    const end = new Date(date);
    end.setDate(end.getDate() + 1);

    setFromDate(HandleDate.convertDateToISOString(first));
    setToDate(HandleDate.convertDateToISOString(end));

    return date;
  }

  const goToDayView = () => {
    const first = new Date(props.date);

    const end = new Date(props.date);
    end.setDate(end.getDate() + 1);

    setFromDate(HandleDate.convertDateToISOString(first));
    setToDate(HandleDate.convertDateToISOString(end));

    props.onView("day");
  };
  const goToWeekView = () => {
    const first = startOfWeek(new Date(props.date), {
      weekStartsOn: 0,
    });

    const end = endOfWeek(new Date(props.date), { weekStartsOn: 0 });
    end.setDate(end.getDate() + 1);

    setFromDate(HandleDate.convertDateToISOString(first));
    setToDate(HandleDate.convertDateToISOString(end));

    props.onView("week");
  };
  const goToMonthView = () => {
    const newDate = new Date(props.date);
    newDate.setMonth(newDate.getMonth());
    const first = startOfWeek(new Date(startOfMonth(newDate)), {
      weekStartsOn: 0,
    });

    const end = endOfWeek(new Date(endOfMonth(newDate)), { weekStartsOn: 0 });
    end.setDate(end.getDate() + 1);

    setFromDate(HandleDate.convertDateToISOString(first));
    setToDate(HandleDate.convertDateToISOString(end));

    props.onView("month");
  };
  //   const goToAgendaView = () => {
  //     props.onView("agenda");
  //     setViewState("agenda");
  //   };

  const goToBack = () => {
    if (props.view === "day") {
      props.onNavigate("prev", addDays(props.date, -1));
    } else if (props.view === "week") {
      props.onNavigate("prev", addWeeks(props.date, -1));
    } else {
      props.onNavigate("prev", addMonths(props.date, -1));
    }
  };

  const goToNext = () => {
    if (props.view === "day") {
      props.onNavigate("next", addDays(props.date, +1));
    } else if (props.view === "week") {
      props.onNavigate("next", addWeeks(props.date, +1));
    } else {
      props.onNavigate("next", addMonths(props.date, +1));
    }
  };

  const goToToday = () => {
    const now = new Date();
    props.date.setMonth(now.getMonth());
    props.date.setYear(now.getFullYear());
    props.date.setDate(now.getDate());
    if (props.view === "day") {
      const first = new Date(props.date);
      const end = new Date(props.date);
      end.setDate(end.getDate() + 1);
      setFromDate(HandleDate.convertDateToISOString(first));
      setToDate(HandleDate.convertDateToISOString(end));
    } else if (props.view === "week") {
      const firstDayOfWeek = startOfWeek(new Date(props.date), {
        weekStartsOn: 0,
      });
      const lastDayOfWeek = endOfWeek(new Date(props.date), {
        weekStartsOn: 0,
      });
      lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 1);
      setFromDate(HandleDate.convertDateToISOString(firstDayOfWeek));
      setToDate(HandleDate.convertDateToISOString(lastDayOfWeek));
    } else {
      const newDate = new Date(props.date);
      newDate.setMonth(newDate.getMonth());
      const first = startOfWeek(new Date(startOfMonth(newDate)), {
        weekStartsOn: 0,
      });

      const end = endOfWeek(new Date(endOfMonth(newDate)), { weekStartsOn: 0 });
      end.setDate(end.getDate() + 1);
      setFromDate(HandleDate.convertDateToISOString(first));
      setToDate(HandleDate.convertDateToISOString(end));
    }
    props.onNavigate("current");
  };
  const _handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", _handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", _handleClickOutside);
    };
  }, []);
  return (
    <div className="lg:flex gap-5 justify-between my-2 px-3 lg:px-0">
      <div className="flex justify-between">
        <div className=" border border-gray-300 rounded-md bg-gray-50">
          <button
            onClick={goToBack}
            className=" py-1 px-3.5 lg:px-5 font-semibold w-[70px]"
          >
            Trước
          </button>
          <button
            onClick={goToToday}
            className=" py-1 px-3.5 lg:px-5 border-gray-300 border-x font-semibold"
          >
            Hôm nay
          </button>
          <button
            onClick={goToNext}
            className=" py-1 px-3.5 lg:px-5 font-semibold w-[70px]"
          >
            Sau
          </button>
        </div>
        <div
          className="border border-gray-300 rounded-md bg-gray-50 lg:hidden relative"
          ref={dropdownRef}
        >
          <button
            onClick={() => {
              setOpenDropdown(!openDropdown);
            }}
            className=" p-1 font-semibold"
          >
            <MoreVertIcon />
          </button>
          {openDropdown && (
            <div className="absolute w-[100px] right-0 z-[100] text-center font-normal border border-gray-300 rounded-md bg-gray-50 flex flex-col">
              <button
                onClick={goToDayView}
                className="py-2 font-semibold border-gray-300 border-b"
              >
                Ngày
              </button>
              <button
                onClick={goToWeekView}
                className="py-2 font-semibold border-gray-300 border-b"
              >
                Tuần
              </button>
              <button onClick={goToMonthView} className="py-2 font-semibold">
                Tháng
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="text-center w-full lg:w-max mt-5 mb-2 lg:mt-0 lg:mb-2">
        <h1 className="text-lg font-semibold ">
          {moment(props.date).format("DD/MM/YYYY")}
        </h1>
      </div>
      <div className=" border border-gray-300 rounded-md bg-gray-50 w-max mx-auto lg:mx-0 hidden lg:flex">
        <button
          onClick={goToDayView}
          className="py-1 px-5  font-semibold border-r"
        >
          Ngày
        </button>
        <button
          onClick={goToWeekView}
          className="py-1 px-5  font-semibold border-gray-300 border-r"
        >
          Tuần
        </button>
        <button
          onClick={goToMonthView}
          className=" py-1 px-5 rounded-l-md font-semibold border-gray-300 border-r"
        >
          Tháng
        </button>

        {/* <button
          onClick={goToAgendaView}
          className="py-1 px-5 rounded-r-md font-semibold"
        >
          agenda
        </button> */}
      </div>
    </div>
  );
};

export default CustomToolbar;
