import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { HexColorPicker } from "react-colorful";

import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";

import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { toast } from "react-toastify";
import axios from "axios";

import {
  CREATE_CATEGORY_SUGGEST_API,
  GET_DETAIL_CATEGORY_SUGGEST_API,
  GET_LIST_CATEGORY_SUGGEST_API,
  GET_LIST_PROCEDURE_DROPDOWN,
  PRIOR_CREATE,
  PRIOR_LIST,
  SETTING_PROCEDUCE_LIST,
  UPDATE_CATEGORY_SUGGEST_API,
} from "../../../apis/_index";
import {
  ONLOAD_CATEGORY_SUGGEST,
  ONLOAD_PRIORLIST,
} from "../../../redux/reducers/settingReducer/_index";

import { onTouchForm } from "../../../funcs/onTochForm";
import { ModalController } from "../../../ui/homeUI/_index";
import ServiceUtils from "../../../utils/services";
import { PROCEDUCE_TYPES } from "../../../constants/dataConstants";

const CreateCategorySuggest = (props: any) => {
  const dispatch = useDispatch();
  const context = useContext(ModalController);

  const id = props.id;
  const handleClose = props.handleClose;

  // state
  const [name, setName] = useState<any>("");
  const [description, setDescription] = useState<any>("");
  const [selectedProceduce, setSelectedProceduce] = useState<any>(0);
  const [proceduces, setProceduceList] = useState<any>();

  const [proceduceType, setProceduceType] = useState<any>(
    PROCEDUCE_TYPES[0].value
  );

  const getListProceduce = () => {
    try {
      // const accessToken = localStorage.getItem("accessToken");

      ServiceUtils.getV2(GET_LIST_PROCEDURE_DROPDOWN, {
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.status == 200) {
          const procedures1 = res?.data?.Data?.procedures || [];

          setProceduceList(procedures1);
        } else {
        }
      });
    } catch (error: any) {}
  };

  const _close = () => {
    handleClose();

    context.setTaskId("");

    context.handleClose();
  };

  const _getDetail = async () => {
    if (id) {
      try {
        await ServiceUtils.getV2(
          GET_DETAIL_CATEGORY_SUGGEST_API.replace("{id}", id),
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "text/plain",
            },
          }
        ).then((res: any) => {
          if (res.status === 200) {
            const data = (res.data || {}).Data[0];

            if (data) {
              setName(data.Name);
              setDescription(data.Description);
              setSelectedProceduce(data.ProceduceID);
              setProceduceType(data.ProposeType);
            } else {
              toast.error("Lấy chi tiết danh mục đề xuất thất bại");
            }
          } else {
            toast.error("Lấy chi tiết danh mục đề xuất thất bại");
          }
        });
      } catch (error: any) {
        console.log(error);

        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    }
  };

  // funcs
  const _checkValid = (): boolean => {
    if (!name || !selectedProceduce) {
      return true;
    } else {
      return false;
    }
  };

  const _onChangeName = (e: any) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const _onChangeSelectedProcedure = (e: any) => {
    e.preventDefault();
    setSelectedProceduce(e.target.value);
  };
  const _onChangeProcedureType = (e: any) => {
    e.preventDefault();
    setProceduceType(e.target.value);
  };

  const _onClickSubmit = async () => {
    // const accessToken = localStorage.getItem("accessToken");

    const body: any = {
      Name: name,
      Description: description,
      ID: id,
      ProceduceID: selectedProceduce,
      ProposeType: parseInt(proceduceType, 10),
    };

    const url = id ? UPDATE_CATEGORY_SUGGEST_API : CREATE_CATEGORY_SUGGEST_API;
    const messageSuccessfully = id ? "Sửa thành công." : "Tạo mới thành công.";
    const messageFailure = id ? "Tạo mới thất bại." : "Sửa thất bại.";

    try {
      await ServiceUtils.postV2(url, body, {
        headers: {
          "Content-Type": "application/json",
          Accept: "text/plain",
        },
      }).then((res: any) => {
        console.log(res);
        if ((res.data || {}).StatusCode === 200) {
          const takeData = async () => {
            try {
              await ServiceUtils.getV2(GET_LIST_CATEGORY_SUGGEST_API, {
                headers: {
                  Accept: "text/plain",
                },
              }).then((res: any) => {
                if (res.status === 200) {
                  dispatch(ONLOAD_CATEGORY_SUGGEST(res.data.Data));

                  toast.success(messageSuccessfully);

                  if (id) {
                    _close();
                  } else {
                    setName("");
                    setDescription("");
                    setSelectedProceduce("");
                  }
                  _close();
                } else {
                  toast.error(res.data.Message);
                }
              });
            } catch (error: any) {
              toast.error(
                "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
              );
            }
          };

          takeData();
        } else {
          toast.error(messageFailure);
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  };

  const _onChangeDescription = (e: any) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  useEffect(() => {
    _getDetail();

    getListProceduce();
  }, []);

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[52rem] w-screen lg:h-[40rem] h-[calc(100vh-10rem)]
    rounded-md
  "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        {id ? <p>Thông tin đề xuất</p> : <p>Tạo danh mục đề xuất</p>}
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={_close}
        >
          X
        </button>
      </div>

      {/* content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        {/* lines */}
        <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label>
              Tên danh mục đề xuất&nbsp;
              <span className="text-red-500">(*)</span>
            </label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={name}
              onChange={(e: any) => {
                _onChangeName(e);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>
        <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label>
              Mô tả đề xuất&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <textarea
              className="w-full h-[10rem] rounded-md p-2 border-2 border-slate-200"
              value={description}
              onChange={(e: any) => {
                _onChangeDescription(e);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>
        <div className="flex lg:flex-row flex-col w-full h-max">
          <div className="flex flex-col w-full lg:pr-2 pr-0 h-max">
            <label>Thuộc quy trình (*)</label>
            <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={selectedProceduce}
              onChange={(e: any) => {
                _onChangeSelectedProcedure(e);
              }}
            >
              <option className="text-gray-300" value={0}>
                --- Chọn quy trình ---
              </option>
              {proceduces === undefined
                ? ""
                : proceduces.map((item: any, index: any) => (
                    <option key={index} value={item.ID}>
                      {item.Name}
                    </option>
                  ))}
            </select>
          </div>
        </div>

        <div className="flex lg:flex-row flex-col w-full h-max">
          <div className="flex flex-col w-full lg:pr-2 pr-0 h-max">
            <label>Thuộc loại (*)</label>
            <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={proceduceType}
              onChange={(e: any) => {
                _onChangeProcedureType(e);
              }}
            >
              {PROCEDUCE_TYPES === undefined
                ? ""
                : PROCEDUCE_TYPES.map((item: any, index: any) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  ))}
            </select>
          </div>
        </div>

        <div className="flex w-full h-max space-x-6">
          {/* <div className="w-1/3 h-max flex flex-col space-y-6">
            <div className="flex items-center w-1/2 justify-between h-max">
              <label className="font-bold text-primary">Hiển thị </label>
              <input type="checkbox" checked={isActive}
            onChange={_onChangeIsActive}
           />
            </div>
          </div> */}
          <div
            className={`${
              _checkValid() === true ? "flex" : "hidden"
            } absolute right-4 bottom-4`}
          >
            <p className="text-red-500">Vui lòng điền đầy đủ thông tin</p>
          </div>
        </div>
      </div>

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className={`
          flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
          ${
            _checkValid() === true
              ? "bg-slate-200"
              : "bg-primary hover:bg-sky-400"
          }
        `}
            onClick={_onClickSubmit}
            disabled={_checkValid() === true ? true : false}
          >
            <SaveIcon fontSize="small" />
            {id ? <p>Cập nhật</p> : <p>Tạo mới</p>}
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateCategorySuggest;
