import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ModalController } from "../../homeUI/_index";

import Title from "../../../components/title";
import Table from "../../../components/table";

import { GROUP_READ_ALL } from "../../../apis/_index";
import axios from "axios";
import { toast } from "react-toastify";

import { takePermission } from "../../../funcs";

import {
  ONLOAD_GROUPLIST,
  ONLOAD_GROUPLIST_DROPDOWN,
} from "../../../redux/reducers/userReducer/_index";
import AddIcon from "@mui/icons-material/Add";
import { unsignedToAccented } from "../../../helpers/handleHelper";
import ServiceUtils from "../../../utils/services";

const UserListUI = () => {
  const userData = useSelector((state: any) => state.userReducer);
  const [groups, setGroup] = useState((userData || {}).groupList || []);
  const data = useSelector((state: any) => state.userReducer);
  const menu = takePermission(5);

  const context = useContext(ModalController);
  const dispatch = useDispatch();
  const shouldLog = useRef(true);

  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      try {
        const takeData = async () => {
          await ServiceUtils.getV2(GROUP_READ_ALL, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              dispatch(ONLOAD_GROUPLIST(res.data.Data));
            }
          });
        };

        takeData();
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    }
  });

  useEffect(() => {
    if (groups.length <= 0) {
      setGroup(userData.groupList || []);
    }
  }, [userData.groupList]);

  const _onClickCreateUser = () => {
    context.setFuncs("createUser");
    context.handleOpen();
  };

  const onKeyUpSearch = (e: any) => {
    const value = e.target.value;

    if (!value) {
      dispatch(ONLOAD_GROUPLIST(groups));
    } else {
      const valueFilter = unsignedToAccented(value.trim().toLowerCase());

      const groupFilters = groups.filter((p: any) => {
        const groupName = unsignedToAccented(
          (p.GroupName || "").trim().toLowerCase()
        );

        return (
          groupName.includes(valueFilter) ||
          groupName.startsWith(valueFilter) ||
          groupName.endsWith(valueFilter) ||
          groupName.indexOf(valueFilter) > -1 ||
          groupName.lastIndexOf(valueFilter) > -1
        );
      });

      dispatch(ONLOAD_GROUPLIST(groupFilters));
    }
  };

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-3rem)] text-sm pl-5 pr-5 pt-3 pb-3 space-y-3">
        <div className="flex w-full h-max justify-between">
          <input
            className="w-max h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
            placeholder="Nhập nội dung cần tìm"
            onKeyUp={onKeyUpSearch}
          />

          <button
            className={`
              p-2 bg-green-500 rounded-md text-white
              hover:bg-green-400 items-center
              ${menu.PerInsert === true ? "flex" : "hidden"}
            `}
            onClick={_onClickCreateUser}
          >
            <AddIcon fontSize="small" />
            <p className="hidden lg:flex">Thêm mới</p>
          </button>
        </div>

        <Table />
      </div>
    </div>
  );
};

export default UserListUI;
