import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CREATE_REQUEST_REPORTING } from "../../../apis/_index";

import { ModalController } from "../../../ui/homeUI/_index";

import LoaderSpinner from "../../loader-spinner/_index";

import AddIcon from "@mui/icons-material/Add";

import Select from "react-select";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import { toast } from "react-toastify";

import CloseIcon from "@mui/icons-material/Close";
import ServiceUtils from "../../../utils/services";
// var index = require("../../../temp/baocao/index.html");

const RequireNewElectronicReport = (props: any) => {
  const handleClose = props.handleClose;
  const context = useContext(ModalController);

  // states
  const [phongBanId, setPhongBanId] = useState<any[]>([]);
  const [userId, setUserId] = useState<any[]>([]);
  const [deadLine, setDeadLine] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [reportType, setReportType] = useState<any>(null);
  const [reportViewerType, setReportViewerType] = useState<any>({
    id: 1,
    value: "Quản lý đánh giá",
    label: "Quản lý đánh giá",
  });

  const [selectedTypeOfReport, setSelectedTypeOFReport] = useState<any[]>([]);
  const [selectedPhongBan, setSelectedPhongBan] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<any[]>([]);

  const [finalResult, setFinalResult] = useState<any[]>([]);
  const [finalResultRating, setFinalResultRating] = useState<any[]>([]);

  const workingData = useSelector((state: any) => state.workingReducer);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // non - funcs convert
  const phongBanIdList = workingData.listPhongBan?.map((item: any) => {
    return {
      ...item,
      label: item?.ParameterValue,
      value: item?.ParameterValue,
    };
  });

  const userIdList = workingData.receiverList?.map((item: any) => {
    return { ...item, label: item?.FullName, value: item?.FullName };
  });

  const finalResultConvert = useMemo(() => {
    return finalResult?.map((item: any) => {
      return { ...item, label: item?.FullName, value: item?.FullName };
    });
  }, [finalResult]);

  const finalResultMultipleConvert = useMemo(() => {
    return finalResultRating?.map((item: any) => {
      return { ...item, label: item?.FullName, value: item?.FullName };
    });
  }, [finalResultRating]);

  // funcs
  // -- reporter
  const _onSelectPhongBan = (data: any) => {
    const tempArray = userIdList?.filter((item: any) =>
      (item.PhongBan || "")
        .split(",")
        .find((p: any) => p == data.ParameterId) != null
        ? true
        : false
    );

    const _voting = [...finalResult];

    tempArray.forEach((item: any, index: number) => {
      if (_voting.some((_item: any) => _item.Id === item.Id) !== true) {
        _voting.push(item);
      }
    });

    setFinalResult(_voting);
    console.log(tempArray, _voting);
  };

  const _onSelectUser = (data: any) => {
    const _voting = [...finalResult];

    if (_voting.some((item: any) => item.Id === data.Id) !== true) {
      _voting.push(data);
    }

    setFinalResult(_voting);
  };

  // -- reviewer
  const _onSelectMultipleRatingPhongBan = (data: any) => {
    console.log(userIdList);

    const tempArray = userIdList?.filter((item: any) =>
      item?.PhongBan?.split(",").find((x: any) => x == data?.ParameterId)
    );

    const _voting = [...finalResultRating];

    tempArray.forEach((item: any, index: number) => {
      if (_voting.some((_item: any) => _item.Id === item.Id) !== true) {
        _voting.push(item);
      }
    });

    setFinalResultRating(_voting);
  };

  const _onSelectMultipleRatingUser = (data: any) => {
    const _voting = [...finalResultRating];

    if (_voting.some((item: any) => item.Id === data.Id) !== true) {
      _voting.push(data);
    }

    setFinalResultRating(_voting);
  };

  const _onSelectTypeOfReport = (data: any) => {
    if (data?.id === 3) {
      setFinalResult([]);
      setFinalResult(userIdList);
      setReportType(data);
    } else {
      setFinalResult([]);
      if (data !== null) {
        setReportType(data);
      }
    }
  };

  const _onChangeDeadline = (e: any) => {
    e.preventDefault();
    setDeadLine(e.target.value);
  };

  const _onChangeTypeViewerReport = (data: any) => {
    setReportViewerType(data);
  };

  const _onClickSubmit = () => {
    const UserReport = finalResult
      ?.map((item) => {
        return item.Id;
      })
      .join();
    const UserReviewer = finalResultRating
      ?.map((item) => {
        return item.Id;
      })
      .join();
    let reViewTypeFinal: boolean = false;

    if (reportViewerType?.id === 0) {
      reViewTypeFinal = false;
    } else {
      reViewTypeFinal = true;
    }

    const body: any = {
      DeadLine: deadLine,
      RequestContent: notes,
      ReportUserType: reportType?.id,
      ReportReviewType: reViewTypeFinal,
      UserReport: UserReport,
      UserReviewer: UserReviewer,
    };

    if (reViewTypeFinal === true) {
      delete body.UserReviewer;
    }

    const fetchData = async () => {
      const accessToken = localStorage.getItem("accessToken");

      try {
        setIsLoading(true);
        await ServiceUtils.postV2(CREATE_REQUEST_REPORTING, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            setIsLoading(false);
            context.funcsLoad?.onLoad();
            toast.success("Tạo mới yêu cầu thành công.");
            handleClose();
          } else {
            setIsLoading(false);
            toast.error(res.data.Message);
          }
        });
      } catch (error: any) {
        setIsLoading(false);
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };
    fetchData();
  };

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[50rem] w-screen h-[40rem]
      rounded-md
    "
    >
      {/* SubModal */}

      {/* Header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Yêu cầu báo cáo</p>

        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      {isLoading ? (
        <div className="w-full flex h-full overflow-auto p-4 text-sm styled-scrollbars space-y-10 flex-col justify-center items-center">
          <LoaderSpinner w={32} h={32} />
          <p className="text-primary">Đang tạo mới yêu cầu</p>
        </div>
      ) : (
        <div className="w-full flex h-full overflow-auto p-4 text-sm styled-scrollbars space-y-10 flex-col">
          {/* Type of report list */}
          <div className="w-full h-[35px]">
            <label>Chọn kiểu báo cáo</label>
            <Select
              isClearable
              options={[
                {
                  id: 1,
                  value: "Chọn nhân viên",
                  label: "Chọn nhân viên",
                },
                {
                  id: 2,
                  value: "Chọn phòng",
                  label: "Chọn phòng",
                },
                {
                  id: 3,
                  value: "Chọn tất cả",
                  label: "Chọn tất cả",
                },
              ]}
              onChange={(data: any) => {
                _onSelectTypeOfReport(data);
              }}
              placeholder="--- Chọn kiểu báo cáo ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          {/* Phong ban list */}
          <div className="w-full h-[35px]">
            <label>Chọn kiểu người đánh giá</label>
            <Select
              className="w-full"
              options={[
                {
                  id: 1,
                  value: "Quản lý đánh giá",
                  label: "Quản lý đánh giá",
                },
                {
                  id: 0,
                  value: "Từng người đánh giá",
                  label: "Từng người đánh giá",
                },
              ]}
              // isClearable
              value={reportViewerType}
              onChange={(data: any) => _onChangeTypeViewerReport(data)}
              placeholder="Chọn nhanh cả phòng làm báo cáo"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          {/* Fields */}
          <div className="w-full h-max flex space-x-8">
            {/* Left fields */}
            <div className="w-1/2 h-max flex flex-col space-y-10">
              {/* Title */}
              <div className="w-full h-max flex justify-center items-center">
                <p className="font-semibold">NGƯỜI LÀM BÁO CÁO</p>
              </div>

              {/* Phong ban list */}
              <div className="w-full h-[35px]">
                <label>Chọn nhanh cả phòng</label>
                <Select
                  className="w-full"
                  options={phongBanIdList}
                  value={phongBanId}
                  onChange={(data: any) => _onSelectPhongBan(data)}
                  // isClearable
                  placeholder="Chọn nhanh cả phòng"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              {/* User list */}
              <div className="w-full h-[35px]">
                <label>Chọn từng nhân viên làm báo cáo</label>
                <Select
                  className="w-full"
                  options={userIdList}
                  value={userId}
                  // isClearable
                  onChange={(data: any) => _onSelectUser(data)}
                  placeholder="Chọn nhân viên"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <div className="w-full h-[35px]">
                <label>Chọn thời hạn làm báo cáo</label>
                <input
                  className="w-full h-[2.3rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                  type="datetime-local"
                  value={deadLine}
                  onChange={(e: any) => _onChangeDeadline(e)}
                />
              </div>

              <div className="w-full min-h-[35px] max-h-max">
                <label>Danh sách người báo cáo</label>
                <Select
                  className="w-full"
                  isMulti
                  value={finalResultConvert}
                  onChange={(data: any) => setFinalResult(data)}
                  isClearable
                  placeholder="Danh sách nhân viên báo cáo"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    NoOptionsMessage: () => null,
                  }}
                />
              </div>
            </div>

            {/* Right fields */}
            {reportViewerType?.id === 1 ? (
              <></>
            ) : (
              <div className="w-1/2 h-max flex flex-col">
                <div className="w-full h-max flex flex-col space-y-10">
                  {/* Title */}
                  <div className="w-full h-max flex justify-center items-center">
                    <p className="font-semibold">NGƯỜI ĐÁNH GIÁ</p>
                  </div>

                  {/* Phong ban list */}
                  <div className="w-full h-[35px]">
                    <label>Chọn nhanh cả phòng đánh giá</label>
                    <Select
                      className="w-full"
                      options={phongBanIdList}
                      value={phongBanId}
                      onChange={(data: any) =>
                        _onSelectMultipleRatingPhongBan(data)
                      }
                      // isClearable
                      placeholder="Chọn nhanh cả phòng đánh giá"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>

                  {/* User list */}
                  <div className="w-full h-[35px]">
                    <label>Chọn từng nhân viên đánh giá</label>
                    <Select
                      className="w-full"
                      options={userIdList}
                      value={userId}
                      // isClearable
                      onChange={(data: any) =>
                        _onSelectMultipleRatingUser(data)
                      }
                      placeholder="Chọn nhân viên đánh giá"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>

                  <div className="w-full min-h-[35px] max-h-max">
                    <label>Danh sách người đánh giá</label>
                    <Select
                      className="w-full"
                      isMulti
                      value={finalResultMultipleConvert}
                      onChange={(data: any) => setFinalResult(data)}
                      isClearable
                      placeholder="Danh sách người đánh giá"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        NoOptionsMessage: () => null,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Content request */}
          <div className="w-full h-max flex space-x-4">
            <textarea
              className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
              placeholder="Nhập nội dung yêu cầu báo cáo"
              value={notes}
              onChange={(e: any) => {
                e.preventDefault();
                setNotes(e.target.value);
              }}
            />
          </div>
        </div>
      )}

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className="
              flex items-center space-x-1 w-max h-max p-2 bg-amber-500 text-white rounded-md
              hover:bg-amber-400
            "
            onClick={_onClickSubmit}
          >
            <AddIcon fontSize="small" />
            <p>Tạo yêu cầu</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};
export default RequireNewElectronicReport;
