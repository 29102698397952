import React, { useRef, useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ModalController } from "../../../ui/homeUI/_index";

import { ONCHANGE_NGUOI_NHAN_DA_NHAN } from "../../../redux/reducers/workingReducer/_index";

import FlagIcon from "@mui/icons-material/Flag";
import {
  NGUOI_NHAN_VIEC_BAT_DAU,
  NGUOI_NHAN_VIEC_LIST_RECEIVED,
} from "../../../apis/_index";
import { toast } from "react-toastify";
import axios from "axios";

import { readList } from "../../../funcs/taskNguoiGiaoViec";
import LoaderSpinner from "../../loader-spinner/_index";
import ServiceUtils from "../../../utils/services";

const StartTaskSub = (props: any) => {
  // take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;
  const context = useContext(ModalController);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const urlLink = window.location.pathname;
  const shouldLog = useRef(true);

  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  const _onClickSubmit = () => {
    const batdau = async () => {
      setIsLoading(true);
      const accessToken = localStorage.getItem("accessToken");
      const API = NGUOI_NHAN_VIEC_BAT_DAU + taskId;

      try {
        await ServiceUtils.getV2(API, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if ((((res || {}).data || {}).Data[0] || {}).RCode === 200) {
            if (handleClose) {
              readList(
                NGUOI_NHAN_VIEC_LIST_RECEIVED,
                data.pageCurrent,
                data.choosePage,
                data.searchFilter.taskName,
                data.searchFilter.selectedPrior,
                data.searchFilter.selectedReceiver,
                data.searchFilter.fromDate,
                data.searchFilter.toDate,
                data.searchFilter.deadLine,
                shouldLog,
                urlLink,
                (res: any) => {
                  dispatch(ONCHANGE_NGUOI_NHAN_DA_NHAN(res.data.Data));
                }
              );
              toast.success("Bắt đầu công việc thành công.");
              handleClose();
              context.handleClose();
              setIsLoading(false);
            }
          } else {
            if (handleClose) {
              handleClose();
            }
            toast.error(
              (((res || {}).data || {}).Data[0] || {}).RMessage ||
                "Bắt đầu công việc thất bại."
            );
            setIsLoading(false);
          }
        });
      } catch (error: any) {
        if (handleClose) {
          handleClose();
        }
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
        setIsLoading(false);
      }
    };
    batdau();
  };

  return (
    <div
      className="
      flex bg-cyan-500
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    "
    >
      {/* icon */}
      <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
        <FlagIcon sx={{ fontSize: 100 }} />
      </div>

      {/* content */}
      <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
        <p>Bạn có đồng ý bắt đầu công việc này?</p>
        <div className="w-full h-max flex justify-center items-center space-x-12">
          <button
            className={`w-[5rem] h-max p-2 rounded-md
            ${
              isLoading === true
                ? "bg-slate-300"
                : "bg-slate-500 hover:bg-slate-400"
            }
          `}
            onClick={() => _onClickSubmit()}
            disabled={isLoading}
          >
            {isLoading === true ? (
              <div className="flex w-full h-full justify-center items-center">
                <LoaderSpinner w={20} h={20} />
              </div>
            ) : (
              <p>Có</p>
            )}
          </button>
          <button
            className={`w-[5rem] h-max pt-1 pb-1 rounded-md
            ${isLoading === true ? "bg-red-300" : "bg-red-500 hover:bg-red-400"}
          `}
            onClick={handleClose}
            disabled={isLoading}
          >
            Không
          </button>
        </div>
      </div>
    </div>
  );
};

export default StartTaskSub;
