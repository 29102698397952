const linkIncludes = [
  "/working/new-task",
  "/working/task-change-deadline",
  "/working/task-need-give",
  "/working/task-finish",
  // "/working/task-totalall",
  "/working/task-total",
  "/working/receiver/task-new",
  "/working/receiver/task-waiting",
  "/working/receiver/task-received",
  "/working/receiver/all-task",
];

const firstDayOfMonth = (link: any, month?: any, year?: any) => {
  if (linkIncludes.find((p) => p == link)) {
    if (month == null || year == null) {
      return "";
    } else {
      return new Date(year, month - 1, 1, 7, 0, 0).toISOString().slice(0, 16);
    }
  } else {
    if (month == null || year == null) {
      return new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        7,
        0,
        0
      )
        .toISOString()
        .slice(0, 16);
    } else {
      return new Date(year, month - 1, 1, 7, 0, 0).toISOString().slice(0, 16);
    }
  }
};

const currentDate = (link: any, month?: any, year?: any) => {
  if (linkIncludes.find((p) => p == link)) {
    if (month == null || year == null) {
      return "";
    } else {
      return new Date(year, month, 1, 7, 0, 0).toISOString().slice(0, 16);
    }
  } else {
    if (
      month == null ||
      year == null ||
      (month - 1 == new Date().getMonth() && year == new Date().getFullYear())
    ) {
      return new Date(
        new Date().getTime() + new Date().getTimezoneOffset() * -60 * 1000
      )
        .toISOString()
        .slice(0, 16);
    } else {
      return new Date(year, month, 1, 7, 0, 0).toISOString().slice(0, 16);
    }
  }
};

const firstDayOfYear = (link: any) => {
  if (linkIncludes.find((p) => p == link)) {
    return "";
  } else {
    return new Date(new Date().getFullYear(), 0, 1, 0, 0, 0)
      .toISOString()
      .slice(0, 24);
  }
};

const lastDayOfYear = (link?: any) => {
  if (linkIncludes.find((p) => p == link)) {
    return "";
  } else {
    return new Date(new Date().getFullYear() + 1, 0, 1, 0, 0, 0)
      .toISOString()
      .slice(0, 24);
  }
};

const convertDateToISOString = (date: any) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 7, 0, 0)
    .toISOString()
    .slice(0, 24);
};

const HandleDate = {
  firstDayOfMonth,
  firstDayOfYear,
  currentDate,
  lastDayOfYear,
  convertDateToISOString,
};

export default HandleDate;
