import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import DoneIcon from "@mui/icons-material/Done";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import HistoryIcon from "@mui/icons-material/History";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";

import {
  PRIOR_LIST_DROPDOWN,
  SECURITY_READ_ALL_DROPDOWN,
  SENT_MESSAGE,
  NGUOI_NHAN_FILE_STEP_UPLOAD,
  NGUOI_NHAN_VIEC_LIST_RECEIVED,
  NGUOI_NHAN_VIEC_BAT_DAU,
} from "../../../apis/_index";

import LoaderSpinner from "../../loader-spinner/_index";

import {
  NGUOI_NHAN_VIEC_DETAIL,
  NGUOI_GIAO_VIEC_DETAIL,
} from "../../../apis/_index";

import SubModal from "../_subModal/_index";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

import UploadFileTwoToneIcon from "@mui/icons-material/UploadFileTwoTone";
import ServiceUtils from "../../../utils/services";
import { readList } from "../../../funcs/taskNguoiGiaoViec";
import { ONCHANGE_NGUOI_NHAN_DA_NHAN } from "../../../redux/reducers/workingReducer/_index";

const ReceiveTaskDetail = (props: any) => {
  // take props
  const handleClose = props.handleClose;
  const taskId = props.taskId;

  // state submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [uuTienList, setUuTienList] = useState<any[] | null>(null);
  const [doMatList, setDoMatList] = useState<any[] | null>(null);
  const [_taskContents, set_TaskContents] = useState<any[]>([]);
  const [fileListAPI2, setFileListAPI2] = useState<any[]>([]);

  const appData = useSelector((state: any) => state.appReducer);

  const [task, setTask] = useState<any>(null);
  const [taskContents, setTaskContents] = useState<any[]>([]);
  const [taskResults, setTaskResults] = useState<any>([]);
  const [taskInfoText, setTaskInfoText] = useState<any[]>([]);
  const [taskHistory, setTaskHistory] = useState<any>([]);
  const [taskContentsHistory, setTaskContentsHistory] = useState<any[]>([]);
  const uploadFileChat = useRef<any>(null);

  const [text, setText] = useState<string>("");

  const [fileListAPI, setFileListAPI] = useState<any[]>([]);

  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);

  const [filesList, setFilesList] = useState<any>([]);
  const [selectedTypeOf, setSelectedTypeOf] = useState<number>(0);

  const urlLink = window.location.pathname;

  const [taskContentHistoryId, setTaskContentHistoryId] = useState<any>(null);
  const [isViewHistoty, setIsViewHistoty] = useState<any>(null);

  // funcs
  const _onChangeMultipleChoicePartner = (
    text: string,
    item: any = null,
    isViewHistoty: any = null
  ) => {
    handleOpenSub();
    if (
      text === "completedTaskContent" ||
      text === "multipleUploadFileContent"
    ) {
      setTaskContentHistoryId(item);
    }
    if (isViewHistoty === 1) {
      setIsViewHistoty(1);
    } else {
      setIsViewHistoty(0);
    }
    setFuncsSub(text);
  };

  const _handleChecked = (statusCheck: any, ownerChecked: any) => {
    if (statusCheck === false && ownerChecked === null) {
      return "Người thực hiện chính không duyệt";
    } else if (statusCheck === true && ownerChecked === null) {
      return "Người giao việc chưa duyệt";
    } else if (statusCheck === null && ownerChecked === null) {
      return "Người thực hiện chính chưa duyệt";
    } else if (statusCheck === true && ownerChecked === false) {
      return "Người giao việc không duyệt";
    } else if (statusCheck === true && ownerChecked === true) {
      return "Người giao việc đã duyệt";
    }
  };

  useEffect(() => {
    _handleTaskContentHistory();
  }, [taskContents, taskContentsHistory]);

  const _handleTaskContentHistory = () => {
    if (taskContents.length > 0 && taskContentsHistory.length > 0) {
      let _taskContent: any[] = [];
      let foundObject: any[] = [];
      let _foundObject: any;

      _taskContent = taskContents.map((item1: any) => {
        foundObject = taskContentsHistory.filter(
          (item2: any) => item1.ID === item2.Task_ContentID
        );
        if (foundObject) {
          //console.log(getMaxValueIndex(foundObject));

          _foundObject = foundObject.find(
            (x: any, index: any) =>
              x.Checked === null || index == foundObject.length - 1
          );

          return { ...item1, taskContentsHistory: _foundObject };
        } else {
          return { ...item1 };
        }
      });
      set_TaskContents(_taskContent);
    } else {
      set_TaskContents(taskContents);
    }
  };

  // const _onChangeMultipleChoicePartner = (text: string) => {
  //   handleOpenSub();
  //   setFuncsSub(text);
  // }

  const _onClickChooseFileChat = () => {
    uploadFileChat.current.click();
  };

  const _onClickSubModal = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const _onCheckResult = (id: any, taskId: any, checked: boolean) => {
    let resultChecking: boolean;

    if (checked === true) {
      resultChecking = false;
    } else {
      resultChecking = true;
    }

    const dispatch = useDispatch();
    const workingData = useSelector((state: any) => state.workingReducer);

    const checkResult = async () => {
      const body: any = {
        ID: id,
        TaskID: taskId,
        Checked: resultChecking,
      };

      try {
        await ServiceUtils.postV2(NGUOI_NHAN_FILE_STEP_UPLOAD, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.status === 200) {
            const API = NGUOI_NHAN_VIEC_DETAIL(taskId);

            const takeData = async () => {
              try {
                await ServiceUtils.getV2(API, {
                  headers: {
                    Accept: "text/plain",
                  },
                }).then((res: any) => {
                  if (res.status === 200) {
                    setTask(res.data.Data.task);
                    setTaskContents(res.data.Data.taskContents);
                    setTaskResults(res.data.Data.taskResults);
                    setTaskInfoText(res.data.Data.taskInfoText);

                    const fileListResponse =
                      res.data.Data.task.ListFiles.split(";");
                    const fileListNameResponse =
                      res.data.Data.task.ListFilesName.split(",");

                    let tempFileArray: any[] = [];

                    fileListNameResponse.forEach((item: any, index: any) => {
                      let tempObject = {
                        listFile: fileListResponse[index],
                        listFileName: item,
                      };

                      tempFileArray.push(tempObject);
                    });

                    setFileListAPI(tempFileArray);
                  }
                });
              } catch (error: any) {}
            };

            takeData();
          }
        });
      } catch (error: any) {}
    };

    checkResult();
  };

  const _onChangeFileImage = (e: any) => {
    const files = e.target.files || [];

    let tempArray: any[] = [...filesList] || [];

    for (let i = 0; i < files.length; i++) {
      tempArray.push(
        new File([files[i]], files[i].name, { type: files[i].type })
      );
    }

    setFilesList(tempArray);
  };

  // const _onChangeFileStep = (id: any, taskId: any, e: any) => {
  //   const files = e.target.files || [];

  //   let tempArray: any[] = [...filesList] || [];

  //   for (let i = 0; i < files.length; i++) {
  //     tempArray.push(
  //       new File([files[i]], files[i].name, { type: files[i].type })
  //     );
  //   }

  //   // call api
  //   const formData = new FormData();

  //   formData.append("ID", id);
  //   formData.append("TaskID", taskId);

  //   if (filesList) {
  //     filesList.forEach((item: any, index: any) => {
  //       formData.append(`File_ListFileResult`, item);
  //     });
  //   }

  //   formData.append("ListNameFileResult", "");

  //   const takeData = async () => {
  //     const accessToken = localStorage.getItem("accessToken");

  //     try {
  //       await axios
  //         .post(NGUOI_NHAN_FILE_STEP_UPLOAD, formData, {
  //           headers: {
  //             Authorization: "Bearer " + accessToken,
  //             Accept: "text/plain",
  //             "Content-Type": "multipart/form-data",
  //           },
  //         })
  //         .then((res: any) => {
  //           toast.success("Tải tệp tin thành công");
  //         });
  //     } catch (error: any) {
  //       toast.error("Tải tệp tin thất bại");
  //     }
  //   };

  //   takeData();
  // };

  const _onClickSentChat = () => {
    if (text === "" && filesList.length <= 0) {
      // do nothing
    } else {
      const accessToken = localStorage.getItem("accessToken");

      const formData = new FormData();

      formData.append("TaskID", taskId);
      formData.append("Contents", text);
      formData.append("typeofNote", selectedTypeOf.toString());

      if (filesList) {
        filesList.forEach((item: any, index: any) => {
          formData.append(`File_ListFile`, item);
        });
      }

      const sentCode = async () => {
        try {
          await ServiceUtils.postFormDataV2(SENT_MESSAGE, formData, {
            headers: {
              Accept: "text/plain",
              "Content-Type": "multipart/form-data",
            },
          }).then((res: any) => {
            if (res.data.StatusCode === 200) {
              const API = NGUOI_NHAN_VIEC_DETAIL(taskId);
              const takeData = async () => {
                try {
                  await ServiceUtils.getV2(API, {
                    headers: {
                      Accept: "text/plain",
                    },
                  }).then((res: any) => {
                    if (res.status === 200) {
                      setTask(res.data.Data.task);
                      setTaskContents(res.data.Data.taskContents);
                      setTaskResults(res.data.Data.taskResults);
                      setTaskInfoText(res.data.Data.taskInfoText);

                      const fileListResponse =
                        res.data.Data.task.ListFiles.split(";");
                      const fileListNameResponse =
                        res.data.Data.task.ListFilesName.split(",");

                      let tempFileArray: any[] = [];

                      fileListNameResponse.forEach((item: any, index: any) => {
                        let tempObject = {
                          listFile: fileListResponse[index],
                          listFileName: item,
                        };

                        tempFileArray.push(tempObject);
                      });

                      setFileListAPI(tempFileArray);
                    }
                  });
                } catch (error: any) {
                  // toast.error("Tải dữ liệu thất bại")
                }
              };

              takeData();

              setSelectedTypeOf(0);
              setText("");
              setFilesList([]);
            } else {
              toast.error(res.data.Message);
            }
          });
        } catch (error: any) {}
      };

      sentCode();
    }
  };

  const dispatch = useDispatch();
  const data = useSelector((state: any) => state.workingReducer);

  const getDetail = (isStart: any) => {
    const API = NGUOI_NHAN_VIEC_DETAIL(taskId);
    if (isStart === 1) {
      if (uuTienList === null || doMatList === null) {
      } else {
        if (shouldLog.current) {
          shouldLog.current = false;

          const takeData = async () => {
            try {
              await ServiceUtils.getV2(API, {
                headers: {
                  Accept: "text/plain",
                },
              }).then((res: any) => {
                if (res.status === 200) {
                  if (res.data.Data.task.CountGetId === 3) {
                    readList(
                      NGUOI_NHAN_VIEC_LIST_RECEIVED,
                      data.pageCurrent,
                      data.choosePage,
                      data.searchFilter.taskName,
                      data.searchFilter.selectedPrior,
                      data.searchFilter.selectedReceiver,
                      data.searchFilter.fromDate,
                      data.searchFilter.toDate,
                      data.searchFilter.deadLine,
                      {
                        current: true,
                      },
                      urlLink,
                      (res: any) => {
                        dispatch(ONCHANGE_NGUOI_NHAN_DA_NHAN(res.data.Data));
                      }
                    );
                    toast.success("Bắt đầu công việc thành công.");
                  }

                  setTask(res.data.Data.task);
                  setTaskContents(res.data.Data.taskContents);
                  setTaskContentsHistory(res.data.Data.taskContentHistory);
                  setTaskResults(res.data.Data.taskResults);
                  //setTaskInfoText(res.data.Data.taskInfoText)

                  let fileListResponse: any[] = [];
                  let fileListNameResponse: any[];
                  if (
                    res.data.Data.task.ListFiles &&
                    res.data.Data.task.ListFilesName
                  ) {
                    fileListResponse = res.data.Data.task.ListFiles.split(";");
                    fileListNameResponse =
                      res.data.Data.task.ListFilesName.split(";");

                    let tempFileArray: any[] = [];
                    if (
                      fileListResponse.length > 0 &&
                      fileListNameResponse.length > 0
                    ) {
                      fileListNameResponse.forEach((item: any, index: any) => {
                        let tempObject: any;
                        if (item) {
                          tempObject = {
                            listFile: fileListResponse[index],
                            listFileName: item,
                          };
                        }
                        tempFileArray.push(tempObject);
                      });
                      setFileListAPI(tempFileArray);
                    }
                  }

                  let fileListResponse2: any[] = [];
                  let fileListNameResponse2: any[] = [];
                  if (
                    res.data.Data.task.ResultFiles &&
                    res.data.Data.task.ResultFilesName
                  ) {
                    fileListResponse2 =
                      res.data.Data.task.ResultFiles.split(";");
                    fileListNameResponse2 =
                      res.data.Data.task.ResultFilesName.split(";");
                    let tempFileArray2: any[] = [];
                    if (
                      fileListNameResponse2.length > 0 &&
                      fileListResponse2.length > 0
                    ) {
                      fileListNameResponse2.map((item: any, index: any) => {
                        let tempObject: any;
                        if (
                          item !== "" &&
                          item !== null &&
                          typeof item !== "undefined"
                        ) {
                          tempObject = {
                            listFile: fileListResponse2[index],
                            listFileName: item,
                          };
                        }
                        if (tempObject) {
                          tempFileArray2.push(tempObject);
                        }
                      });
                      setFileListAPI2(tempFileArray2);
                    }
                  }

                  let _taskInfoText: any[] = res.data.Data.taskInfoText;
                  // debugger

                  let fileListResponseChat: any[] = [];
                  let fileListResponseNameChat: any[] = [];
                  if (_taskInfoText.length > 0) {
                    let tempFileArrFileChat: any[] = [];
                    _taskInfoText.map((item: any) => {
                      if (item.ListFile && item.ListFileName) {
                        fileListResponseChat = item.ListFile.split(";");
                        fileListResponseNameChat = item.ListFileName.split(";");

                        let arr: any[] = [];
                        if (
                          fileListResponseNameChat.length > 0 &&
                          fileListResponseChat.length > 0
                        ) {
                          fileListResponseNameChat.map(
                            (itemC: any, index: any) => {
                              let tempObject: any;

                              if (
                                itemC !== "" &&
                                itemC !== null &&
                                typeof itemC !== "undefined"
                              ) {
                                tempObject = {
                                  listFile: fileListResponseChat[index],
                                  listFileName: itemC,
                                };
                              }
                              if (tempObject) {
                                arr.push(tempObject);
                              }
                            }
                          );

                          if (arr) {
                            tempFileArrFileChat.push({
                              ...item,
                              listFileInChat: arr,
                            });
                          } else {
                            tempFileArrFileChat.push({
                              ...item,
                              listFileInChat: [],
                            });
                          }
                        }
                      } else {
                        tempFileArrFileChat.push({
                          ...item,
                          listFileInChat: [],
                        });
                      }
                    });
                    setTaskInfoText(tempFileArrFileChat);
                  }
                }
              });
            } catch (error: any) {
              // ERROR: here
            }
          };

          takeData();
        }
      }
    } else {
      const takeData = async () => {
        try {
          await ServiceUtils.getV2(API, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.status === 200) {
              setTask(res.data.Data.task);
              setTaskContents(res.data.Data.taskContents);
              setTaskContentsHistory(res.data.Data.taskContentHistory);
              setTaskResults(res.data.Data.taskResults);
              //setTaskInfoText(res.data.Data.taskInfoText)

              let fileListResponse: any[] = [];
              let fileListNameResponse: any[];
              if (
                res.data.Data.task.ListFiles &&
                res.data.Data.task.ListFilesName
              ) {
                fileListResponse = res.data.Data.task.ListFiles.split(";");
                fileListNameResponse =
                  res.data.Data.task.ListFilesName.split(";");

                let tempFileArray: any[] = [];
                if (
                  fileListResponse.length > 0 &&
                  fileListNameResponse.length > 0
                ) {
                  fileListNameResponse.forEach((item: any, index: any) => {
                    let tempObject: any;
                    if (item) {
                      tempObject = {
                        listFile: fileListResponse[index],
                        listFileName: item,
                      };
                    }
                    tempFileArray.push(tempObject);
                  });
                  setFileListAPI(tempFileArray);
                }
              }

              let fileListResponse2: any[] = [];
              let fileListNameResponse2: any[] = [];
              if (
                res.data.Data.task.ResultFiles &&
                res.data.Data.task.ResultFilesName
              ) {
                fileListResponse2 = res.data.Data.task.ResultFiles.split(";");
                fileListNameResponse2 =
                  res.data.Data.task.ResultFilesName.split(";");
                let tempFileArray2: any[] = [];
                if (
                  fileListNameResponse2.length > 0 &&
                  fileListResponse2.length > 0
                ) {
                  fileListNameResponse2.map((item: any, index: any) => {
                    let tempObject: any;
                    if (
                      item !== "" &&
                      item !== null &&
                      typeof item !== "undefined"
                    ) {
                      tempObject = {
                        listFile: fileListResponse2[index],
                        listFileName: item,
                      };
                    }
                    if (tempObject) {
                      tempFileArray2.push(tempObject);
                    }
                  });
                  setFileListAPI2(tempFileArray2);
                }
              }

              let _taskInfoText: any[] = res.data.Data.taskInfoText;
              // debugger

              let fileListResponseChat: any[] = [];
              let fileListResponseNameChat: any[] = [];
              if (_taskInfoText.length > 0) {
                let tempFileArrFileChat: any[] = [];
                _taskInfoText.map((item: any) => {
                  if (item.ListFile && item.ListFileName) {
                    fileListResponseChat = item.ListFile.split(";");
                    fileListResponseNameChat = item.ListFileName.split(";");

                    let arr: any[] = [];
                    if (
                      fileListResponseNameChat.length > 0 &&
                      fileListResponseChat.length > 0
                    ) {
                      fileListResponseNameChat.map((itemC: any, index: any) => {
                        let tempObject: any;

                        if (
                          itemC !== "" &&
                          itemC !== null &&
                          typeof itemC !== "undefined"
                        ) {
                          tempObject = {
                            listFile: fileListResponseChat[index],
                            listFileName: itemC,
                          };
                        }
                        if (tempObject) {
                          arr.push(tempObject);
                        }
                      });

                      if (arr) {
                        tempFileArrFileChat.push({
                          ...item,
                          listFileInChat: arr,
                        });
                      } else {
                        tempFileArrFileChat.push({
                          ...item,
                          listFileInChat: [],
                        });
                      }
                    }
                  } else {
                    tempFileArrFileChat.push({ ...item, listFileInChat: [] });
                  }
                });
                setTaskInfoText(tempFileArrFileChat);
              }
            }
          });
        } catch (error: any) {
          // ERROR: here
        }
      };

      takeData();
    }
  };

  const _handleColor = (statusCheck: any, ownerChecked: any) => {
    if (statusCheck === false && ownerChecked === null) {
      return "#a855f7";
    } else if (statusCheck === true && ownerChecked === null) {
      return "#FFAA00";
    } else if (statusCheck === null && ownerChecked === null) {
      return "#78716c";
    } else if (statusCheck === true && ownerChecked === false) {
      return "#ff0000";
    } else if (statusCheck === true && ownerChecked === true) {
      return "#00ff00";
    }
  };

  const chatContainerRef = useRef<any>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [taskInfoText]);

  useEffect(() => {
    getDetail(1);
  });

  useEffect(() => {
    if (uuTienList === null || doMatList === null) {
      if (shouldLog2.current) {
        const accessToken = localStorage.getItem("accessToken");
        shouldLog2.current = false;

        const takeUuTien = async () => {
          try {
            await ServiceUtils.getV2(PRIOR_LIST_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              setUuTienList(res.data.Data);
            });
          } catch (error: any) {
            toast.error("Tải dữ liệu thất bại");
          }
        };

        const takeDoMat = async () => {
          try {
            await ServiceUtils.getV2(SECURITY_READ_ALL_DROPDOWN, {
              headers: {
                Accept: "text/plain",
              },
            }).then((res: any) => {
              setDoMatList(res.data.Data);
            });
          } catch (error: any) {
            toast.error(
              "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
            );
          }
        };

        takeUuTien();
        takeDoMat();
      }
    }
  });

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[80rem] w-screen h-[90vh]
      rounded-md
    "
    >
      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskId={taskId}
        taskContentsHistory={taskContentsHistory}
        taskContentHistoryId={taskContentHistoryId}
        _taskContents={_taskContents}
        taskInfoText={taskInfoText}
      />

      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <div className="flex space-x-2 items-center">
          <DescriptionTwoToneIcon />
          <p>Chi tiết</p>
        </div>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      {task === null ? (
        <div className="flex justify-center items-center w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm">
          <LoaderSpinner h={32} w={32} />
          <p className="text-primary">Đang tải biểu mẫu</p>
        </div>
      ) : (
        <>
          <div className="lg:flex hidden w-full lg:h-[calc(100%-6rem)] sm:h-screen overflow-hidden p-4 text-sm">
            <div className="styled-scrollbars flex flex-col space-y-3 w-3/5 h-full pr-5 overflow-auto border-r-[1px] border-primary ">
              <p className="font-semibold text-black bg-slate-200 pl-1 pr-1 pt-1.5 pb-1.5 w-max rounded-sm shadow-sm">
                Mã công việc - {task === null ? "" : task.TaskCode}
              </p>

              {/* elements */}
              <div className="flex flex-col w-full h-max">
                <label>Tên công việc</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập tên công việc"
                  value={task === null ? "" : task.TaskName}
                  disabled
                />
              </div>

              <div className="flex w-full h-max space-x-2">
                <div className="w-1/2 h-max flex flex-col">
                  <label>
                    Mức độ&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${
                      task === null ? "" : "font-bold"
                    }`}
                    value={task === null ? -1 : task.PriorityLevelID}
                    disabled
                    style={{
                      color: `${task === null ? "" : task.PriorityLevelColor}`,
                      borderColor: `${
                        task === null ? "" : task.PriorityLevelColor
                      }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn mức độ ---
                    </option>
                    {uuTienList === null ? (
                      <></>
                    ) : (
                      uuTienList.map((item: any, index: any) => (
                        <option key={index} value={item.Id}>
                          {item.PriorityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-1/2 h-max flex flex-col">
                  <label>Độ mật</label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${
                      task === null ? "" : "font-bold"
                    }`}
                    value={task === null ? -1 : task.SecurityLevelID}
                    disabled
                    style={{
                      color: `${task === null ? "" : task.SecurityLevelColor}`,
                      borderColor: `${
                        task === null ? "" : task.SecurityLevelColor
                      }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn độ mật ---
                    </option>
                    {doMatList === null ? (
                      <></>
                    ) : (
                      doMatList.map((item: any, index: any) => (
                        <option key={index} value={item.Id}>
                          {item.SecurityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>

              <div className="flex w-full h-max space-x-2">
                <div className="w-1/2 h-max flex flex-col">
                  <label>Người giao</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    value={task === null ? "" : task.TaskOwnerName}
                    disabled
                  />
                </div>
                <div className="w-1/2 h-max flex flex-col">
                  <label>Người nhận</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    placeholder="Nhập tên công việc"
                    value={task === null ? "" : task.RecipientName}
                    disabled
                  />
                </div>
              </div>

              <div className="flex w-full h-max space-x-2">
                <div className="w-full h-max flex flex-col">
                  <label>Thời hạn</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    type="datetime-local"
                    value={task === null ? "" : task.DeadLine}
                    disabled
                  />
                </div>
              </div>
              <div className="w-full h-max">
                <label>Thời gian thực hiện</label>
                <div className="flex w-full h-max space-x-2">
                  <div className="w-1/2 h-max flex flex-col">
                    <label>Số giờ</label>
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                      value={task === null ? "" : task.HoursUsed}
                      disabled
                    />
                  </div>
                  <div className="w-1/2 h-max flex flex-col">
                    <label>Số phút</label>
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                      value={task === null ? "" : task.MinutesUsed}
                      disabled
                    />
                  </div>
                </div>
              </div>

              {task.SupporterName !== null ? (
                <div className="flex flex-col w-full h-max">
                  <label>Người phối hợp</label>
                  <textarea
                    className="w-full h-max rounded-md pt-2 px-2 border-2 border-slate-200"
                    value={task.SupporterName}
                    disabled
                  />
                </div>
              ) : null}

              <div className="flex flex-col w-full h-max">
                <label>Mô tả</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập mô tả"
                  value={task === null ? "" : task.Description}
                  disabled
                />
              </div>

              <div className="flex flex-col w-full h-max">
                <label>Ghi chú</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập ghi chú"
                  value={task === null ? "" : task.Notes}
                  disabled
                />
              </div>

              <div className="flex flex-col w-full h-max">
                <p>Bước thực hiện 1</p>
                <table className="w-full">
                  <thead className="text-white font-semibold text-center w-full h-[2rem]">
                    <tr>
                      <th className="border border-slate-300 ">Bước</th>
                      <th className="border border-slate-300">Nội dung</th>
                      <th className="border border-slate-300">Thời hạn</th>
                    </tr>
                  </thead>
                  <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                    {_taskContents === null ? (
                      <></>
                    ) : (
                      _taskContents.map((item: any, index: any) => (
                        <>
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="text-center border border-slate-300">
                              {item.SortOrder}
                            </td>
                            <td className="border border-slate-300">
                              <p>{item.StepName}</p>
                              <p>
                                <i>
                                  Người thực hiện: <b>{item.UserDoName}</b>
                                </i>
                              </p>
                            </td>

                            <td className="text-center border border-slate-300">
                              <p>
                                {moment(item.DeadLine).format("DD/MM/YYYY")}
                              </p>
                              <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                            </td>
                          </tr>
                        </>
                      ))
                    )}
                  </tbody>
                </table>
              </div>

              {fileListAPI.length !== 0 && fileListAPI !== null && (
                <div className="flex w-full h-max">
                  <div className="flex flex-col w-1/2 h-max">
                    <label>File đính kèm</label>
                    <div className="flex flex-col w-full h-max">
                      {/* element */}

                      {fileListAPI.map((item: any, index: any) => (
                        <>
                          <div
                            key={index}
                            className="flex w-full h-max text-primary items-center justify-between"
                          >
                            <a
                              href={item}
                              target="_blank"
                              rel="noreferrer"
                              className="flex w-5/6 h-max space-x-1"
                            >
                              <InsertDriveFileTwoToneIcon fontSize="small" />
                              <p>{item.listFileName}</p>
                            </a>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              <div className="flex w-full h-max">
                {/* elements */}
                <div className="flex flex-col w-max h-max space-y-3">
                  {taskResults !== null && taskResults.length > 0 && (
                    <>
                      <label>Kết quả cần đạt</label>
                      {/* element */}
                      {taskResults.map((item: any, index: any) => (
                        <div className="h-max flex flex-col">
                          <div
                            key={index}
                            className="flex w-full h-max text-primary items-center justify-between"
                          >
                            <div className="flex w-max h-max space-x-1">
                              {/* <DoneIcon fontSize="small" /> */}
                              <input
                                type="checkbox"
                                className="w-5 h-5"
                                checked={
                                  item.Checked === null ? false : item.Checked
                                }
                                onClick={() =>
                                  _onCheckResult(
                                    item.ID,
                                    item.TaskID,
                                    item.Checked
                                  )
                                }
                                disabled={item.Checked ? true : false}
                              />
                              <p>{item.ResultText}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}

                  {/* fields of file */}
                  {fileListAPI2.length !== 0 && (
                    <div className="flex flex-col w-full h-max">
                      {/* element */}
                      <label>File kết quả</label>
                      <div className="flex w-full h-max text-primary items-center justify-between">
                        <div className="flex flex-col w-full h-max">
                          {fileListAPI2.map((item: any, index: any) => (
                            <div key={index} className="flex w-full h-max">
                              <a
                                href={item.listFile}
                                target="_blank"
                                rel="noreferrer"
                                className="flex w-5/6 h-max space-x-1"
                              >
                                <InsertDriveFileTwoToneIcon fontSize="small" />
                                <p>{item.listFileName}</p>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-col w-1/2 h-max"></div>
              </div>
            </div>

            {/* chatting */}
            <div className="styled-scrollbars w-2/5 h-full pl-5 flex flex-col space-y-3 overflow-hidden">
              <div className="styled-scrollbars relative flex flex-col w-full h-full">
                <div className="flex absolute top-0 w-full h-[1.5rem] bg-white font-bold mb-2">
                  <p>THÔNG TIN TRAO ĐỔI</p>
                </div>

                <div
                  className="styled-scrollbars absolute top-[1.5rem] bottom-[6.5rem] w-full h-[calc(100%-8rem)] overflow-auto flex flex-col space-y-2"
                  ref={chatContainerRef}
                >
                  {taskInfoText.length <= 0 ? (
                    <></>
                  ) : (
                    taskInfoText.map((item: any, index: any) => (
                      <>
                        <div
                          key={index}
                          className={
                            item.UserAdd === appData.userData.Id
                              ? "flex flex-col w-full h-max justify-end items-end"
                              : "flex flex-col w-full h-max justify-start items-start"
                          }
                        >
                          <p className="text-xs text-slate-500">
                            <b>{item.UserAddName ? item.UserAddName : ""}</b>
                          </p>
                          <div
                            className={
                              item.UserAdd === appData.userData.Id
                                ? "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-primary pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-white"
                                : item.typeofNote === 0
                                ? "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-slate-200 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black"
                                : item.typeofNote === 1
                                ? "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-green-200 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black"
                                : "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-red-200 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black"
                            }
                          >
                            <div className="w-full h-full flex flex-col">
                              <p>{item.Contents}</p>
                              {(item.listFileInChat || {}).length > 0 ? (
                                <>
                                  {(item.listFileInChat || []).map(
                                    (itemFChat: any, indexFChat: any) => (
                                      <a
                                        key={indexFChat}
                                        className={
                                          item.UserAdd === appData.userData.Id
                                            ? `text-white text-xs hover:underline`
                                            : `text-primary text-xs hover:underline`
                                        }
                                        href={itemFChat.listFile}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <FindInPageIcon fontSize="small" />
                                        {itemFChat.listFileName}
                                      </a>
                                    )
                                  )}
                                </>
                              ) : null}
                            </div>
                            <p className="text-left text-[12px] text-white-500">
                              {moment(item.DateAdd).format("HH:mm:ss")} -{" "}
                              {moment(item.DateAdd).format("DD/MM/YYYY")}
                            </p>
                          </div>
                        </div>
                      </>
                    ))
                  )}
                </div>

                <div className="flex flex-col absolute bottom-0 w-full h-[6.5rem] bg-slate-100 mt-2 rounded-sm">
                  <div className="flex items-center w-full h-[2rem]">
                    {/* elements */}
                    {/* <button className="flex justify-center items-center w-[2rem] h-full text-slate-500 hover:text-slate-400">
                      <input
                        className={`w-max h-max`}
                        multiple type='file'
                      />
                      <UploadFileTwoToneIcon fontSize="small" />
                    </button> */}
                    <div className="flex w-max h-full space-x-2 text-xs">
                      <button
                        className="flex items-center p-2 bg-primary rounded-md text-white hover:bg-sky-400 "
                        onClick={_onClickChooseFileChat}
                      >
                        <AttachFileIcon fontSize="small" />
                        {/* <p>Chọn tệp tin</p> */}
                        <p>Chọn tệp tin đính kém</p>
                      </button>
                    </div>
                    <input
                      ref={uploadFileChat}
                      className={`w-max h-max`}
                      multiple
                      type="file"
                      onChange={(e: any) => _onChangeFileImage(e)}
                      hidden
                    />
                    <p
                      style={{
                        marginLeft: 5,
                        marginRight: 5,
                      }}
                    >
                      {filesList.length > 0
                        ? filesList.length + " tệp tin được chọn"
                        : ""}
                    </p>

                    <div className="flex w-max h-full space-x-2 text-xs">
                      <button
                        className={`
                        flex justify-center pl-1 pr-1 items-center w-max h-full text-slate-500 hover:text-slate-400
                        ${selectedTypeOf === 0 ? "bg-slate-200" : ""}
                      `}
                        onClick={() => setSelectedTypeOf(0)}
                      >
                        <p>Bình thường</p>
                      </button>
                      <button
                        className={`
                        flex justify-center pl-1 pr-1 items-center w-max h-full text-green-500 hover:text-green-400
                        ${selectedTypeOf === 1 ? "bg-slate-200" : ""}
                      `}
                        onClick={() => setSelectedTypeOf(1)}
                      >
                        <p>Ưu tiên</p>
                      </button>
                      <button
                        className={`
                        flex justify-center pl-1 pr-1 items-center w-max h-full text-red-500 hover:text-red-400
                        ${selectedTypeOf === 2 ? "bg-slate-200" : ""}
                      `}
                        onClick={() => setSelectedTypeOf(2)}
                      >
                        <p>Khẩn cấp</p>
                      </button>
                    </div>
                  </div>

                  <div className="flex w-full h-[4.5rem]">
                    <div className="w-4/5 flex h-full">
                      <textarea
                        className="w-full h-full rounded-md p-2 border-2 border-slate-200"
                        placeholder="Nhập nội dung"
                        value={text}
                        onChange={(e: any) => {
                          e.preventDefault();
                          setText(e.target.value);
                        }}
                      />
                    </div>

                    <div className="w-1/5 h-full flex justify-center items-center">
                      <button
                        className="bg-primary hover:bg-green-500 w-max h-max p-2 rounded-md font-semibold"
                        onClick={_onClickSentChat}
                      >
                        <div className="flex text-white">
                          <span style={{ paddingRight: 3 }}>Gửi</span>
                          <SendIcon fontSize="small" />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  phone */}
          <div className="lg:hidden flex w-full h-[calc(100%-6rem)] p-0 overflow-hidden text-sm">
            <div className="styled-scrollbars flex flex-col space-y-3 p-4 w-full h-full overflow-auto border-r-[1px] border-primary ">
              {/*  TaskName - TaskCode */}
              <div>
                <p className="flex w-full font-semibold">
                  {task === null ? "" : task.TaskName} -{" "}
                  {task === null ? "" : task.TaskCode}
                </p>
              </div>
              {/*  Uu tien */}
              <div className="flex w-full h-max">
                <div className="w-1/2 h-max flex flex-col pr-2">
                  <label>
                    Mức độ&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${
                      task === null ? "" : "font-bold"
                    }`}
                    value={task === null ? -1 : task.PriorityLevelID}
                    disabled
                    style={{
                      color: `${task === null ? "" : task.PriorityLevelColor}`,
                      borderColor: `${
                        task === null ? "" : task.PriorityLevelColor
                      }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn mức độ ---
                    </option>
                    {uuTienList === null ? (
                      <></>
                    ) : (
                      uuTienList.map((item: any, index: any) => (
                        <option key={index} value={item.Id}>
                          {item.PriorityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-1/2 h-max flex flex-col pl-2">
                  <label>Độ mật</label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${
                      task === null ? "" : "font-bold"
                    }`}
                    value={task === null ? -1 : task.SecurityLevelID}
                    disabled
                    style={{
                      color: `${task === null ? "" : task.SecurityLevelColor}`,
                      borderColor: `${
                        task === null ? "" : task.SecurityLevelColor
                      }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn độ mật ---
                    </option>
                    {doMatList === null ? (
                      <></>
                    ) : (
                      doMatList.map((item: any, index: any) => (
                        <option key={index} value={item.Id}>
                          {item.SecurityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>

              <div className="flex w-full h-max">
                <div className="w-1/2 h-max flex flex-col pr-2">
                  <label>Người giao</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    value={task === null ? "" : task.TaskOwnerName}
                    disabled
                  />
                </div>
                <div className="w-1/2 h-max flex flex-col pl-2">
                  <label>Người nhận</label>
                  <input
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                    value={task === null ? "" : task.RecipientName}
                    disabled
                  />
                </div>
              </div>

              <div className="flex flex-col w-full h-max">
                <label>Thời hạn</label>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  type="datetime-local"
                  value={task === null ? "" : task.DeadLine}
                  disabled
                />
              </div>

              <div className="w-full h-max">
                <label>Thời gian thực hiện</label>
                <div className="flex w-full h-max space-x-2">
                  <div className="w-1/2 h-max flex flex-col">
                    <label>Số giờ</label>
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                      value={task === null ? "" : task.HoursUsed}
                      disabled
                    />
                  </div>
                  <div className="w-1/2 h-max flex flex-col">
                    <label>Số phút</label>
                    <input
                      className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2"
                      value={task === null ? "" : task.MinutesUsed}
                      disabled
                    />
                  </div>
                </div>
              </div>
              {task.SupporterName !== null ? (
                <div className="flex flex-col w-full h-max">
                  <label>Người phối hợp</label>
                  <textarea
                    className="w-full h-max rounded-md px-2 border-2 border-slate-200"
                    value={task.SupporterName}
                    disabled
                  />
                </div>
              ) : null}
              {/*Description*/}
              <div className="flex flex-col w-full h-max">
                <label>Mô tả</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập mô tả"
                  value={task === null ? "" : task.Description}
                  disabled
                />
              </div>
              {/*Noted*/}
              <div className="flex flex-col w-full h-max">
                <label>Ghi chú</label>
                <textarea
                  className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập ghi chú"
                  value={task === null ? "" : task.Notes}
                  disabled
                />
              </div>
              {fileListAPI.length !== 0 && fileListAPI !== null ? (
                <div className="flex w-full h-max">
                  <div className="flex flex-col w-1/2 h-max">
                    <label>File đính kèm</label>
                    <div className="flex flex-col w-full h-max">
                      {/* element */}

                      {fileListAPI.map((item: any, index: any) => (
                        <>
                          <div
                            key={index}
                            className="flex w-full h-max text-primary items-center justify-between"
                          >
                            <a
                              href={item.listFile}
                              target="_blank"
                              rel="noreferrer"
                              className="flex w-5/6 h-max space-x-1"
                            >
                              <InsertDriveFileTwoToneIcon fontSize="small" />
                              <p>{item.listFileName}</p>
                            </a>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
              {/*Step Table*/}
              <div className="flex flex-col w-full h-max">
                <p>Bước thực hiện</p>
                <table className="w-full">
                  <thead className="text-white font-semibold text-center w-full h-[2rem]">
                    <tr>
                      <th className="border border-slate-300 ">Bước</th>
                      <th className="border border-slate-300">Trạng thái</th>
                      <th className="border border-slate-300">Nội dung</th>
                      <th className="border border-slate-300">Thời hạn</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                    {_taskContents === null ? (
                      <></>
                    ) : (
                      _taskContents.map((item: any, index: any) => (
                        <>
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="text-center border border-slate-300">
                              {item.SortOrder}
                            </td>
                            <td className="border border-slate-300">
                              <>
                                {item.taskContentsHistory &&
                                item.taskContentsHistory.IsCompleted ===
                                  true ? (
                                  <>
                                    <div
                                      style={{
                                        backgroundColor: _handleColor(
                                          item.taskContentsHistory.Checked,
                                          item.taskContentsHistory.OwnerChecked
                                        ),
                                        color: "white",
                                        padding: 5,
                                        textAlign: "center",
                                        borderRadius: 13,
                                        width: "fit-content",
                                      }}
                                    >
                                      {/* {_handleChecked(
                                        item.taskContentsHistory.Checked
                                      )} */}
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      backgroundColor: "#00B1FF",
                                      color: "white",
                                      padding: 5,
                                      textAlign: "center",
                                      borderRadius: 13,
                                      width: "fit-content",
                                    }}
                                  >
                                    Chưa có kết quả
                                  </div>
                                )}
                              </>
                            </td>
                            <td className="border border-slate-300">
                              <p>{item.StepName}</p>
                              <p>
                                <i>
                                  Người thực hiện: <b>{item.UserDoName}</b>
                                </i>
                              </p>
                            </td>

                            <td className="text-center border border-slate-300">
                              <p>
                                {moment(item.DeadLine).format("DD/MM/YYYY")}
                              </p>
                              <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                            </td>

                            <td className="text-center border border-slate-300">
                              <>
                                <div className="dropdown">
                                  {/* button options */}
                                  <button className="dropbtn">
                                    <MoreHorizIcon fontSize="small" />
                                  </button>

                                  {/* options fields */}
                                  <div className="dropdown-content text-xs font-semibold">
                                    <button
                                      className="flex w-full h-full hover:text-sky-500"
                                      onClick={() =>
                                        _onChangeMultipleChoicePartner(
                                          "multipleUploadFileContent",
                                          item.ID,
                                          1
                                        )
                                      }
                                    >
                                      {/* detail - contain */}
                                      <div className="flex w-full h-full space-x-1">
                                        {/* icon */}
                                        <HistoryIcon
                                          className="text-sky-600"
                                          sx={{ fontSize: 18 }}
                                          fontSize="small"
                                        />

                                        {/* title */}
                                        <div>Xem lịch sử</div>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </>
                            </td>
                          </tr>
                        </>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              {/* Result */}
              <div className="flex w-full h-max">
                <div className="flex flex-col w-max h-max space-y-3">
                  {taskResults !== null && taskResults.length > 0 && (
                    <>
                      <label>Kết quả cần đạt</label>
                      {taskResults.map((item: any, index: any) => (
                        <div className="h-max flex flex-col">
                          <div
                            key={index}
                            className="flex w-full h-max text-primary items-center justify-between"
                          >
                            <div className="flex w-max h-max space-x-1">
                              {/* <DoneIcon fontSize="small" /> */}

                              <input
                                type="checkbox"
                                className="w-5 h-5"
                                checked={
                                  item.Checked === null ? false : item.Checked
                                }
                                onClick={() =>
                                  _onCheckResult(
                                    item.ID,
                                    item.TaskID,
                                    item.Checked
                                  )
                                }
                                disabled={item.Checked ? true : false}
                              />
                              <p>{item.ResultText}</p>
                            </div>

                            {/* <div className="w-max flex justify-center">
                            <input
                              type="checkbox"
                              checked={item.Checked}
                              onClick={() =>
                                _onCheckResult(
                                  item.ID,
                                  item.TaskID,
                                  item.Checked
                                )
                              }
                              disabled={item.Checked ? true : false}
                            />
                          </div> */}
                          </div>
                        </div>
                      ))}
                    </>
                  )}

                  {fileListAPI2.length !== 0 && (
                    <>
                      <label>File kết quả</label>

                      {/* fields of file */}
                      <div className="flex flex-col w-full h-max">
                        {/* element */}
                        <div className="flex w-full h-max text-primary items-center justify-between">
                          <div className="flex flex-col w-full h-max">
                            {fileListAPI2.map((item: any, index: any) => (
                              <div key={index} className="flex w-full h-max">
                                <a
                                  href={item.listFile}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="flex w-5/6 h-max space-x-1"
                                >
                                  <InsertDriveFileTwoToneIcon fontSize="small" />
                                  <p>{item.listFileName}</p>
                                </a>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* footer */}
      {task === null ? (
        <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm"></div>
      ) : task.Recipient === appData.userData.Id ? (
        <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm">
          <div className="flex h-max w-max space-x-4">
            {urlLink === "/working/receiver/task-processing" ||
            task.TaskStatus === 4 ? (
              <button
                className="
            flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
            hover:bg-green-400
          "
                onClick={() => _onClickSubModal("finishTask")}
              >
                <DoneIcon fontSize="small" />
                <p>Hoàn tất</p>
              </button>
            ) : urlLink === "/working/receiver/task-received" &&
              task.Recipient !== appData.userData.Id ? (
              <></>
            ) : (
              <button
                className="
            flex items-center space-x-1 w-max h-max p-2 bg-cyan-500 text-white rounded-md
            hover:bg-cyan-400
          "
                onClick={() => _onClickSubModal("startTaskSub")}
              >
                <FlagTwoToneIcon fontSize="small" />
                <p>Bắt đầu</p>
              </button>
            )}
            <button
              className="lg:hidden flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md"
              onClick={() =>
                _onChangeMultipleChoicePartner("chatPopup", null, null)
              }
            >
              <SendIcon fontSize="small" />
              <p>Trao đổi</p>
            </button>

            <button
              className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      ) : (
        <div className="flex w-full h-[3rem] bg-slate-100 rounded-b-md justify-end items-center pl-4 pr-4 text-sm">
          <div className="flex h-max w-max space-x-4">
            {urlLink === "/working/receiver/task-processing" &&
            task.Recipient !== appData.userData.Id ? (
              <button
                className="
          flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
          hover:bg-green-400
        "
                onClick={() => _onClickSubModal("finishTask")}
              >
                <DoneIcon fontSize="small" />
                <p>Hoàn tất</p>
              </button>
            ) : (urlLink === "/working/receiver/task-received" &&
                task.Recipient !== appData.userData.Id) ||
              task ? (
              <></>
            ) : (
              <button
                className="
          flex items-center space-x-1 w-max h-max p-2 bg-cyan-500 text-white rounded-md
          hover:bg-cyan-400
        "
                onClick={() => _onClickSubModal("startTaskSub")}
              >
                <FlagTwoToneIcon fontSize="small" />
                <p>Bắt đầu</p>
              </button>
            )}

            <button
              className="
        flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
        hover:bg-slate-200
      "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReceiveTaskDetail;
