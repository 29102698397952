import { useContext } from "react";
import { ModalController } from "../ui/homeUI/_index";
import { NGUOI_GIAO_VIEC_DETAIL } from "../apis/_index";
import ServiceUtils from "../utils/services";
import { toast } from "react-toastify";

export const FuncClickDetailsTask = async (context: any, taskId: string, templateID: any = null) => {
    const userData_: any = localStorage.getItem("userData");
    const _userData: any = (JSON.parse(userData_) || {}).Data || [];
    const userData: any = _userData[0] || {};
    const token: any = localStorage.getItem("accessToken");
    // const context = useContext(ModalController);

    context.setTaskId(taskId);

    let tempTaskStatus: any = null;
    let templatetId: any = null;

    if (
        templateID === 19 ||
        templateID === 20 ||
        templateID === 21
    ) {
        context.setFuncs("proposeDetail");
        context.handleOpen();
        return;
    }
    const API = NGUOI_GIAO_VIEC_DETAIL(taskId);

    let isNguoiNhan = false;
    let isCompleted = false;
    let isTaskChangedDeadLine = false;

    try {
        await ServiceUtils.getV2(API, {
            headers: {
                Accept: "text/plain",
            },
        }).then((res: any) => {
            if (res.data.StatusCode === 200) {

                tempTaskStatus = res?.data?.Data?.task?.TaskStatus;
                templatetId = res?.data?.Data?.task?.TemplateID;
                if (res?.data?.Data?.task?.Recipient == userData.Id) {
                    isNguoiNhan = true;
                }
                if (res?.data?.Data?.taskHistory.length > 0) {
                    isCompleted = true;
                }
                isTaskChangedDeadLine = res?.data?.Data?.task?.IsTaskChangedDeadLine

            }
        });
    } catch (error: any) {
        toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
    }
    if (tempTaskStatus === null) {
        return;
    } else {

        let _funcs = '';
        if (!isNguoiNhan && tempTaskStatus === 9) { //recovery
            _funcs = 'detailTaskRecovery'
        } else if (isNguoiNhan && tempTaskStatus === 9) { //recovery
            _funcs = 'detailTaskRecovery'
        } else if (isTaskChangedDeadLine && !isNguoiNhan) {
            _funcs = 'readTotalTaskReadDealine'
        } else if (
            (tempTaskStatus === 4 && isNguoiNhan)
            || (tempTaskStatus === 5 && isNguoiNhan)) {
            _funcs = 'readTotalTask'
        } else if (tempTaskStatus === 2 || tempTaskStatus === 7) {
            _funcs = 'taskDetailOnly'
        } else if (tempTaskStatus === 3) {
            _funcs = 'receiveTaskDetail'
        } else if ((tempTaskStatus === 4 && !isNguoiNhan && !isCompleted)
            || (tempTaskStatus === 5 && !isNguoiNhan)) {
            _funcs = 'readTotalTask_nguoigiao'
        } else if (tempTaskStatus === 4 && !isNguoiNhan && isCompleted) {
            _funcs = 'taskNeedConfirm'
        } else if (tempTaskStatus === 1 || tempTaskStatus === 0) {
            _funcs = 'editTask'
        }

        context.setFuncs(_funcs)
    }

    context.handleOpen();
};