import React, { useState, useRef, useEffect, useContext } from "react";
import { HexColorPicker } from "react-colorful";
import { SketchPicker } from "react-color";

import { useSelector } from "react-redux";

import { CHiTietTieuChiDanhGia } from "../../../apis/_index";

import { NGUOI_NHAN_TASK_COMPLETE } from "../../../apis/_index";

import { onTouchForm } from "../../../funcs/onTochForm";
import CloseIcon from "@mui/icons-material/Close";

import SubModal from "../_subModal/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import LoaderSpinner from "../../loader-spinner/_index";

import axios from "axios";

import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";

import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

import { readList } from "../../../funcs/taskNguoiGiaoViec";
import { NGUOI_NHAN_VIEC_LIST_PROCESSING } from "../../../apis/_index";
import { ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN } from "../../../redux/reducers/workingReducer/_index";

import { USER_CHANGE_INFO } from "../../../apis/_index";

import { toast } from "react-toastify";
import { paste } from "@testing-library/user-event/dist/paste";
import ServiceUtils from "../../../utils/services";

const AccountIF = (props: any) => {
  const handleClose = props.handleClose;

  const appData = useSelector((state: any) => state.appReducer);

  const [fullName, setFullname] = useState<string>(appData.userData?.FullName);
  const [phoneNumber, setPhoneNumber] = useState<string>(
    appData.userData.PhoneNumber
  );
  const [address, setAddress] = useState<string>(appData.userData.Address);
  const [email, setEmail] = useState<string>(appData.userData.Email);

  const context = useContext(ModalController);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const _onChangeValue = (text: string, e: any) => {
    e.preventDefault();
    switch (text) {
      case "fullName":
        setFullname(e.target.value);
        break;
      case "phoneNumber":
        setPhoneNumber(e.target.value.toString());
        break;
      case "address":
        setAddress(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;

      default:
        break;
    }
  };

  const _onClickSubmit = async () => {
    setIsLoading(true);
    if (
      fullName === "" ||
      phoneNumber === "" ||
      address === "" ||
      email === ""
    ) {
      toast.error("Vui lòng điền đầy đủ thông tin");
    } else {
      setIsLoading(true);

      const accessToken = localStorage.getItem("accessToken");

      const body: any = {
        Id: appData.userData.Id,
        UserName: appData.userData.UserName,
        FullName: fullName,
        Address: address,
        Email: email,
        PhoneNumber: phoneNumber,
        BoPhan: parseInt(appData.userData.BoPhan),
        ChucVu: parseInt(appData.userData.ChucVu),
        PhongBan: parseInt(appData.userData.PhongBan),
        IsActive: true,
        OverloadType: appData.userData.OverloadType,
        OverloadFree: appData.userData.OverloadFree,
        OverloadBusy: appData.userData.OverloadBusy,
        OverloadFull: appData.userData.OverloadFull,
      };

      try {
        await ServiceUtils.postV2(USER_CHANGE_INFO, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.status === 200) {
          } else {
            toast.error("Cập nhật thất bại.");
            setIsLoading(false);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
        setIsLoading(false);
      }
    }
  };

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[34rem] w-screen h-[28rem]
    rounded-md
  "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

      "
      >
        <p>Thông tin tài khoản</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* content */}
      {isLoading === true ? (
        <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4 justify-center items-center">
          <LoaderSpinner w={32} h={32} />
          <p className="text-primary">Đang xử lý dữ liệu</p>
        </div>
      ) : (
        <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4 justify-center items-center ">
          {/* lines */}

          <div className="text-center ">
            <p className="font-semibold text-black text-xl">
              Tài khoản đăng nhập
              <br />
              <span className=" bg-slate-200">
                -- {appData.userData.UserName} --
              </span>{" "}
            </p>
          </div>

          <table className="w-full">
            <tr>
              <td className="w-[20%]">Họ & tên:</td>
              <td>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Họ và tên"
                  value={fullName}
                  onChange={(e: any) => _onChangeValue("fullName", e)}
                  readOnly
                  onTouchStart={(e: any) => onTouchForm(e)}
                ></input>
              </td>
            </tr>

            <tr>
              <td className="w-[25%]">Số điện thoại:</td>
              <td>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Số điện thoại"
                  type="number"
                  value={phoneNumber}
                  onChange={(e: any) => _onChangeValue("phoneNumber", e)}
                  readOnly
                  onTouchStart={(e: any) => onTouchForm(e)}
                ></input>
              </td>
            </tr>

            <tr>
              <td className="w-[20%]">Địa chỉ:</td>
              <td>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Địa chỉ"
                  value={address}
                  onChange={(e: any) => _onChangeValue("address", e)}
                  readOnly
                  onTouchStart={(e: any) => onTouchForm(e)}
                ></input>
              </td>
            </tr>

            <tr>
              <td className="w-[20%]">Email:</td>
              <td>
                <input
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="email@gg.com"
                  value={email}
                  onChange={(e: any) => _onChangeValue("email", e)}
                  readOnly
                ></input>
              </td>
            </tr>
          </table>
        </div>
      )}

      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          {/* <button className={`
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md

        `}

                    >


                    </button>

                    <button className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-emerald-500 text-white rounded-md
          hover:bg-emerald-400
        "
                        // onClick={handleClose}
                        onClick={_onClickSubmit}

                    >
                        <ExitToAppTwoToneIcon fontSize="small" />
                        <p>Xác nhận</p>
                    </button> */}

          <button
            className="flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountIF;
