import { useState, useRef, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ASK_EXTEND } from "../../../apis/_index";

import SubModal from "../_subModal/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import LoaderSpinner from "../../loader-spinner/_index";

import { onTouchForm } from "../../../funcs/onTochForm";

import axios from "axios";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";

import { readList } from "../../../funcs/taskNguoiGiaoViec";
import { NGUOI_NHAN_VIEC_LIST_PROCESSING } from "../../../apis/_index";
import { ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN } from "../../../redux/reducers/workingReducer/_index";

import { toast } from "react-toastify";
import ServiceUtils from "../../../utils/services";
import moment from "moment";

const AskExtend = (props: any) => {
  const taskId = props.taskId;
  const taskCode = props.taskCode;
  const handleClose = props.handleClose;
  const urlLink = window.location.pathname;
  const task = props.task;

  const dispatch = useDispatch();
  const context = useContext(ModalController);

  const [notes, setNotes] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [hours, setHours] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [getHours, setGetHours] = useState<number>(task?.HoursUsed | 0);
  const [isNotChangeHours, setIsNotChangeHours] = useState<boolean>(false);

  const data = useSelector((state: any) => state.appReducer);
  const workingData = useSelector((state: any) => state.workingReducer);

  const shouldLog = useRef(true);

  // Funcs
  const _onChangeNumberHours = (e: any) => {
    e.preventDefault();
    setHours(e.target.value);
  };

  // funcs
  const _onClickSubmit = async () => {
    // const accessToken = localStorage.getItem("accessToken");

    const body: any = {
      TaskID: taskId,
      Reason: notes,
      NewDate: date,
      Hours: isNotChangeHours ? null : hours,
    };

    try {
      setIsLoading(true);
      await ServiceUtils.postV2(ASK_EXTEND, body, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res.status === 200) {
          toast.success("Xác nhận xin gia hạn thành công");
          readList(
            NGUOI_NHAN_VIEC_LIST_PROCESSING,
            workingData.pageCurrent,
            workingData.choosePage,
            workingData.searchFilter.taskName,
            workingData.searchFilter.selectedPrior,
            workingData.searchFilter.selectedReceiver,
            workingData.searchFilter.fromDate,
            workingData.searchFilter.toDate,
            workingData.searchFilter.deadLine,
            shouldLog,
            urlLink,
            (res: any) => {
              dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data));
            }
          );
        } else {
          toast.error("Xác nhận xin gia hạn thất bại");
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    } finally {
      handleClose();
      context.handleClose();
      setIsLoading(false);
    }
  };

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[34rem] w-screen h-[28rem]
    rounded-md
  "
    >
      {/* header */}
      <div
        className="
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Xác nhận gia hạn</p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        {/* lines */}
        {/* <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label>
              Mã công việc&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={taskCode}
              disabled
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div> */}
        <div className="flex flex-col w-full h-max">
          <p>
            Thời hạn hiện tại:{" "}
            <b>{moment(task?.DeadLine).format("hh:mm DD/MM/YYYY")}</b>
          </p>

          {/* <input
            className="w-full h-[2rem] rounded-md p-2 border-2 border-slate-200"
            type="datetime-local"
            value={date}
            onChange={(e: any) => {
              e.preventDefault();
              setDate(e.target.value);
            }}
          /> */}
        </div>
        <div className="flex flex-col w-full h-max">
          <label>
            Thời hạn mới&nbsp;<span className="text-red-500">(*)</span>
          </label>
          <input
            className="w-full h-[2rem] rounded-md p-2 border-2 border-slate-200"
            type="datetime-local"
            value={date}
            onChange={(e: any) => {
              e.preventDefault();
              setDate(e.target.value);
            }}
          />
        </div>

        <div className="flex w-full h-max">
          <p>
            Số giờ hiện tại: <b>{getHours}</b>
          </p>
        </div>
        <div className="flex w-full h-max" style={{ alignItems: "center" }}>
          <input
            type="checkbox"
            checked={isNotChangeHours}
            onChange={() => setIsNotChangeHours(!isNotChangeHours)}
          />
          &nbsp;
          <span className="text-xs mr-1"> Không thay đổi số giờ</span>
        </div>
        {!isNotChangeHours && (
          <div className="flex w-full h-max">
            <div className="flex flex-col w-full h-max">
              <label>
                Số giờ hoàn thành&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <input
                className="w-full p-2 h-[2rem] rounded-md border-2 border-slate-200"
                type="number"
                placeholder="Nhập số giờ hoàn thành"
                value={hours === 0 ? "" : hours}
                onChange={(e: any) => _onChangeNumberHours(e)}
              />
            </div>
          </div>
        )}
        <div className="flex w-full h-max">
          <div className="flex flex-col w-full h-max">
            <label>
              Nội dung gia hạn&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <textarea
              className="w-full h-[10rem] rounded-md p-2 border-2 border-slate-200"
              placeholder="Nhập nội dung gia hạn"
              onChange={(e: any) => {
                e.preventDefault();
                setNotes(e.target.value);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>
      </div>

      {/* footer */}
      <div className="flex footer w-full p-12-16 rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className={`
          flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
          ${
            notes === "" ||
            (!isNotChangeHours && (!hours || hours == 0)) ||
            date === "" ||
            isLoading === true
              ? "bg-slate-300"
              : "bg-green-500 hover:bg-green-400"
          }
        `}
            disabled={
              notes === "" ||
              (!isNotChangeHours && (!hours || hours == 0)) ||
              date === "" ||
              isLoading === true
                ? true
                : false
            }
            onClick={_onClickSubmit}
          >
            {isLoading === true ? (
              <LoaderSpinner w={20} h={20} />
            ) : (
              <p>Xác nhận</p>
            )}
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
            disabled={isLoading}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AskExtend;
