import React from "react";
import { Modal } from "@mui/material";

import PlanElectric from "./plan/_index";

import "../../ui/shared/_index.css";

// sub modal importing
import CreateNewTask from "./working/createNewTask";
import CreateNewElectronicReport from "./working/createNewElectronicReport";
import EditTask from "./working/editTask";
import SwitchTaskToBoss from "./working/switchTaskToBoss";
import GiveTask from "./working/giveTask";
import NotiTask from "./working/notiTask";
import DeleteTask from "./working/deleteTask";
import AlertTask from "./working/alertTask";
import StartTask from "./working/startTask";
import ReceiveTask from "./working/receiveTask";
import ReceiveAndStartTask from "./working/receiveAndStartTask";
import Chart3Detail from "./processing/chart3Detail";
import ReadDeadline from "./working/_readDeadline";
import CreateWorkflow from "./working/createWorkflow";
import ConfirmFinish from "./working/confirmFinish";
import DelayDetail from "./working/delayDetail";
import NeedGive from "./working/needGive";
import TaskDelay from "./working/taskDelay";
import TaskEndToday from "./working/taskEndToday";
import ReceiveTaskDetail from "./working/receiveTaskDetail";
import VotingTaskDetail from "./working/votingTaskDetail";
import VotingResultDetail from "./working/votingResultDetail";
import VotingTask from "./working/votingTask";
import CreateListOfEmployee from "./setting/createListOfEmployee";
import DetailListOfEmployee from "./setting/detailListOfEmployee";
import ListOfTask from "./setting/listOfTask";
import CreateListOfGeneral from "./setting/createListOfGeneral";
import DetailListOfGeneral from "./setting/detailListOfGeneral";
import CreatePrior from "./setting/createPrior";
import DetailPrior from "./setting/detailPrior";
import ReportDetailOwnerAndManagerElectric from "./reportElectric/_detailOwnerAndManger";
import CreateListOfOrganization from "./setting/createListOfOrganization";

import DetailOnLeaving from "./onLeave/detailOnLeaving";

import ReportDetailOwnerAndManagerElectric3 from "./reportElectric/_detailOwnerAndManager3";
import SwitchTask from "./working/switchTask";

import TotalListRecevedSupport from "./report/support/TotalListReceved_Support";

import CreateNewPlan from "./working/createNewPlan";

import ReTaskModal from "./reTask/_index";
import CreateNewLeaving from "./onLeave/createNewLeaving";

import TotalListProccessingSupport from "./report/support/TotalListProccessing_Support";
import CreateElectricReportOwner from "./working/createElectricReportOwner";

import ReportEdit from "./reportElectric/_editReport";
import EditReportInputForm from "./reportElectric/EditReportInputForm";

import CompareStaff from "./staff/CompareStaff";
import CreateDificult from "./setting/createDificultRate";

import CreateTargetRatingCapacity from "./setting/createTargetRatingCapacity";
import CreateTableScoringCapacity from "./setting/createTableScoringCapacity";
import DetailTargetRatingCapacity from "./setting/detailTargetRatingCapacity";
import DetailTableScoringCapacity from "./setting/detailTableScoringCapacity";
import DetailUser from "./setting/detailUser";
import DetailListOfTask from "./setting/detailListOfTask";
import CreateUser from "./setting/createUser";

import TotalTaskListProcessingSupport from "./report/support/TotalTaskListProcessing_Support";

import DeleteDetailGeneral from "./setting/deleteDetailGeneral";
import DetailWorkflow from "./working/detailWorkflow";
import DetailSingleOfGeneral from "./setting/detailSingleOfGeneral";
import DetailPriorSingle from "./setting/detailSinglePrior";
import DeletePriorModal from "./setting/deletePriorModal";

import ReportDetailOwnerAndManagerElectric2 from "./reportElectric/_detailOwnerAndManager2";

import DetailListOfEmployeeSingle from "./setting/detailListSingleEmployee";
import CreateSecurity from "./setting/createSecurity";

import DetailSecurity from "./setting/detailSecurity";
import DetailSecuritySingle from "./setting/detailSecuritySingle";
import DeleteSecurity from "./setting/deleteSecurity";

import DetailUserSingle from "./setting/detailUserSingle";
import DeleteUser from "./_subModal/deleteUser";
import TaskDetail from "./working/taskDetail";
import RequireNewElectronicReport from "./working/requireNewElectronicReport";

import TaskNeedConfirm from "./working/taskNeedConfirm";
import ReadTotalTask from "./working/readTotalTask";
import ReadHistoryExtend from "./working/readHistoryExtend";
import ReadTotalTask_Nguoigiao from "./working/readTotalTask_nguoigiao";

import DeleteDificult from "./setting/deleteDificult";

import DetailDificultRate from "./setting/detailDificultRate";

import DELETE_TIEUCHI from "../../../src/components/modal/_subModal/deleteTieuChi";
import CompleteTask from "./working/completeTask";
import ChangePassword from "./appMoadal/ChangePassword";

import DeleteEmployee from "./working/deleteEmployee";

import AccountIF from "./appMoadal/AccountI_Information";
import TotalListNewSupport from "./report/support/TotalListNew_Support";

import ProposeDetail from "./proposeModal/proposeDetail";
import ConfirmPropose from "./proposeModal/confirmPropose";
import UnConfirmPropose from "./proposeModal/unConfirmPropose";
import CreatePropose from "./proposeModal/createPropose";
import EditElectric from "./reportElectric/_edit";
import TotalTaskComplete from "./report/TotalListNew";

import ViewReport from "./ViewReport";

import CheckPlanning from "./_subModal/checkPlanning";

// report total
import TotalTaskAll from "./report/TotalTaskAll";
import TotalListNew from "./report/TotalListNew";
import TotalListReceved from "./report/TotalListReceved";
import TotalCompletedWaiting from "./report/TotalCompletedWaiting";
import TotalTaskListProcessing from "./report/TotalTaskListProcessing";
import TotalListProccessing from "./report/TotalListProccessing";

import ReportElectric from "./reportElectric/_index";
import EditReport2 from "./reportElectric/_editReport2";

import EditPlanning from "./_subModal/editPlan";
import ViewReason from "./ViewReason";
import { MODAL_KEYS } from "../../constants/constants";
import EditTaskRecoveryAndTransfer from "./working/editTaskRecoveryAndTransfer";
import DetailTaskRecovery from "./working/detailTaskRecovery";
import CreateOrUpdateCategorySuggest from "./setting/createOrUpdateCategorySuggest";
import DeleteCategorySuggestModal from "./setting/deleteCategorySuggest";
import CreateOrUpdateSettingProceduce from "./setting/createOrUpdateSettingProceduce";
import EditTaskTransfer from "./working/editTaskTransfer";
import ReviewHistoryTaskPreviousSubModal from "./_subModal/reviewHistoryTaskPreviousSubModal";
import DetailProcedureStep from "./proposeModal/detailProcedureStep";
import DetailListOfOrganization from "./setting/detailListOfOrganization";
import CreateFile from "./drive/createFolder";
import CreateFolder from "./drive/createFolder";

import Status4 from "./noti/status4";
import RenameTSX from "./drive/renameTSX";
import ShareTSX from "./drive/shareTSX";
import ShowStaffOrganization from "./staff/showStaffOrganization";
import DeleteProcedure from "./procedures/deleteProcedure";
import MoveToTrash from "./drive/moveToTrash";
import DeleteOnTrash from "./drive/deleteOnTrash";
import CreateTaskMyself from "./working/createTaskMyself";
import TaskDetailMyself from "./working/taskDetailMyself";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const ModalBase = (props: any) => {
  // take props

  const funcs = props.funcs;
  const open = props.open;
  const handleClose = props.handleClose;

  // sys attr
  const task = props.task;
  const taskId = props.taskId;
  const taskCode = props.taskCode;
  const maTieuChi = props.maTieuChi;
  const _loadData = props._loadData;
  const dataModal = props.dataModal;
  const userId = props.userId;

  const folderItem = props.folderState;

  // TODO: Setting backdropClick
  const closeModal = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      handleClose(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={style} className="w-max h-max">

        {funcs === "createNewTask" ? (
          <CreateNewTask handleClose={handleClose} />
        ) : funcs === "createTaskMyself" ? (
          <CreateTaskMyself handleClose={handleClose} />
        ) : funcs === "createNewElectronicTask" ? (
          <CreateNewElectronicReport handleClose={handleClose} />
        ) : funcs === "compareStaff" ? (
          <CompareStaff handleClose={handleClose} />
        ) : funcs === "requireElectricReportOwner" ? (
          <RequireNewElectronicReport handleClose={handleClose} />
        ) : funcs === "listListNew" ? (
          <TotalTaskComplete handleClose={handleClose} />
        ) : funcs === "viewReport" ? (
          <ViewReport handleClose={handleClose} />
        ) : funcs === "createNewPlan" ? (
          <CheckPlanning handleClose={handleClose} />
        ) : funcs === "createNewLeaving" ? (
          <CreateNewLeaving handleClose={handleClose} />
        ) : funcs === "createElectricReportOwner" ? (
          <CreateElectricReportOwner handleClose={handleClose} />
        ) : funcs === "switchTaskFromOld" ? (
          <ReTaskModal taskId={taskId} handleClose={handleClose} />
        ) : funcs === "TotalListAll" ? (
          <TotalTaskAll
            userId={userId}
            taskId={taskId}
            dataModal={dataModal}
            handleClose={handleClose}
          />
        ) : funcs === "TotalListNew" ? (
          <TotalListNew
            userId={userId}
            taskId={taskId}
            dataModal={dataModal}
            handleClose={handleClose}
          />
        ) : // EditPlanning
          funcs === "TotalListNew_Support" ? (
            <TotalListNewSupport
              userId={userId}
              taskId={taskId}
              dataModal={dataModal}
              handleClose={handleClose}
            />
          ) : // EditPlanning
            funcs === "TotalListReveced" ? (
              <TotalListReceved
                userId={userId}
                taskId={taskId}
                dataModal={dataModal}
                handleClose={handleClose}
              />
            ) : funcs === "TotalListReveced_Support" ? (
              <TotalListRecevedSupport
                userId={userId}
                taskId={taskId}
                dataModal={dataModal}
                handleClose={handleClose}
              />
            ) : funcs === "detailOnLeaving" ? (
              <DetailOnLeaving taskId={taskId} handleClose={handleClose} />
            ) : funcs === "TotalListReveced" ? (
              <TotalListReceved
                userId={userId}
                taskId={taskId}
                dataModal={dataModal}
                handleClose={handleClose}
              />
            ) : funcs === "TotalListCompletedWaiting" ? (
              <TotalCompletedWaiting
                userId={userId}
                taskId={taskId}
                dataModal={dataModal}
                handleClose={handleClose}
              />
            ) : funcs === "TotalListCompleted" ? (
              <TotalTaskListProcessing
                userId={userId}
                taskId={taskId}
                dataModal={dataModal}
                handleClose={handleClose}
              />
            ) : funcs === "TotalListProccessing" ? (
              <TotalListProccessing
                userId={userId}
                taskId={taskId}
                dataModal={dataModal}
                handleClose={handleClose}
              />
            ) : funcs === "TotalListCompleted_Support" ? (
              <TotalTaskListProcessingSupport
                userId={userId}
                taskId={taskId}
                dataModal={dataModal}
                handleClose={handleClose}
              />
            ) : funcs === "TotalListProccessing_Support" ? (
              <TotalListProccessingSupport
                userId={userId}
                taskId={taskId}
                dataModal={dataModal}
                handleClose={handleClose}
              />
            ) : funcs === "planElectric" ? (
              <PlanElectric
                dataModal={dataModal}
                taskId={taskId}
                handleClose={handleClose}
              />
            ) : funcs === "editPlanning" ? (
              <EditPlanning
                dataModal={dataModal}
                taskId={taskId}
                handleClose={handleClose}
              />
            ) : funcs === "reportElectric" ? (
              <ReportElectric
                dataModal={dataModal}
                taskId={taskId}
                handleClose={handleClose}
              />
            ) : funcs === "reportElectric2" ? (
              <EditReport2 taskId={taskId} handleClose={handleClose} />
            ) : funcs === "editReportInputForm" ? (
              <EditReportInputForm taskId={taskId} handleClose={handleClose} />
            ) : funcs === "reportEdit" ? (
              <ReportEdit
                dataModal={dataModal}
                taskId={taskId}
                handleClose={handleClose}
              />
            ) : funcs === "reportDetailElectric" ? (
              <ReportDetailOwnerAndManagerElectric
                dataModal={dataModal}
                taskId={taskId}
                handleClose={handleClose}
              />
            ) : funcs === "reportDetailElectric2" ? (
              <ReportDetailOwnerAndManagerElectric2
                taskId={taskId}
                handleClose={handleClose}
              />
            ) : funcs === "reportDetailElectric3" ? (
              <ReportDetailOwnerAndManagerElectric3
                taskId={taskId}
                handleClose={handleClose}
              />
            ) : funcs === "editElectric" ? (
              <EditElectric
                dataModal={dataModal}
                taskId={taskId}
                handleClose={handleClose}
              />
            ) : funcs === "editTask" ? (
              <EditTask taskId={taskId} handleClose={handleClose} />
            ) : funcs === "switchTaskToBoss" ? (
              <SwitchTaskToBoss taskId={taskId} handleClose={handleClose} />
            ) : funcs === "giveTask" ? (
              <GiveTask taskId={taskId} handleClose={handleClose} />
            ) : funcs === "switchTask" ? (
              <SwitchTask taskId={taskId} handleClose={handleClose} />
            ) : funcs === "notiTask_Model" ? (
              <NotiTask taskId={taskId} handleClose={handleClose} />
            ) : funcs === "deleteTask" ? (
              <DeleteTask taskId={taskId} handleClose={handleClose} />
            ) : funcs === "alertTask" ? (
              <AlertTask taskId={taskId} handleClose={handleClose} />
            ) : funcs === "receiveTask" ? (
              <ReceiveTask taskId={taskId} handleClose={handleClose} />
            ) : funcs === MODAL_KEYS.receiveTaskAndStart ? (
              <ReceiveAndStartTask taskId={taskId} handleClose={handleClose} />
            ) : funcs === "startTask" ? (
              <StartTask taskId={taskId} handleClose={handleClose} />
            ) : funcs === "deleteEmployee" ? (
              <DeleteEmployee taskId={taskId} handleClose={handleClose} />
            ) : funcs === "ChangePassword" ? (
              <ChangePassword handleClose={handleClose} />
            ) : funcs === "AccountIF" ? (
              <AccountIF handleClose={handleClose} />
            ) : funcs === "deleteTieuChi" ? (
              <DELETE_TIEUCHI
                maTieuChi={maTieuChi}
                taskId={taskId}
                handleClose={handleClose}
              />
            ) : funcs === "taskDetailOnly" ? (
              <TaskDetail taskId={taskId} handleClose={handleClose} />
            ) : funcs === "taskDetaiMyself" ? (
              <TaskDetailMyself taskId={taskId} handleClose={handleClose} />
            ) : funcs === "chart3Detail" ? (
              <Chart3Detail taskId={taskId} handleClose={handleClose} />
            ) : funcs === "createWorkflow" ? (
              <CreateWorkflow handleClose={handleClose} />
            ) : funcs === "confirmFinishTask" ? (
              <ConfirmFinish taskId={taskId} handleClose={handleClose} />
            ) : funcs === "delayDetail" ? (
              <DelayDetail taskId={taskId} handleClose={handleClose} />
            ) : funcs === "needGive" ? (
              <NeedGive taskId={taskId} handleClose={handleClose} />
            ) : funcs === "taskDelay" ? (
              <TaskDelay taskId={taskId} handleClose={handleClose} />
            ) : funcs === "taskEndToday" ? (
              <TaskEndToday taskId={taskId} handleClose={handleClose} />
            ) : funcs === "receiveTaskDetail" ? (
              <ReceiveTaskDetail taskId={taskId} handleClose={handleClose} />
            ) : funcs === "status4" ? (
              <Status4 taskId={taskId} handleClose={handleClose} />
            ) : funcs === "votingTaskDetail" ? (
              <VotingTaskDetail taskId={taskId} handleClose={handleClose} />
            ) : funcs === "votingResult" ? (
              <VotingResultDetail taskId={taskId} handleClose={handleClose} />
            ) : funcs === "votingTask" ? (
              <VotingTask taskId={taskId} handleClose={handleClose} />
            ) : funcs === "taskNeedConfirm" ? (
              <TaskNeedConfirm taskId={taskId} handleClose={handleClose} />
            ) : funcs === "readTotalTask" ? (
              <ReadTotalTask taskId={taskId} handleClose={handleClose} task={task} />
            ) : funcs === "readTotalTask_nguoigiao" ? (
              <ReadTotalTask_Nguoigiao taskId={taskId} handleClose={handleClose} />
            ) : funcs === "readTotalTaskReadDealine" ? (
              <ReadDeadline taskId={taskId} handleClose={handleClose} />
            ) : funcs === "readCompleteTask" ? (
              <CompleteTask taskId={taskId} handleClose={handleClose} />
            ) : funcs === "createDificult" ? (
              <CreateDificult handleClose={handleClose} />
            ) : funcs === "detailDificult" ? (
              <DetailDificultRate taskId={taskId} handleClose={handleClose} />
            ) : funcs === "detailListOfEmployee" ? (
              <DetailListOfEmployee taskId={taskId} handleClose={handleClose} />
            ) : funcs === "createListOfEmployee" ? (
              <CreateListOfEmployee handleClose={handleClose} />
            ) : funcs === "listOfTask" ? (
              <ListOfTask taskId={taskId} handleClose={handleClose} />
            ) : funcs === "detailListOfTask" ? (
              <DetailListOfTask taskId={taskId} handleClose={handleClose} />
            ) : funcs === "createListOfGeneral" ? (
              <CreateListOfGeneral taskId={taskId} handleClose={handleClose} />
            ) : funcs === "detailListOfGeneral" ? (
              <DetailListOfGeneral taskId={taskId} handleClose={handleClose} />
            ) : funcs === "createPrior" ? (
              <CreatePrior handleClose={handleClose} />
            ) : funcs === "deleteDificult" ? (
              <DeleteDificult taskId={taskId} handleClose={handleClose} />
            ) : funcs === "detailPrior" ? (
              <DetailPrior taskId={taskId} handleClose={handleClose} />
            ) : funcs === "detailSingleOfGeneral" ? (
              <DetailSingleOfGeneral taskId={taskId} handleClose={handleClose} />
            ) : funcs === "createTargetCapacity" ? (
              <CreateTargetRatingCapacity handleClose={handleClose} />
            ) : funcs === "detailTargetCapacity" ? (
              <DetailTargetRatingCapacity
                taskId={taskId}
                handleClose={handleClose}
              />
            ) : funcs === "createTableScoringCapacity" ? (
              <CreateTableScoringCapacity handleClose={handleClose} />
            ) : funcs === "detailTableScoringCapacity" ? (
              <DetailTableScoringCapacity
                taskId={taskId}
                handleClose={handleClose}
              />
            ) : funcs === "detailUser" ? (
              <DetailUser taskId={taskId} handleClose={handleClose} />
            ) : funcs === "createUser" ? (
              <CreateUser handleClose={handleClose} />
            ) : funcs === "deleteGeneral" ? (
              <DeleteDetailGeneral taskId={taskId} handleClose={handleClose} />
            ) : funcs === "detailWorkflow" ? (
              <DetailWorkflow taskId={taskId} handleClose={handleClose} />
            ) : funcs === "deletePrior" ? (
              <DeletePriorModal taskId={taskId} handleClose={handleClose} />
            ) : funcs === "detailPriorSingle" ? (
              <DetailPriorSingle taskId={taskId} handleClose={handleClose} />
            ) : funcs === "createSecurity" ? (
              <CreateSecurity handleClose={handleClose} />
            ) : funcs === "detailListOfEmployeeSingle" ? (
              <DetailListOfEmployeeSingle
                taskId={taskId}
                handleClose={handleClose}
              />
            ) : funcs === "detailSecurity" ? (
              <DetailSecurity taskId={taskId} handleClose={handleClose} />
            ) : funcs === "detailSecuritySingle" ? (
              <DetailSecuritySingle taskId={taskId} handleClose={handleClose} />
            ) : funcs === "deleteSecurity" ? (
              <DeleteSecurity taskId={taskId} handleClose={handleClose} />
            ) : funcs === "detailUserSingle" ? (
              <DetailUserSingle taskId={taskId} handleClose={handleClose} />
            ) : funcs === "deleteUser" ? (
              <DeleteUser taskId={taskId} handleClose={handleClose} />
            ) : funcs === "proposeDetail" ? (
              <ProposeDetail taskId={taskId} handleClose={handleClose} />
            ) : funcs === "proposeConfirm" ? (
              <ConfirmPropose
                taskId={taskId}
                handleClose={handleClose}
                _loadData={_loadData}
              />
            ) : funcs === "proposeUnConfirm" ? (
              <UnConfirmPropose
                taskId={taskId}
                handleClose={handleClose}
                _loadData={_loadData}
              />
            ) : funcs === "createPropose" ? (
              <CreatePropose handleClose={handleClose} _loadData={_loadData} />
            ) : funcs === "readHistoryExtend" ? (
              <ReadHistoryExtend taskId={taskId} handleClose={handleClose} />
            ) : funcs === "viewReason" ? (
              <ViewReason taskId={taskId} handleClose={handleClose} />
            ) : funcs === MODAL_KEYS.editTaskRecoveryAndTransfer ? (
              <EditTaskRecoveryAndTransfer
                taskId={taskId}
                handleClose={handleClose}
                taskCode={taskCode}
              />
            ) : funcs === MODAL_KEYS.editTaskTransfer ? (
              <EditTaskTransfer
                taskCode={taskCode}
                taskId={taskId}
                handleClose={handleClose}
              />
            ) : funcs === MODAL_KEYS.detailTaskRecovery ? (
              <DetailTaskRecovery taskId={taskId} handleClose={handleClose} />
            ) : funcs === MODAL_KEYS.createOrUpdateCategorySuggest ? (
              <CreateOrUpdateCategorySuggest
                id={taskId}
                handleClose={handleClose}
              />
            ) : funcs === MODAL_KEYS.deleteCategorySuggest ? (
              <DeleteCategorySuggestModal id={taskId} handleClose={handleClose} />
            ) : funcs === MODAL_KEYS.createOrUpdateSettingProceduce ? (
              <CreateOrUpdateSettingProceduce
                id={taskId}
                handleClose={handleClose}
                data={dataModal}
              />
            ) : funcs === MODAL_KEYS.reviewHistoryTaskPrevious ? (
              <ReviewHistoryTaskPreviousSubModal
                id={taskId}
                handleClose={handleClose}
              />
            ) : funcs === MODAL_KEYS.detailProcedureStep ? (
              <DetailProcedureStep taskId={taskId} handleClose={handleClose} />
            ) : funcs === MODAL_KEYS.createSettingOrganization ? (
              <CreateListOfOrganization taskId={taskId} handleClose={handleClose} />
            ) : funcs === MODAL_KEYS.detailSettingOrganization ? (
              <DetailListOfOrganization taskId={taskId} handleClose={handleClose} />
            ) : funcs === "createFolder" ? (
              <CreateFolder handleClose={handleClose} />
            ) : funcs === "renameDrive" ? (
              <RenameTSX folderItem={folderItem} handleClose={handleClose} />
            ) : funcs === "shareDrive" ? (
              <ShareTSX folderItem={folderItem} handleClose={handleClose} />
            ) : funcs === "moveToTrash" ? (
              <MoveToTrash folderItem={folderItem} handleClose={handleClose} />
            ) : funcs === "deleteOnTrash" ? (
              <DeleteOnTrash folderItem={folderItem} handleClose={handleClose} />
            ) : funcs == MODAL_KEYS.showStaffOrganization ? (
              <ShowStaffOrganization handleClose={handleClose} />
            ) : funcs == MODAL_KEYS.deleteProcedure ? (
              <DeleteProcedure taskId={taskId} handleClose={handleClose} />
            ) : (
              ""
            )}
      </div>
    </Modal>
  );
};

export default ModalBase;
