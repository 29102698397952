import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  ONCHANGE_PAGINATION_TASKLIST,
  ONCHANGE_CHOOSEPAGE,
  ONFILTER_DATA,
  ONCHANGE_VIEC_CAN_GIAO,
} from "../../../redux/reducers/workingReducer/_index";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { ModalController } from "../../../ui/homeUI/_index";
import Pagination from "@mui/material/Pagination";

import { _onClickFuncs, _onClickOther } from "../../../funcs/onClickShowBtn";
import axios from "axios";
import LoaderSpinner from "../../loader-spinner/_index";
import { Checkcolor } from "../../../funcs/checkColor";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  CONFIG_LOADTASKREJECT,
  NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
} from "../../../apis/_index";
import { readList } from "../../../funcs/taskNguoiGiaoViec";
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";

const WorkingTaskNeedGiveJSX = () => {
  _onClickOther();

  // redux
  const settingData = useSelector((state: any) => state.settingReducer);
  const userData = useSelector((state: any) => state.userReducer);
  const workingData = useSelector((state: any) => state.workingReducer);
  const appData = useSelector((state: any) => state.appReducer);

  const dispatch = useDispatch();
  const context = useContext(ModalController);

  const shouldLog = useRef(true);
  const link = window.location.pathname;

  const [taskStatus, setloadtaskreject] = useState<any>(null);

  const _fetchData = (page: any, limit: any) => {
    readList(
      NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
      page,
      limit,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_VIEC_CAN_GIAO(res.data.Data));
      }
    );
  };

  const _onClickEditTaskWithId = (id: any) => {
    context.setFuncs("editTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickSwitchToBoss = (id: any) => {
    context.setFuncs("switchTaskToBoss");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickGiveTask = (id: any) => {
    context.setFuncs("giveTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDeleteTask = (id: any) => {
    context.setFuncs("deleteTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onChangePaginate = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(ONCHANGE_PAGINATION_TASKLIST(value));
  };

  const _onChangeChoosePage = (e: any) => {
    const value = e.target.value;
    dispatch(ONCHANGE_CHOOSEPAGE(value));
    dispatch(ONCHANGE_PAGINATION_TASKLIST(1));
  };

  useEffect(() => {
    _fetchData(workingData.pageCurrent, workingData.choosePage);
  }, [workingData.choosePage, workingData.pageCurrent]);
  useEffect(() => {
    // fetchloadtaskreject()
  }, []);

  async function fetchloadtaskreject() {
    const accessToken = localStorage.getItem("accessToken"); // => Laco rieng biet
    try {
      await ServiceUtils.getV2(CONFIG_LOADTASKREJECT, {
        headers: {
          Accept: "text/plain",
        },
      }).then((res: any) => {
        if (res?.data?.StatusCode === 200) {
          setloadtaskreject(res?.data?.Data[0]?.TaskStatus);
        } else {
          toast.error(res.data.Message);
        }
      });
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
    }
  }
  var permiss: any = localStorage.getItem("menuList");
  permiss = permiss.replace(/'/g, '"'); //replacing all ' with "
  permiss = JSON.parse(permiss);

  let checkPermiss = permiss.find((x: any) => x.FormId == 21);

  // funcs
  const _onClickViewReasonReturnWork = (id: string) => {
    context.setFuncs("viewReason");
    context.setTaskId(id);
    context.handleOpen();
  };

  // h-[calc(100%-10rem)]

  return (
    <>
      {workingData.needGiveList === null ? (
        // <div className="flex justify-center items-center flex-col w-full lg:mt-0 mt-3">
        //   <LoaderSpinner w={32} h={32} />
        //   <p className="text-primary">Đang tải dữ liệu</p>
        // </div>
        <LoadingControl />
      ) : (
        <div className="flex flex-col w-full lg:mt-0 pb-10">
          {" "}
          {/* warm */}
          {/* warm */}
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 w-full h-max items-end justify-between my-1 space-y-1">
            <div className="font-bold">
              <p>
                Tổng:&nbsp;
                {workingData.needGiveList.count} việc
              </p>
              <p>
                Trang hiện tại:&nbsp;
                {workingData.needGiveList.data?.length} việc
              </p>
            </div>

            <div className="flex lg:justify-end md:justify-end w-full h-max items-center space-x-1">
              <p>Hiển thị</p>
              <select
                className="border-2 border-slate-200 rounded-md"
                value={workingData.choosePage}
                onChange={(e: any) => _onChangeChoosePage(e)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <p>dòng</p>
            </div>
          </div>
          <div className="tableFixHead styled-scrollbars w-full lg:min-h-[250px]  shadow-md">
            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="hidden lg:table-cell border border-slate-300 ">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>STT</p>
                      {/* <button className="flex w-max h-max hover:text-slate-200">
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Tên công việc</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskName" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Mức độ</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "priorLevel" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Ngày giao</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "assignmentDate" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Thời hạn</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "deadLine" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>

                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người nhận</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskRecipient" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border border-slate-300"></th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {workingData.needGiveList === null ? (
                  <tr></tr>
                ) : (
                  workingData.needGiveList.data?.map(
                    (item: any, index: any) => (
                      <tr key={index} className="w-max h-[3rem] bg-white">
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          {index +
                            1 +
                            workingData.pageCurrent * workingData.choosePage}
                        </td>
                        <td className="border border-slate-300 space-y-0.5">
                          <p className="lg:hidden font-bold">
                            <span className="lg:hidden">
                              {index +
                                1 +
                                workingData.pageCurrent *
                                  workingData.choosePage}
                              .{" "}
                            </span>
                            {item.TaskName}
                          </p>
                          <p className="sm:flex flex-col md:flex lg:flex ">
                            {item.TaskName}
                            {item.TaskStatus === 7 ? (
                              <>
                                <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">
                                  Việc bị trả lại
                                </p>
                              </>
                            ) : null}
                          </p>
                          <p className="lg:hidden">
                            Người giao: {item.TaskOwnerName}
                          </p>
                          <p className="lg:hidden">
                            Người nhận: {item.RecipientName}
                          </p>
                          {item.SupporterName !== null ? (
                            <p className="lg:hidden">
                              Người hỗ trợ: {item.SupporterName}
                            </p>
                          ) : null}
                          <div className="lg:hidden w-full h-max flex flex-col justify-center">
                            <p>
                              <span className="text-xs">Thời hạn: </span>
                              <span className="italic">
                                {moment(item.AssignmentDate).format("HH:mm")}
                                &nbsp;
                                {moment(item.AssignmentDate).format("DD/MM")}
                              </span>
                              &nbsp;-&nbsp;
                              <span className="italic">
                                {moment(item.DeadLine).format("HH:mm")}&nbsp;
                                {moment(item.DeadLine).format("DD/MM")}
                              </span>
                            </p>
                          </div>
                          <div className="lg:hidden flex justify-between items-center">
                            <p
                              style={{
                                color: Checkcolor(
                                  item.PriorityLevelID,
                                  appData
                                ),
                              }}
                              className={`font-bold mr-2 ${Checkcolor(
                                item.PriorityLevelID,
                                appData
                              )}`}
                            >
                              {item.PriorityLevelName}
                            </p>
                            <div className="dropdown2">
                              {/* button options */}
                              <button
                                className="dropbtn2"
                                onClick={() => _onClickFuncs(index.toString())}
                              >
                                ...
                              </button>

                              {/* options fields */}
                              <div
                                id={index.toString()}
                                className="dropdown-content2 text-xs font-semibold"
                              >
                                {/* detail */}
                                <button
                                  className="flex w-full h-full hover:text-sky-500"
                                  onClick={() =>
                                    _onClickEditTaskWithId(item.ID)
                                  }
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1">
                                    {/* icon */}
                                    <DescriptionTwoToneIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div>Chi tiết / Chỉnh sửa</div>
                                  </div>
                                </button>

                                {/* tranf boss */}
                                <button
                                  className="flex w-full h-full hover:text-amber-500"
                                  onClick={() => _onClickSwitchToBoss(item.ID)}
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1">
                                    {/* icon */}
                                    <CameraFrontTwoToneIcon
                                      className="text-amber-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div>Chuyển việc</div>
                                  </div>
                                </button>

                                {/* delitask */}
                                {(checkPermiss || {}).PerApprove && (
                                  <button
                                    className="flex w-full h-full hover:text-emerald-500"
                                    onClick={() => _onClickGiveTask(item.ID)}
                                  >
                                    {/* detail - contain */}
                                    <div className="flex w-full h-full space-x-1">
                                      {/* icon */}
                                      <SendTwoToneIcon
                                        className="text-emerald-600"
                                        sx={{ fontSize: 18 }}
                                        fontSize="small"
                                      />

                                      {/* title */}
                                      <div>Giao việc</div>
                                    </div>
                                  </button>
                                )}
                                {item?.TaskStatus === 7 ? (
                                  <button
                                    className="flex w-full h-full hover:text-red-500"
                                    onClick={() =>
                                      _onClickViewReasonReturnWork(item.ID)
                                    }
                                  >
                                    {/* detail - contain */}
                                    <div className="flex w-full h-full space-x-1">
                                      {/* icon */}
                                      <RemoveRedEyeIcon
                                        className="text-emerald-600"
                                        sx={{ fontSize: 18 }}
                                        fontSize="small"
                                      />
                                      {/* title */}
                                      <div>Xem lý do bị trả việc </div>
                                    </div>
                                  </button>
                                ) : (
                                  <></>
                                )}

                                {/* delete */}
                                {(checkPermiss || {}).PerDelete && (
                                  <button
                                    className="flex w-full h-full hover:text-red-500"
                                    onClick={() => _onClickDeleteTask(item.ID)}
                                  >
                                    {/* delete - contain */}
                                    <div className="flex w-full space-x-1">
                                      {/* icon */}
                                      <DeleteTwoToneIcon
                                        className="text-red-600"
                                        sx={{ fontSize: 18 }}
                                        fontSize="small"
                                      />

                                      {/* title */}
                                      <div className="h-full">Xoá</div>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <p
                            style={{
                              color: Checkcolor(item.PriorityLevelID, appData),
                            }}
                            className={`font-bold ${Checkcolor(
                              item.PriorityLevelID,
                              appData
                            )}`}
                          >
                            {item.PriorityLevelName}
                          </p>
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <div className="w-full h-max flex flex-col justify-center items-center">
                            <p>{moment(item.DateAdd).format("DD/MM/YYYY")}</p>
                            <p>{moment(item.DateAdd).format("HH:mm:ss")}</p>
                          </div>
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <div className="w-full h-max flex flex-col justify-center items-center">
                            <p>{moment(item.DeadLine).format("DD/MM/YYYY")}</p>
                            <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                          </div>
                        </td>
                        <td className="hidden lg:table-cell border border-slate-300">
                          {item.RecipientName}
                          {item.SupporterName !== null ? (
                            <p className="text-xs">
                              <span className="text-slate-600">Hỗ trợ:</span>{" "}
                              {item.SupporterName}
                            </p>
                          ) : null}
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <div className="dropdown">
                            {/* button options */}
                            <button className="dropbtn">
                              <MoreHorizIcon fontSize="small" />
                            </button>

                            {/* options fields */}
                            <div className="dropdown-content text-xs font-semibold">
                              {/* detail */}
                              <button
                                className="flex w-full h-full hover:text-sky-500"
                                onClick={() => _onClickEditTaskWithId(item.ID)}
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1">
                                  {/* icon */}
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Chi tiết / Chỉnh sửa</div>
                                </div>
                              </button>

                              {/* delitask */}
                              {(checkPermiss || {}).PerApprove && (
                                <button
                                  className="flex w-full h-full hover:text-emerald-500"
                                  onClick={() => _onClickGiveTask(item.ID)}
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1">
                                    {/* icon */}
                                    <SendTwoToneIcon
                                      className="text-emerald-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div>Giao việc </div>
                                  </div>
                                </button>
                              )}

                              {item?.TaskStatus === 7 ? (
                                <button
                                  className="flex w-full h-full hover:text-red-500"
                                  onClick={() =>
                                    _onClickViewReasonReturnWork(item.ID)
                                  }
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1">
                                    {/* icon */}
                                    <RemoveRedEyeIcon
                                      className="text-emerald-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div>Xem lý do bị trả việc </div>
                                  </div>
                                </button>
                              ) : (
                                <></>
                              )}

                              {/* delete */}
                              {(checkPermiss || {}).PerDelete && (
                                <button
                                  className="flex w-full h-full hover:text-red-500"
                                  onClick={() => _onClickDeleteTask(item.ID)}
                                >
                                  {/* delete - contain */}
                                  <div className="flex w-full space-x-1">
                                    {/* icon */}
                                    <DeleteTwoToneIcon
                                      className="text-red-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div className="h-full">Xoá</div>
                                  </div>
                                </button>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  )
                )}
              </tbody>
            </table>
          </div>
          <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem] pt-2">
            <Pagination
              count={
                workingData.paginateTaskList === null
                  ? 0
                  : workingData.paginateTaskList
              }
              page={workingData.pageCurrent + 1}
              variant="outlined"
              color="primary"
              size="small"
              onChange={_onChangePaginate}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WorkingTaskNeedGiveJSX;
