import React, { useState, useContext } from "react";

import {
  NGUOI_GIAO_TASK_RETURN,
  NGUOI_GIAO_UPDATE_DEADLINE,
  NGUOI_GIAO_VIEC_LIST_DELAY,
  NGUOI_GIAO_VIEC_LIST_PROOCCESSING,
  RECOVERY_TASK_API,
  TASK_RECOVERY_LIST,
} from "../../../apis/_index";

import axios from "axios";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import LoaderSpinner from "../../loader-spinner/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import ModalBase from "../../../components/modal/_index";
import { toast } from "react-toastify";
import TaskRepository from "../../../repositories/tasks/taskRepository";
import { readList } from "../../../funcs/taskNguoiGiaoViec";
import { useSelector } from "react-redux";
import {
  ONCHANGE_DANG_TRE_HAN,
  ONCHANGE_NGUOI_GIAO_DANG_XU_LY,
  ONCHANGE_NGUOI_GIAO_RETURN,
  ONLOAD_TASKRECOVERYLIST_ALL,
} from "../../../redux/reducers/workingReducer/_index";
import { useDispatch } from "react-redux";

const RecoveryTaskModal = (props: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const [reason, setReason] = useState("");
  const taskId = props.taskId;
  const taskCode = props.taskCode;
  const timeExtend = props.timeExtend;
  const isResult = props.isResult;
  const [result, setResult] = useState<string>("");
  const [newTimeExtend, setNewTimeExtend] = useState<string>(timeExtend);
  const handleClose = props.handleClose;
  const context = useContext(ModalController);
  const _loadData = props._loadData;
  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();
  const typeTransfer = props.typeTransfer;
  const isTransfer = props.isTransfer;

  const messageSuccess = isTransfer
    ? "Thu hồi và chuyển giao công việc thành công"
    : "Thu hồi công việc thành công";
  const messageFailure = isTransfer
    ? "Thu hồi và chuyển giao công việc thất bại"
    : "Thu hồi công việc thất bại";

  const [isloading, setIsLoading] = useState(false);

  const link = window.location.pathname;

  console.log(taskCode);

  const getList = () => {
    if (link == "/working/task-proccessing") {
      readList(
        NGUOI_GIAO_VIEC_LIST_PROOCCESSING,
        0,
        workingData.choosePage,
        "",
        -1,
        -1,
        "",
        "",
        "",
        { current: true },
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_GIAO_DANG_XU_LY(res.data.Data));
        }
      );
    } else if (link == "/working/task-recovery") {
      readList(
        TASK_RECOVERY_LIST,
        0,
        workingData.choosePage,
        "",
        -1,
        -1,
        "",
        "",
        "",
        { current: true },
        link,
        (res: any) => {
          dispatch(ONLOAD_TASKRECOVERYLIST_ALL(res.data.Data));
        }
      );
    } else if (link == "/working/task-delay") {
      readList(
        NGUOI_GIAO_VIEC_LIST_DELAY,
        0,
        workingData.choosePage,
        "",
        -1,
        -1,
        "",
        "",
        "",
        { current: true },
        link,
        (res: any) => {
          dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data));
        }
      );
    } else if (link == "/working/task-return") {
      readList(
        NGUOI_GIAO_TASK_RETURN,
        0,
        workingData.choosePage,
        "",
        -1,
        -1,
        "",
        "",
        "",
        { current: true },
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_GIAO_RETURN(res.data.Data));
        }
      );
    }
  };

  const onChangeReason = (e: any) => {
    const value = e.target.value;

    setReason(value);
  };

  const transferTask = () => {
    props.dataUpdate.append("IDClone", taskId);

    props.dataUpdate.append("TaskCode", taskCode);

    TaskRepository.transferTask(props.dataUpdate).then((result2: any) => {
      if (result2.status) {
        toast.success(messageSuccess);

        getList();

        setIsLoading(false);

        handleClose();

        context.handleClose();

        setReason("");

        if (props.onHandleRecoveryTask) {
          props.onHandleRecoveryTask();
        }
      } else {
        toast.error(result2.message || messageFailure);

        setIsLoading(false);
      }
    });
  };

  const onSubmit = async () => {
    if (!taskId) {
      toast.error("Bạn vui lòng chọn công việc để thu hồi");

      return;
    }

    if (!reason) {
      toast.error("Bạn vui lòng nhập lý do thu hồi");

      return;
    }

    const body = {
      TaskID: taskId,
      Reason: reason,
    };

    setIsLoading(true);

    try {
      if (typeTransfer == 1) {
        transferTask();
      } else {
        TaskRepository.recoveryTask(body).then((result: any) => {
          if (result.status) {
            if (isTransfer) {
              transferTask();
            } else {
              toast.success(result.message || messageFailure);

              getList();

              setIsLoading(false);

              handleClose();

              context.handleClose();

              setReason("");

              if (props.onHandleRecoveryTask) {
                props.onHandleRecoveryTask();
              }
            }
          } else {
            toast.error(result.message || messageFailure);

            setIsLoading(false);
          }
        });
      }
    } catch (error: any) {
      toast.error(
        "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
      );
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col bg-white lg:w-[34rem] w-screen h-full rounded-md">
      {/* header */}
      <ModalBase _loadData={_loadData} />
      <div
        className="

                flex w-full bg-sky-500 rounded-t-md
                p-12-16 items-center font-semibold text-white
                text-lg justify-between
        
      "
      >
        <p>
          {isTransfer
            ? "Thu hồi và chuyển giao công việc"
            : "Thu hồi công việc"}
        </p>
        <button
          className="hover:bg-slate-300 p-0.5 rounded-md"
          onClick={handleClose}
          style={{
            height: "1.75rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CloseIcon />
        </button>
      </div>
      {/* content */}
      <div className="relative flex flex-col w-full h-full overflow-auto p-4 text-sm styled-scrollbars space-y-2">
        <div className="space-y-2">
          <div className="flex">
            <label className="w-1/4">Lý do thu hồi <span className="text-red-500">(*)</span></label>
            <textarea
              className=" w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200"
              placeholder=""
              onChange={onChangeReason}
              value={reason}
            />
          </div>
        </div>
      </div>
      {/* footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md justify-end items-center text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          {isloading ? (
            <LoaderSpinner h={25} w={25} />
          ) : (
            <button
              className={`flex items-center space-x-1 w-max h-max p-2 text-white rounded-md
              ${isResult === false && result === ""
                  ? "bg-slate-300"
                  : "bg-green-500 hover:bg-green-400"
                }`}
              disabled={isResult === false && result === ""}
              onClick={onSubmit}
            >
              <p>Xác nhận</p>
            </button>
          )}

          <button
            className="
          flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecoveryTaskModal;
