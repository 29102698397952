import React, { useEffect, useRef, useState, useContext, useMemo } from "react";
import ReactDOMServer, { renderToString } from "react-dom/server";

import parse, { HTMLReactParserOptions, Element } from "html-react-parser";
import ReactToPrint from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";

// import autosize from "autosize";
import TextareaAutosize from "react-textarea-autosize";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import SaveIcon from "@mui/icons-material/Save";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

import SubModal from "../_subModal/_index";
import moment from "moment";

import reportView from "../../../temp/baocao";
import { reportView2 } from "../../../temp/baocao/index2";

import EditorFC from "../../EditorFC/EditorFC";

import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";

import {
  BAO_CAO_CHI_TIET,
  BAO_CAO_UPDATE_REPORT,
  READ_REPORT_NGUOINHAN,
  UPDATE_BAO_CAO,
  CREATE_REPORT_CONTENT,
  BAO_CAO_UPDATE,
} from "../../../apis/_index";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import { toast } from "react-toastify";
import axios from "axios";
import LoaderSpinner from "../../loader-spinner/_index";
import { ModalController } from "../../../ui/homeUI/_index";
import EditorFC2 from "../../EditorFC2/EditorFC2";
import ServiceUtils from "../../../utils/services";
import LoadingControl from "../../../controls/loading/loadingControl";

import "../../../styles/editReportInputForms/index.css";

const EditReportInputForm = (props: any) => {
  // Take props
  const context = useContext(ModalController);
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);

  // state submodal
  // let tempBonusOrPayoff: string = "";
  // let tempPlanOfAction: string = "";
  const [tempBonusOrPayoff, setTempBonusOrPayoff] = useState<any>("");
  const [tempPlanOfAction, setTempPlanOfAction] = useState<any>("");

  const [bonusOrPayoff, setBonusOrPayoff] = useState<string>("");
  const [planOfAction, setPlanOfAction] = useState<string>("");

  const [data, setData] = useState<any>(null);
  const [template, setTemplate] = useState<string | null>(null);
  const [dataHistory, setDataHistory] = useState<any>(null);

  // const [planOfAction, setPlanOfAction] = useState<string>("")

  const [optionsParser, setOptionParser] = useState<any>();

  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const _onClickReportDelay = () => {
    setFuncsSub("reportDelay");
    handleOpenSub();
  };

  const _onClickSubViewReport = () => {
    setFuncsSub("subViewReport");
    handleOpenSub();
  };

  const _onClickSendReport = () => {
    context.setDataModal({
      ID: taskId,
      ReportHistoryID: data?.report_history[0]?.ID,
    });
    setFuncsSub("reportSend");
    handleOpenSub();
  };

  const _onClickDeleteReport = () => {
    setFuncsSub("reportDelete");
    handleOpenSub();
  };

  // funcs
  const _onChangeReportDescriptionFuncs = (e: any, TaskID: string) => {
    const tempValue = dataHistory;
    const tempArray = [...dataHistory?.report_history];
    const tempObject = tempArray.find((item) => item.TaskID === TaskID);

    tempObject.Description = e.target.value;

    setDataHistory({ ...tempValue, report_history: tempArray });
  };

  const _onChangeReportDescriptionFuncs2 = (taskId: any) => (e: any) => {
    const tempValue = dataHistory;

    const tempArray = [...dataHistory?.report_history];

    const tempObject = tempArray.find((item) => item.TaskID === taskId);

    tempObject.Description = e;

    // setDataHistory({ ...tempValue, report_history: tempArray })
  };

  const _onChangeBonusOrPayoff = (e: string) => {
    setTempBonusOrPayoff(e);
  };

  const _onChangePlanOfAction = (e: string) => {
    setTempPlanOfAction(e);
  };

  const _onClickUpdateReport = () => {
    const accessToken = localStorage.getItem("accessToken");

    const listOfTask = dataHistory?.report_history?.map((item: any) => {
      return {
        TaskID: item.TaskID,
        Description: item.Description === null ? "" : item.Description,
      };
    });

    // console.log(listOfTask)

    const body: any = {
      ReportID: data?.report_details?.ID,
      Report_HistoryID: dataHistory?.ID,
      RewardOrPunishment: tempBonusOrPayoff,
      PlanOfAction: tempPlanOfAction,
      Report_HistoryText: listOfTask,
    };

    const fetchData = async () => {
      try {
        await ServiceUtils.postV2(BAO_CAO_UPDATE, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 400) {
            toast.success("Cập nhật báo cáo thành công");
          } else {
            toast.error(res.data.Message);
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    fetchData();

    // console.log(dataHistory)
    // console.log(body)
  };

  // onMount
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      const accessToken = localStorage.getItem("accessToken");

      const takeData = async () => {
        try {
          await ServiceUtils.getV2(READ_REPORT_NGUOINHAN + taskId, {
            headers: {
              Accept: "text/plain",
            },
          }).then((res: any) => {
            if (res.data.StatusCode === 200) {
              setDataHistory(
                res.data.Data.report_history?.find(
                  (item: any) => item.ReportID === taskId
                )
              );
              setPlanOfAction(
                res.data.Data.report_history?.find(
                  (item: any) => item.ReportID === taskId
                )?.PlanOfAction === null
                  ? ""
                  : res.data.Data.report_history?.find(
                      (item: any) => item.ReportID === taskId
                    )?.PlanOfAction
              );
              setBonusOrPayoff(
                res.data.Data.report_history?.find(
                  (item: any) => item.ReportID === taskId
                )?.RewardOrPunishment === null
                  ? ""
                  : res.data.Data.report_history?.find(
                      (item: any) => item.ReportID === taskId
                    )?.RewardOrPunishment
              );

              setTemplate(res.data.Data.report_details?.ReportTemplateID);
              setData(res.data.Data);

              console.log(res.data.Data);
              // console.log("res Data", res.data.Data)
              // console.log("res history", dataHistory);
              // console.log("res template", template);
            }
          });
        } catch (error: any) {
          toast.error(
            "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
          );
        }
      };

      takeData();
    }
  });

  // onUpdate
  useEffect(() => {
    const options: HTMLReactParserOptions = {
      replace: (domNode) => {
        const typedDomNode = domNode as Element;

        if (typedDomNode.attribs) {
          if (data?.report_details?.TypeReportJob === "1") {
            if (typedDomNode.attribs.id === "reportDay") {
              return (
                <input className="w-3 h-3" type="checkbox" checked disabled />
              );
            }
          } else if (data?.report_details?.TypeReportJob === "2") {
            if (typedDomNode.attribs.id === "reportMonth") {
              return (
                <input className="w-3 h-3" type="checkbox" checked disabled />
              );
            }
          } else if (data?.report_details?.TypeReportJob === "3") {
            if (typedDomNode.attribs.id === "reportQuarter") {
              return (
                <input className="w-3 h-3" type="checkbox" checked disabled />
              );
            }
          } else if (data?.report_details?.TypeReportJob === "4") {
            if (typedDomNode.attribs.id === "reportYear") {
              return (
                <input className="w-3 h-3" type="checkbox" checked disabled />
              );
            }
          } else if (data?.report_details?.TypeReportJob === "5") {
            if (typedDomNode.attribs.id === "reportCreateOwnwer") {
              return (
                <input className="w-3 h-3" type="checkbox" checked disabled />
              );
            }
          } else {
          }
        }

        switch (typedDomNode?.attribs?.id) {
          case "nameStaff":
            return (
              <span className="font-normal">
                {data?.report_details.NameReportUser}
              </span>
            );
          case "levelStaff":
            return (
              <span className="font-normal">{data?.report_details.CHUCVU}</span>
            );
          case "phongBanStaff":
            return (
              <span className="font-normal">
                {data?.report_details.PHONGBAN}
              </span>
            );

          case "workingCapacity":
            return (
              <span className="font-semibold">
                {data?.report_result[0].NANGSUAT}%
              </span>
            );
          case "supportCapacity":
            return (
              <span className="font-semibold">
                {data?.report_result[0].NANGSUATPHOIHOP}%
              </span>
            );
          case "workingLevel":
            return (
              <span className="font-semibold">
                {data?.report_result[0].NANGLUC}%
              </span>
            );
          case "supportLevel":
            return (
              <span className="font-semibold">
                {data?.report_result[0].NANGLUCPHOIHOP}%
              </span>
            );

          case "perfectResult":
            return (
              <span>{data?.report_result[0].Recipient_NumberExcellent}</span>
            );
          case "goodResult":
            return <span>{data?.report_result[0].Recipient_NumberGood}</span>;
          case "aveResult":
            return <span>{data?.report_result[0].Recipient_NumberMedium}</span>;
          case "badResult":
            return <span>{data?.report_result[0].Recipient_NumberFail}</span>;

          case "receiverTask":
            return <span>{data?.report_result[0].DaNhan}</span>;
          case "completeTask":
            return <span>{data?.report_result[0].HoanTat}</span>;
          case "supportTask":
            return <span>{data?.report_result[0].HoTro}</span>;

          case "phongBanBoss":
            return (
              <span className="font-normal">
                {data?.report_listreviewer[0].PHONGBAN}
              </span>
            );
          case "levelBoss":
            return (
              <span className="font-normal">
                {data?.report_listreviewer[0].CHUCVU}
              </span>
            );
          case "taskOwner":
            return (
              <span className="font-normal">
                {data?.report_listreviewer[0].NameReviewer}
              </span>
            );
          case "bodyOfTaskResponsible":
            return (
              <>
                <tbody>
                  {data?.report_task?.map((item: any, index: number) => (
                    <tr key={index}>
                      <th
                        style={{ border: "2px solid black", height: "2rem" }}
                        scope="row"
                      >
                        {index + 1}
                      </th>
                      <td style={{ border: "2px solid black" }}>
                        <p className="font-semibold">{item.TaskName}</p>
                        <p>
                          Người giao: <span>{item.TaskOwnerName}</span>
                        </p>
                        <p>
                          Ngày tạo: {moment(item.DateAdd).format("DD-MM-YYYY")}
                        </p>
                        <p>
                          Thời hạn: {moment(item.DeadLine).format("DD-MM-YYYY")}
                        </p>
                        <div className="flex w-full h-max space-x-1">
                          <p>Trạng thái:</p>
                          {item.TaskStatus === 3 ? (
                            <p className="text-gray-500 font-bold">
                              Chưa xử lý
                            </p>
                          ) : item.TaskStatus === 4 ? (
                            <p className="text-amber-500 font-bold">
                              Đang xử lý
                            </p>
                          ) : item.TaskStatus === 5 ? (
                            <p className="text-green-500 font-bold">Hoàn tất</p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="flex w-full h-max space-x-1">
                          <p>Kết quả:</p>
                          <p>{item.TaskScorecardName}</p>
                        </div>
                      </td>
                      <td
                        className="w-1/2 h-full"
                        style={{ border: "2px solid black" }}
                      >
                        {/* <TextareaAutosize
                          style={{
                            height: 80
                          }}
                          className="w-full rounded-md pl-2 pr-2 border-2 border-slate-200"

                          value={dataHistory?.report_history[index]?.Description}
                          onChange={(e: any) => _onChangeReportDescriptionFuncs(e, dataHistory?.report_history[index].TaskID)}
                        /> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            );

          case "bonusOrPayOff":
            return (
              <div className="w-full h-max">
                {data?.report_history[0].RewardOrPunishment}
              </div>
            );
          case "planOfAction":
            return (
              <div className="w-full h-max">
                {data?.report_history[0].PlanOfAction}
              </div>
            );
        }
      },
    };

    setOptionParser(options);
  }, [data]);

  // memo
  const dataBodyReader = useMemo(() => {
    const tempData = data?.report_history?.find(
      (item: any) => item.ReportID === taskId
    );
    return tempData;
  }, [data, taskId]);

  console.log(dataHistory);

  const FinalJSX = () => {
    return (
      <>
        {/* {parse(reportView, optionsParser)} */}

        <div className="w-full h-max flex flex-col space-y-6">
          {/* Element */}
          <p className="text-lg font-semibold text-primary">
            I. DANH SÁCH CÔNG VIỆC
          </p>
          {data?.report_task?.map((item: any, index: number) => (
            <div className="w-full h-max flex flex-col space-x-1">
              <p className="font-semibold">
                {index + 1}. {item.TaskName}
              </p>
              <p>Người giao: {item.TaskOwnerName}</p>
              <p>Ngày tạo: {moment(item.DateAdd).format("DD-MM-YYYY HH:mm")}</p>
              <p>
                Thời hạn: {moment(item.DeadLine).format("DD-MM-YYYY HH:mm")}
              </p>
              <div className="flex w-full h-max space-x-1">
                <p>Trạng thái:</p>
                {item.TaskStatus === 3 ? (
                  <p className="text-gray-500 font-bold">Chưa xử lý</p>
                ) : item.TaskStatus === 4 ? (
                  <p className="text-amber-500 font-bold">Đang xử lý</p>
                ) : item.TaskStatus === 5 ? (
                  <p className="text-green-500 font-bold">Hoàn tất</p>
                ) : item.TaskStatus === 2 ? (
                  <p className="text-green-500 font-bold">Đã giao</p>
                ) : (
                  ""
                )}
              </div>
              <div className="flex w-full h-max space-x-1">
                <p>Kết quả:</p>
                <p>{item.TaskScorecardName}</p>
              </div>
              <div className="flex flex-col w-full h-max space-x-1">
                <p
                  style={{
                    marginBottom: "5px",
                  }}
                >
                  Mô tả:
                </p>
                <EditorFC
                  state={{
                    placeHolder: "Mô tả công việc",
                    isShowSaveButton: false,
                    height: 400,
                    value: dataHistory?.report_history[index]?.Description,
                    bodyParams: {
                      type: "Description",
                      TaskID: dataHistory?.report_history[index]?.TaskID,
                      onChange: _onChangeReportDescriptionFuncs2(
                        dataHistory?.report_history[index]?.TaskID
                      ),
                    },
                  }}
                />
              </div>
            </div>
          ))}
          <p className="text-lg font-semibold text-primary">II. THƯỞNG PHẠT</p>
          <div className="w-full h-max flex">
            <EditorFC2
              state={{
                placeHolder: "Ghi chú thưởng phạt",
                isShowSaveButton: false,
                height: 400,
                value: bonusOrPayoff,
                bodyParams: {
                  onChange: _onChangeBonusOrPayoff,
                },
              }}
            />
          </div>

          <p className="text-lg font-semibold text-primary">
            III. KẾ HOẠCH HÀNH ĐỘNG
          </p>
          <div className="w-full h-max flex">
            <EditorFC2
              state={{
                placeHolder: "Ghi chú kế hoạch hành động",
                isShowSaveButton: false,
                height: 400,
                value: planOfAction,
                bodyParams: {
                  onChange: _onChangePlanOfAction,
                },
              }}
            />
          </div>
        </div>
      </>
    );
  };

  // Funcs
  const _onClickCreateContent = () => {
    const accessToken = localStorage.getItem("accessToken");
    const body: any = {
      ReportID: taskId,
      Content: renderToString(<FinalJSX />),
    };
    const takeData = async () => {
      try {
        await ServiceUtils.postV2(UPDATE_BAO_CAO, body, {
          headers: {
            Accept: "text/plain",
          },
        }).then((res: any) => {
          if (res.data.StatusCode === 200) {
            toast.success("Lưu nội dung thành công");
          }
        });
      } catch (error: any) {
        toast.error(
          "Không kết nối được máy chủ, vui lòng kiểm tra lại đường truyền hoặc đăng nhập lại phần mềm!"
        );
      }
    };

    takeData();
  };

  return (
    <div
      className="
        flex flex-col bg-white
        lg:w-[90vw] w-screen lg:h-[90vh] h-[calc(100vh-5rem)]
        rounded-md overflow-x-auto
    "
    >
      <SubModal
        taskId={taskId}
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
      />
      {/* Headers */}
      <div
        className="        
        flex w-full bg-sky-500 rounded-t-md
        p-12-16 items-center font-semibold text-white
        text-lg justify-between

    "
      >
        <p>Biểu mẫu báo cáo</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
      </div>

      {/* Content */}
      {data === null ? (
        <div className="w-full h-[calc(100%-6rem)] flex p-3 text-sm overflow-scroll">
          {/* <div className="flex w-full h-full justify-center items-center space-x-1">
              <LoaderSpinner w={32} h={32} />
              <p className="text-primary">Đang tải dữ liệu</p>
            </div> */}
          <LoadingControl />
        </div>
      ) : (
        <div className="w-full h-[calc(100%-6rem)] flex p-3 text-sm overflow-scroll">
          <div className="w-full h-max flex">
            <div
              style={{
                width: "100%",
                height: "max-content",
              }}
            >
              {data === null || dataHistory === null || template === null ? (
                <></>
              ) : (
                <FinalJSX />
              )}
            </div>
          </div>
        </div>
      )}

      {/* Footer */}
      <div className="flex w-full p-12-16 footer rounded-b-md items-center text-sm space-x-4">
        <div className="flex w-full h-max space-x-3 justify-between items-center core-edit-report-input-form-group-button">
          {/* Print button */}
          <div className="w-max h-max flex items-center space-x-3 core-edit-report-input-form-group-button-item">
            {/* Ask delay */}
            <button
              className="flex font-bold items-center space-x-1 w-max h-max p-2 border border-primary hover:bg-primary text-primary hover:text-white rounded-md drop-shadow-lg transition-all delay-65"
              onClick={_onClickReportDelay}
            >
              <MoreTimeIcon fontSize="small" />
              <p>Xin gia hạn</p>
            </button>

            <button
              className="
                flex items-center space-x-1 w-max h-max p-2 bg-gray-500 text-white rounded-md
                hover:bg-gray-400
              "
              onClick={_onClickSubViewReport}
            >
              <ContentPasteIcon fontSize="small" />
              <p>Xem báo cáo</p>
            </button>
          </div>

          {/* Close button */}
          <div className="w-max h-max flex space-x-3 core-edit-report-input-form-group-button-item">
            <button
              className="
          flex items-center space-x-1 w-max h-max p-2 bg-green-500 text-white rounded-md
          hover:bg-green-400
        "
              onClick={_onClickSendReport}
            >
              <SendIcon fontSize="small" />
              <p>Gửi báo cáo</p>
            </button>

            <button
              className="
              flex items-center space-x-1 w-max h-max p-2 bg-primary text-white rounded-md
              hover:bg-sky-400
            "
              onClick={_onClickUpdateReport}
            >
              <SaveIcon fontSize="small" />
              <p>Lưu báo cáo</p>
            </button>

            <button
              className="
                flex items-center space-x-1 w-max h-max p-2 bg-slate-300 text-white rounded-md
                hover:bg-slate-200
              "
              // onClick={handleClose}
              onClick={() => console.log(dataHistory)}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditReportInputForm;
